//해당 파일은 검색시 해당 지역의 검색값에 대한 호우피해 예측 데이터를 테이블에 보여주는 Component 입니다.
//현재는 가짜데이터를 화면에 보여주고 있으며 원래는 지도에서 영역 클릭시 검색창에 해당지역이 선택되고 날짜선택후 검색시 값들이 나와야합니다.
import { useReactiveVar } from "@apollo/client";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import { downpourDataVar } from "../../utils/reactiveVars";

const Container = styled.div`
  max-height: 1540px;
`;
const TableContainer = styled.div`
  display: flex;
`;
const TableTitle = styled.div`
  background-color: lightgray;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10px;
`;
const TableContent = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10px 0;
  background: ${(props) => (props.bgColor ? props.bgColor : "")};
`;
const TableTitleCon = styled.div`
  display: flex;
`;
const TableContentCon = styled.div`
  display: flex;
`;

const DownpourPredictionTable = () => {
  const downpourData = useReactiveVar(downpourDataVar);
  //호우피해예측 테이블 생성 함수
  const tableMaker = () => {
    const titles = [
      "예측기간",
      "24시간 예상\n누적강수량",
      "호우피해발생\n가능성",
    ];
    const tableTitle = (
      <TableTitleCon>
        {titles?.map((v, idx) => (
          <TableTitle key={idx} className="col-md-4">
            {v}
          </TableTitle>
        ))}
      </TableTitleCon>
    );
    const tableContent = downpourData?.map((v, idx) => (
      <TableContentCon key={idx}>
        <TableContent className="col-md-4">{v.date}</TableContent>
        <TableContent className="col-md-4">{v.totalRain24HPred}</TableContent>
        {v.downpourPossibility === "높음" ? (
          <TableContent bgColor="red" className="col-md-4">
            {v.downpourPossibility}
          </TableContent>
        ) : v.downpourPossibility === "보통" ? (
          <TableContent bgColor="orange" className="col-md-4">
            {v.downpourPossibility}
          </TableContent>
        ) : v.downpourPossibility === "낮음" ? (
          <TableContent bgColor="yellow" className="col-md-4">
            {v.downpourPossibility}
          </TableContent>
        ) : v.downpourPossibility === "없음" ? (
          <TableContent className="col-md-4">
            {v.downpourPossibility}
          </TableContent>
        ) : null}
      </TableContentCon>
    ));

    return (
      <div>
        {tableTitle}
        <div
          style={{ height: "1540px", overflowY: "auto", overflowX: "hidden" }}
        >
          {tableContent}
        </div>
      </div>
    );
  };
  return (
    <Container>
      <div>{tableMaker()}</div>
    </Container>
  );
};

export default DownpourPredictionTable;
