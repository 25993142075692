import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import "./styles";
import "../node_modules/tui-grid/dist/tui-grid.css";
import "../node_modules/tui-pagination/dist/tui-pagination.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import HttpsRedirect from "react-https-redirect";
import { config } from "dotenv";
config({ path: "../.env" });
ReactDOM.render(
  <HttpsRedirect>
    <App />
  </HttpsRedirect>,
  document.getElementById("root")
);
//ReactDOM.render(<App />, document.getElementById("root"));
