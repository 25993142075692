import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { OPEN_MENU_VAR } from "../utils/reactiveVars";

const Signup = () => {
  OPEN_MENU_VAR(false);
  const navigate = useNavigate();
  const { register, formState, handleSubmit } = useForm();

  const onSignUpClick = async (data) => {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/registerUser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    if (!!res) {
      if (!!res?.ok) {
        alert("회원가입이 완료되었습니다.\n로그인을 진행해주세요.");
        navigate("/login");
      } else if (!res?.ok) {
        return alert(res?.error);
      } else {
        return alert("회원가입 처리도중 문제가 발생했습니다.");
      }
    }
  };

  return (
    <div id="detect" className="area-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="section-headline text-center">
              <h2>회원가입</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div
              id="signin"
              className="col-md-12 col-sm-12 col-xs-12 text-center"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                fontSize: "20px",
              }}
            >
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                <span style={{ color: "red", fontSize: "10px" }}>
                  {formState?.errors?.username?.message}
                </span>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                <input
                  {...register("username", {
                    required: { value: true, message: "이름을 입력해주세요!" },
                  })}
                  id="username"
                  type="text"
                  placeholder="이름"
                />
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                <span style={{ color: "red", fontSize: "10px" }}>
                  {formState?.errors?.email?.message}
                </span>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                <input
                  {...register("email", {
                    required: {
                      value: true,
                      message: "이메일을 입력해주세요!",
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "올바른 형식의 이메일을 입력해주세요!",
                    },
                  })}
                  id="email"
                  type="email"
                  placeholder="이메일"
                />
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                <span style={{ color: "red", fontSize: "10px" }}>
                  {formState?.errors?.phonenumber?.message}
                </span>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                <input
                  {...register("phonenumber", {
                    required: {
                      value: true,
                      message: "휴대폰 번호를 입력해주세요!",
                    },
                    pattern: {
                      value: /^\d{11}$/,
                      message: "올바른 휴대폰 번호를 입력해주세요!",
                    },
                  })}
                  id="phonenumber"
                  type="text"
                  placeholder="휴대폰 번호"
                />
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                <span style={{ color: "red", fontSize: "10px" }}>
                  {formState?.errors?.password?.message}
                </span>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                <input
                  {...register("password", {
                    required: {
                      value: true,
                      message: "비밀번호를 입력해주세요!",
                    },
                  })}
                  id="password"
                  type="password"
                  placeholder="비밀번호"
                />
              </div>

              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                <input
                  {...register("usertype")}
                  id="usertype"
                  type="hidden"
                  placeholder="usertype"
                  value="guest"
                />
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                <button
                  id="registerBtn"
                  className="btn btn-success"
                  style={{ fontSize: "20px" }}
                  onClick={handleSubmit(onSignUpClick)}
                >
                  회원가입
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
