import { useNavigate } from "react-router-dom";
import { OPEN_MENU_VAR, userLogOut } from "../utils/reactiveVars";

const Account = () => {
  OPEN_MENU_VAR(false);
  const navigate = useNavigate();
  const jwt_decode = (token) => {
    if (!token) {
      return;
    }
    const base64Payload = token.split(".")[1];
    // const payload = Buffer.from(base64Payload, "base64");

    const result = JSON.parse(atob(base64Payload));
    return result;
  };

  const onDeleteAccountClick = async () => {
    const info = jwt_decode(localStorage.getItem("token"));
    try {
      const confirmBool = window.confirm("계정을 정말로 삭제하시겠습니까?");
      if (!!confirmBool) {
        const res = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/wateraiInfoWithdrawal`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify({ info }),
          }
        ).then((res) => res.json());
        if (res?.ok) {
          userLogOut();
          alert("계정이 삭제되었습니다!");
          navigate("/");
        }
      }
    } catch (e) {
      return alert("에러가 발생했습니다!\n다시 시도해주세요.");
    }
  };

  return (
    <div id="detect" className="area-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="section-headline text-center">
              <h2>계정</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div
              id="accountCon"
              className="col-md-12 col-sm-12 col-xs-12 text-center"
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                <button
                  id="deleteAccountBtn"
                  className="btn btn-danger"
                  onClick={onDeleteAccountClick}
                  style={{ fontSize: "20px" }}
                >
                  계정 삭제
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Account;
