import styled from "styled-components";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const Container = styled.div``;

const HighBarChart = ({ data }) => {
  console.log("data : ", data);
  const categories = data?.map((v) => v.date);
  const dataForChart = data?.map((v) => Number(v.maxPrecip));
  const options = {
    chart: {
      type: "column", // bar차트. 아무 설정이 없으면 line chart가 된다.
    },
    title: {
      text: "1시간 최대 강수량",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      //type: "category",
      categories: categories,
    },
    yAxis: {
      min: 0,
      title: {
        text: "mm",
      },
    },
    legend: {
      reversed: true,
    },
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          format: "<b>{point.y}</b>",
        },
      },
    },
    series: [
      {
        name: "1시간 최대 강수량",
        //data: [40000, 50000, 60000, 70000, 80000, 100000, 120000, 150000],
        data: dataForChart,
      },
    ],
  };
  return (
    <Container>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Container>
  );
};

export default HighBarChart;
