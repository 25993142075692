import { useEffect } from "react";
import { OPEN_MENU_VAR } from "../utils/reactiveVars";
import styled from "styled-components";
import zip1 from "../static/zip/WaterAITool-1.0.2-setup_매뉴얼_환경.zip"
const Btn = styled.a`
    border: 1px solid #fff;
    // border-radius: 30px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 120px;
    text-align: center;
    transition: all 0.4s ease 0s;
    z-index: 222;
    &:hover{
        color: #fff;
        background: #3ec1d5;
        border: 1px solid #3ec1d5;
        text-decoration: none;
        scale:1.05;
    }
`;

const AuthoringTools=()=>{
    OPEN_MENU_VAR(false);
    useEffect(() => {
        const js_libs = [
          "contactform/contactform.js",
          "js/main.js",
        ];
        js_libs.forEach((lib) => {
          const script = document.createElement("script");
          script.src = lib;
          script.async = true;
          document.body.appendChild(script);
        });
      }, []);
      const handleDownload = async (srcUrl) => {
        try {
          const response = await fetch(srcUrl); 
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'WaterAITool-1.0.2-setup_매뉴얼_환경.zip');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      };
    return(
        <div style={{
            display:"flex",
            flexDirection:"column"
        }}>
            <div style={{width:"100%",height:"70px",backgroundColor:"black"}}/>
            <>
            <div className="container" style={{padding:"80px 0"}}>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="section-headline text-center">
              <h2>WaterAITool 저작 도구 다운로드</h2>
              </div>
            </div>
          </div>

            {/* single-well start*/}
            <div 
            style={{
                width:"100%",
                display:"flex", 
                flexDirection:"column", 
                gap:"5px", 
                alignItems:"center",
                justifyContent:"center"
            }}
            >

            <div
              style={{
                width:"30%",
                display:"flex",
                flexDirection:"column",
                justifyContent:"center",
                alignItems:"center",
                backgroundColor:"black",
                // borderRadius:"10px",
                gap:"30px",
                color:"white",
                padding:"30px 0px",
                fontSize:"14px"
              }}
            >
                <div style={{
                    fontSize:"18px"}}>WaterAITool-1.0.2-setup_매뉴얼_환경.zip</div>
                {/* <a style={{padding:"5px 25px", backgroundColor:"#3EC1D5", cursor:"pointer"}}>다운로드</a> */}
                <Btn onClick={() => handleDownload(zip1)}>다운로드</Btn>
            </div>
            </div>
          </div>

        </>
        </div>

    )
}

export default AuthoringTools