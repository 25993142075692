import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { OPEN_MENU_VAR, userLogIn } from "../utils/reactiveVars";

const Login = () => {
  OPEN_MENU_VAR(false);
  const navigate = useNavigate();
  const { register, watch, handleSubmit, formState } = useForm();
  useEffect(() => {
    console.log("process.env.SERVER_URL : ", process.env.REACT_APP_SERVER_URL);
  });
  const onLoginClick = async (data) => {
    const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/loginUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    }).then((res) => res.json());
    if (!!res) {
      if (!!res?.ok) {
        // localStorage.setItem("token", res?.token);
        userLogIn(res.token);
        navigate("/");
      } else if (!res?.ok) {
        return alert(res?.error);
      } else {
        return alert("로그인 도중 문제가 발생했습니다!");
      }
    }
  };

  return (
    <div id="detect" className="area-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="section-headline text-center">
              <h2>로그인</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div
              id="loginCon"
              className="col-md-12 col-sm-12 col-xs-12 text-center"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                <span style={{ color: "red" }}>
                  {formState?.errors?.phonenumber?.message}
                </span>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                <input
                  {...register("phonenumber", {
                    required: {
                      value: true,
                      message: "올바른 휴대폰 번호를 입력해주세요!",
                    },
                    pattern: {
                      value: /^\d{11}$/,
                      message: "올바른 휴대폰 번호를 입력해주세요!",
                    },
                  })}
                  id="login_phonenumber"
                  type="text"
                  placeholder={"휴대폰번호"}
                  style={{ fontSize: "20px" }}
                />
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                <span style={{ color: "red" }}>
                  {formState?.errors?.password?.message}
                </span>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                <input
                  {...register("password", {
                    required: {
                      value: true,
                      message: "비밀번호를 입력해주세요!",
                    },
                  })}
                  id="login_password"
                  type="password"
                  placeholder="비밀번호"
                  style={{ fontSize: "20px" }}
                />
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                <button
                  id="loginBtn"
                  className="btn btn-success"
                  onClick={handleSubmit(onLoginClick)}
                  style={{ fontSize: "20px" }}
                >
                  로그인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
