import { useReactiveVar } from "@apollo/client";
import axios from "axios";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { downpourDataVar, fixLocVar } from "../../utils/reactiveVars";
const fakeData = [
  {
    date: "2022-01-01 10:00",
    totalRain24HPred: "100mm",
    downpourPossibility: "높음",
  },
  {
    date: "2022-01-01 11:00",
    totalRain24HPred: "20mm",
    downpourPossibility: "낮음",
  },
  {
    date: "2022-01-01 12:00",
    totalRain24HPred: "30mm",
    downpourPossibility: "보통",
  },
  {
    date: "2022-01-01 13:00",
    totalRain24HPred: "10mm",
    downpourPossibility: "없음",
  },
  {
    date: "2022-01-01 14:00",
    totalRain24HPred: "10mm",
    downpourPossibility: "없음",
  },
  {
    date: "2022-01-01 15:00",
    totalRain24HPred: "10mm",
    downpourPossibility: "없음",
  },
  {
    date: "2022-01-01 16:00",
    totalRain24HPred: "100mm",
    downpourPossibility: "높음",
  },
  {
    date: "2022-01-01 17:00",
    totalRain24HPred: "100mm",
    downpourPossibility: "높음",
  },
  {
    date: "2022-01-01 14:00",
    totalRain24HPred: "10mm",
    downpourPossibility: "없음",
  },
  {
    date: "2022-01-01 14:00",
    totalRain24HPred: "10mm",
    downpourPossibility: "없음",
  },
  {
    date: "2022-01-01 12:00",
    totalRain24HPred: "30mm",
    downpourPossibility: "보통",
  },
  {
    date: "2022-01-01 12:00",
    totalRain24HPred: "30mm",
    downpourPossibility: "보통",
  },
  {
    date: "2022-01-01 12:00",
    totalRain24HPred: "30mm",
    downpourPossibility: "보통",
  },
  {
    date: "2022-01-01 12:00",
    totalRain24HPred: "30mm",
    downpourPossibility: "보통",
  },
  {
    date: "2022-01-01 12:00",
    totalRain24HPred: "30mm",
    downpourPossibility: "보통",
  },
  {
    date: "2022-01-01 12:00",
    totalRain24HPred: "30mm",
    downpourPossibility: "보통",
  },
];
const SearchDownpour = () => {
  const fixLoc = useReactiveVar(fixLocVar);
  const onDownpourClick = () => {
    const { locName, standardDate, standardTime } = getValues();
    console.log(locName, standardTime, standardDate);
    //검색 시 사용할 쿼리를 실행한다.
    if (locName?.includes("성남")) {
      downpourDataVar(fakeData);
    } else {
      downpourDataVar([]);
    }
    //실행 후 데이터를 전역변수에 저장한다.
  };
  const { register, setValue, getValues } = useForm();
  useEffect(() => {
    setValue("locName", fixLoc);
    onDownpourClick();
  }, [fixLoc]);
  return (
    <div className="card">
      <div className="row">
        <div
          className="col-sm-1"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ㆍ지역
        </div>
        <div className="col-sm-3">
          <input
            {...register("locName")}
            type="text"
            className="form-control"
            placeholder="시군구 이름 예) 성남시"
          />
        </div>
        <div
          className="col-sm-2"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ㆍ예측기준
        </div>
        <div className="col-sm-2">
          <input
            {...register("standardDate")}
            type="date"
            className="form-control"
          />
        </div>
        <div className="col-sm-2">
          <input
            {...register("standardTime")}
            type="time"
            className="form-control"
          />
        </div>
        <div className="col-sm-2">
          <button
            onClick={onDownpourClick}
            type="button"
            className="btn btn-primary"
            style={{ width: "100%" }}
          >
            검색
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchDownpour;
