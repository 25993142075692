import { useEffect, useState } from "react";
import { OPEN_MENU_VAR } from "../utils/reactiveVars";

const Admin = () => {
  OPEN_MENU_VAR(false);
  const [devices, setDevices] = useState([]);
  const getDeviceRepairing = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/getDeviceRepairing`
    ).then((res) => res.json());
    if (res?.ok) {
      setDevices(res?.data);
    }
  };
  useEffect(() => {
    getDeviceRepairing();
  }, []);

  const onRepairStatusClick = async (deviceId, repairing) => {
    console.log(deviceId, repairing);
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/updateDeviceRepairing`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({ deviceId, repairing }),
      }
    );
    if (res?.ok) {
      getDeviceRepairing();
    }
  };

  return (
    <div id="detect" className="area-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="section-headline text-center">
              <h2>관리자 화면</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            {devices?.map((d, idx) => (
              <div
                key={idx}
                className="col-md-12 col-sm-12 col-xs-12"
                style={{ fontSize: "20px", marginBottom: "10px" }}
              >
                <div
                  className="col-md-4 col-sm-4 col-xs-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                  }}
                >
                  {d.placename}
                </div>
                <div
                  className="col-md-4 col-sm-4 col-xs-4"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {d.repairing == 0 ? (
                    <span style={{ color: "lightgreen", fontWeight: "bold" }}>
                      작동중
                    </span>
                  ) : (
                    <span style={{ color: "orange", fontWeight: "bold" }}>
                      보수중
                    </span>
                  )}
                </div>
                <div
                  className="col-md-4 col-sm-4 col-xs-4"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {d.repairing == 0 ? (
                    <button
                      type="button"
                      style={{
                        backgroundColor: "orange",
                        color: "white",
                        fontWeight: "bold",
                        border: "none",
                        boxShadow: "0px 0px 5px 1px gray !important",
                      }}
                      onClick={() => {
                        onRepairStatusClick(d.id, true);
                      }}
                    >
                      보수중으로 변경
                    </button>
                  ) : (
                    <button
                      type="button"
                      style={{
                        backgroundColor: "#4cd137",
                        color: "white",
                        fontWeight: "bold",
                        border: "none",
                        boxShadow: "0px 0px 5px 1px gray !important",
                      }}
                      onClick={() => {
                        onRepairStatusClick(d.id, false);
                      }}
                    >
                      작동중으로 변경
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
