import { makeVar } from "@apollo/client";

export const TOKEN = "token";

export const locCodeVar = makeVar("31020");
export const searchedDataForBarVar = makeVar([]);
export const searchedDataForLineVar = makeVar([]);
export const fixLocVar = makeVar("성남시");
export const downpourDataVar = makeVar([]);
export const TOKEN_VAR = makeVar(Boolean(localStorage.getItem(TOKEN)));

export const jwt_decode = (token) => {
  if (!token) {
    return;
  }
  const base64Payload = token.split(".")[1];
  // const payload = Buffer.from(base64Payload, "base64");

  const result = JSON.parse(atob(base64Payload));
  return result;
};

export const USERINFO_VAR = makeVar(jwt_decode(localStorage.getItem(TOKEN)));
export const userLogIn = (token) => {
  localStorage.setItem(TOKEN, token);
  USERINFO_VAR(jwt_decode(localStorage.getItem(TOKEN)));
  TOKEN_VAR(true);
};

export const userLogOut = () => {
  localStorage.removeItem(TOKEN);
  TOKEN_VAR(false);
};

export const OPEN_MENU_VAR = makeVar(false);
