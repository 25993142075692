import { useReactiveVar } from "@apollo/client";
import styled from "styled-components";
// import MyResponsiveBar from "../components/charts/barChart";
// import MyResponsiveLine from "../components/charts/lineChart";
// import KoreaDivisionSvg from "../components/main/KoreaDivisionSvg";
import LocationTable from "../components/main/LocationTable";
import SearchBox from "../components/main/SearchBox";
import {
  OPEN_MENU_VAR,
  searchedDataForBarVar,
  TOKEN_VAR,
  USERINFO_VAR,
} from "../utils/reactiveVars";
// import os from "os";
// import { detect } from "detect-browser";
import HighBarChart from "../components/charts/highBarChart";
import HighLineChart from "../components/charts/highLineChart";
// import VWorldMap from "../components/vworld/VWorldMap";
// import DownpourPredictionTable from "../components/main/DownpourPredictionTable";
import MapandDownpour from "../components/main/MapandDownpour";
import Devices from "../components/main/Devices";
import { GOOGLE_DOWNLOAD, APPLE_DOWNLOAD } from "../static/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import lib from "react-https-redirect";

const Container = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
  margin-top: 100px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const ChartCon = styled.div`
  min-height: 300px;
`;
const FixedStore = styled.div`
  position: fixed;
  top: 100px;
  right: 100px;
`;
const DownloadImg = styled.img`
  width: 100px;
  z-index: 100;
`;

const LayoutMobile = ({ children }) => {
  const navigate = useNavigate();
  const tokenVar = useReactiveVar(TOKEN_VAR);
  const userInfoVar = useReactiveVar(USERINFO_VAR);
  const openMenuVar = useReactiveVar(OPEN_MENU_VAR);
  // const [token, setToken] = useState(localStorage.getItem("token"));
  const searchedDataForBar = useReactiveVar(searchedDataForBarVar);
  //console.log("os : ", os.platform());
  //alert(detect().os.includes("Android"));
  useEffect(() => {
    const js_libs = [
      // "lib/jquery/jquery.min.js",
      // "lib/bootstrap/js/bootstrap.min.js",
      // "lib/owlcarousel/owl.carousel.min.js",
      // "lib/venobox/venobox.min.js",
      // "lib/knob/jquery.knob.js",
      // "lib/wow/wow.min.js",
      // "lib/parallax/parallax.js",
      // "lib/easing/easing.min.js",
      // "lib/nivo-slider/js/jquery.nivo.slider.js",
      // "lib/appear/jquery.appear.js",
      // "lib/isotope/isotope.pkgd.min.js",
      "contactform/contactform.js",
      "js/main.js",
    ];
    js_libs.forEach((lib) => {
      const script = document.createElement("script");
      script.src = lib;
      script.async = true;
      document.body.appendChild(script);
    });
  }, []);
  return (
    <div>
      {/* <div id="preloader"></div> */}

      <header>
        {/* header-area start */}
        <div id="sticker" className="header-area">
          {/* <div
            class="hamToggle"
            style={{ position: "absolute", left: 0, top: -10, zIndex: 10 }}
          >
            <button
              type="button"
              style={{
                height: "50px",
                background: "none",
                border: "none",
              }}
              onClick={() => OPEN_MENU_VAR(!openMenuVar)}
            >
              <span style={{ fontSize: "50px", color: "white" }}>≡</span>
            </button>
          </div> */}
          <div className="container">
            <div className="row">
              <div
                className="col-md-12 col-sm-12"
                // style={{ marginLeft: "40px" }}
              >
                {/* Navigation */}
                {/*<nav className="navbar navbar-default">*/}
                <nav style={{
                  display:"flex",
                  width:"100%",
                  justifyContent:"center",
                  alignItems:"center",
                }}>
                  {/* Brand and toggle get grouped for better mobile display */}

  
                    {/* <button
                      type="button"
                      className="navbar-toggle collapsing"
                      data-toggle="collapse"
                      data-target=".bs-example-navbar-collapse-1"
                      aria-expanded="true"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button> */}
                    {/* Brand */}
             
                      {/*-h1><span>e</span>Business</h1-*/}
                      <img
                        src="img/logo-1.png"
                        width="200px"
                     
                        alt="index.html"
                        title="waterAI"
                        style={{
                          marginTop:"5px"
                        }}
                      ></img>
                    
              
                  {/* Collect the nav links, forms, and other content for toggling */}
          
                  {/* navbar-collapse */}
                </nav>
                {/* END: Navigation */}
              </div>
            </div>
          </div>

        </div>
        {/* header-area end */}
      </header>
      {/* header end */}
      {children}
      {/* Start Footer bottom Area */}
      <footer>
        <div className="footer-area">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className="footer-content">
                  <div className="footer-head">
                    <div className="footer-logo">
                      <h2>
                        <span>Water</span>AI
                      </h2>
                    </div>

                    <p>
                      급변하는 날씨로 인한 물의 변화를 새로운 기술로 조회하고
                      다양한 기후와 안전을 위한 실시간 수위 측정으로 인근 하천과
                      산책로의 안전을 유지하고 AI로 실시간 관측하여 시민의
                      안전과 생활을 더 편리하게 제공하고자 하는 시스템
                    </p>
                    <div className="footer-icons">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-google"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-pinterest"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* end single footer */}
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className="footer-content">
                  <div className="footer-head">
                    <h4>WaterAI 기술문의</h4>
                    <p>
                      실시간 계측 인공지능 개발이 궁금하신 분께서는 아래의
                      연락처로 연락을 바랍니다.
                    </p>
                    <div className="footer-contacts">
                      <p>
                        <span>사무실전화:</span> 02)2043-9112
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* end single footer */}
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className="footer-content">
                  <div className="footer-head">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.wateraiinfo"
                      target="_blank"
                    >
                      <img
                        src="img/download/google_download.png"
                        style={{ width: "150px" }}
                      ></img>
                    </a>
                    <a
                      href="https://apps.apple.com/kr/app/wateraiinfo/id6444245568"
                      target="_blank"
                    >
                      <img
                        src="img/download/apple_download.png"
                        style={{ width: "150px" }}
                      ></img>
                    </a>
                    {/* <h4>Instagram</h4>
                    <div className="flicker-img">
                      <a href="#"><img src="img/portfolio/1.jpg" alt="" /></a>
                      <a href="#"><img src="img/portfolio/2.jpg" alt="" /></a>
                      <a href="#"><img src="img/portfolio/3.jpg" alt="" /></a>
                      <a href="#"><img src="img/portfolio/4.jpg" alt="" /></a>
                      <a href="#"><img src="img/portfolio/5.jpg" alt="" /></a>
                      <a href="#"><img src="img/portfolio/6.jpg" alt="" /></a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-area-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="copyright text-center">
                  <p>
                    &copy; Copyright <strong>주재넷(주)</strong>. All Rights
                    Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <a href="#" className="back-to-top">
        <i className="fa fa-chevron-up"></i>
      </a>

      {/* <script src="/lib/jquery/jquery.min.js"></script>
    <script src="/lib/bootstrap/js/bootstrap.min.js"></script>
    <script src="/lib/owlcarousel/owl.carousel.min.js"></script>
    <script src="/lib/venobox/venobox.min.js"></script>
    <script src="/lib/knob/jquery.knob.js"></script>
    <script src="/lib/wow/wow.min.js"></script>
    <script src="/lib/parallax/parallax.js"></script>
    <script src="/lib/easing/easing.min.js"></script>
    <script src="/lib/nivo-slider/js/jquery.nivo.slider.js" type="text/javascript" ></script>
    <script src="/lib/appear/jquery.appear.js"></script>
    <script src="/lib/isotope/isotope.pkgd.min.js"></script> */}
      <div
        style={{
          backgroundColor: "black",
          position: "fixed",
          height: "110%",
          top: -10,
          ...(openMenuVar ? { left: "0px" } : { left: "-300px" }),
          transition: "left 0.3s",
          width: "300px",
          zIndex: 1000,
          padding: "20px",
        }}
      >
        <div>
          <button
            className="hamToggle"
            type="button"
            style={{
              height: "50px",
              background: "none",
              border: "none",
            }}
            onClick={() => OPEN_MENU_VAR(!openMenuVar)}
          >
            <span style={{ fontSize: "20px", color: "white" }}>◀</span>
          </button>
        </div>
        <div
          style={{
            margin: "10px",
            flexDirection: "column",
            flexWrap: "wrap",
            gap: "10px",
            position: "absolute",
          }}
        >
          <div>
            <button
              type="button"
              style={{ border: "none", background: "none" }}
              onClick={() => navigate("/")}
            >
              <span
                style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}
              >
                홈
              </span>
            </button>
          </div>
          <div>

            {/* <button
              type="button"
              style={{ border: "none", background: "none" }}
              onClick={() => navigate("/deviceList")}
            >
              <span
                style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}
              >
                소하천 실시간 계측상황
              </span>
            </button> */}
            <button
              type="button"
              style={{ border: "none", background: "none" }}
              onClick={() => navigate("/videoList")}
            >
              <span
                style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}
              >
             WaterAI 계측영상
              </span>
            </button>
          </div>
          <div>
            <button
              type="button"
              style={{ border: "none", background: "none" }}
              onClick={() => window.open("https://bbeego.com/")}
            >
              <span
                style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}
              >
                인공지능 학습 참여
              </span>
            </button>
          </div>
          {/* <ul>
            <li>
              <Link to={{ pathname: "/" }}>홈</Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/deviceList" }}
                // onClick={() => {
                //   navigate("/deviceList");
                // }}
              >
                소하천 실시간 계측상황
              </Link>
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  );
};

export default LayoutMobile;
