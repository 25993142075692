import { useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import { OPEN_MENU_VAR, TOKEN_VAR } from "../utils/reactiveVars";

const DeviceList = () => {
  OPEN_MENU_VAR(false);
  const tokenVar = useReactiveVar(TOKEN_VAR);
  const [deviceList, setDeviceList] = useState([]);
  const jwt_decode = (token) => {
    if (!token) {
      return;
    }
    // console.log("token : ", token);
    const base64Payload = token.split(".")[1];
    // const payload = Buffer.from(base64Payload, "base64");

    const result = JSON.parse(atob(base64Payload));
    return result;
  };
  useEffect(() => {
    const getDeviceList = async () => {
      const token = localStorage.getItem("token");
      const userInfo = jwt_decode(token);
      if (!userInfo?.usertype) {
        return;
      }
      try {
        const res = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/wateraiInfo`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify({ info: { usertype: userInfo?.usertype } }),
          }
        ).then((res) => res.json());
        console.log("res : ", res);
        if (res?.ok) {
          setDeviceList(res?.results);
        }
      } catch (e) {
        return;
      }
    };
    getDeviceList();
  }, [tokenVar]);
  return (
    <div id="detect" className="area-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="section-headline text-center">
              <h2>소하천 실시간 계측상황</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div
              id="deviceListCon"
              className="col-md-12 col-sm-12 col-xs-12"
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              {/* <div className="col-md-12 col-sm-12 col-xs-12">
                <video id="test_video" controls autoplay src="rtsp://admin:Hwajin2016!@@27.160.30.69:554"></video>
              </div> */}
              {deviceList?.map((v, idx) => (
                <div className="col-md-12 col-sm-12 col-xs-12" key={idx}>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <img
                      src={`${process.env.REACT_APP_VIEW_URL}${v?.detectedPath}`}
                      style={{ width: "500px" }}
                    />
                  </div>
                  <div
                    className="col-md-6 col-sm-6 col-xs-12"
                    style={{ fontSize: "16px" }}
                  >
                    <span>하천명 : {v?.rivername}</span>
                    <br />
                    <span>위치 : {v?.location}</span>
                    <br />
                    <span>촬영종류 : {v?.recordtype}</span>
                    <br />
                    <span>수위 : {v?.waterlevel / 100}m</span>
                    <br />

                    {v?.repairing == "1" && (
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "orange",
                        }}
                      >
                        정비중
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceList;
