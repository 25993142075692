import { useEffect } from "react";
import { OPEN_MENU_VAR } from "../utils/reactiveVars";

const VideoListMobile = ()=>{
    OPEN_MENU_VAR(false);
    const srcArr = [
    "https://www.youtube.com/embed/J6NM_FjgH-I?si=JkEh4mirJwOMUe8C&autoplay=0&mute=0&controls=0&loop=1&playlist=J6NM_FjgH-I",
    "https://www.youtube.com/embed/MWQDf1hbxyg?si=LA8Pnxez0FOBdQ97&autoplay=0&mute=0&controls=0&loop=1&playlist=MWQDf1hbxyg",
    "https://www.youtube.com/embed/baopJ_m4L4w?autoplay=0&mute=0&controls=0&si=ieS7jCdoxIBfNpmJ&loop=1&playlist=baopJ_m4L4w",
    // "https://www.youtube.com/embed/3PsJ9e2Q42U?si=V3_7PFCYuddCGA_o&autoplay=0&mute=0&controls=0",
    "https://www.youtube.com/embed/qkg9s28R3q8?si=HMMfGYt0O1gUV96X&autoplay=0&mute=0&controls=0&loop=1&playlist=qkg9s28R3q8",
    "https://www.youtube.com/embed/7aKPLQeQvtQ?si=6AUEnks-IsAmLnsM&autoplay=0&mute=0&controls=0&loop=1&playlist=7aKPLQeQvtQ",
    "https://www.youtube.com/embed/hS9KuGT_42Y?si=U0uUYYnaQzBcGHVH&autoplay=0&mute=0&controls=0&loop=1&playlist=hS9KuGT_42Y"
    ];
  const isMobile = matchMedia("screen and (max-width: 600px)").matches ? true :false;
    useEffect(() => {
        const js_libs = [
          // "lib/jquery/jquery.min.js",
          // "lib/bootstrap/js/bootstrap.min.js",
          // "lib/owlcarousel/owl.carousel.min.js",
          // "lib/venobox/venobox.min.js",
          // "lib/knob/jquery.knob.js",
          // "lib/wow/wow.min.js",
          // "lib/parallax/parallax.js",
          // "lib/easing/easing.min.js",
          // "lib/nivo-slider/js/jquery.nivo.slider.js",
          // "lib/appear/jquery.appear.js",
          // "lib/isotope/isotope.pkgd.min.js",
          "contactform/contactform.js",
          "js/main.js",
        ];
        js_libs.forEach((lib) => {
          const script = document.createElement("script");
          script.src = lib;
          script.async = true;
          document.body.appendChild(script);
        });
      }, []);
    return(
        <div style={{
            display:"flex",
            flexDirection:"column"
        }}>
            {
                <>
                    <div style={{width:"100%",height:"70px",backgroundColor:"black"}}/>
                    <div className="videoGrid" style={{
                        display:"grid",
                        width:"100%",

                        gridTemplateColumns: isMobile ? `repeat(1, minmax(100% , auto))` : `repeat(3, minmax(calc(100% / 3), auto))`,
                        }}> 
                        {
                        
                        srcArr.map((v)=>{
                            return(

                            <div className="single-well" style={{
                                aspectRatio:"16/9",
                                width:"99.9%",
                                height:"99.9%",
                                outline:"1px solid #ccc"
                            }}>

                                <iframe
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    aspectRatio: "16/9"
                                }}
                                src={v}
                                title="AI 재난안전 실증"
                                frameBorder="0"
                                // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                ></iframe>
                            </div>
                            )
                        })
                        }           
                    </div>
                </>
        }
        </div>
    )
}
export default VideoListMobile;