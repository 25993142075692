import { useReactiveVar } from "@apollo/client";
import styled from "styled-components";
// import MyResponsiveBar from "../components/charts/barChart";
// import MyResponsiveLine from "../components/charts/lineChart";
// import KoreaDivisionSvg from "../components/main/KoreaDivisionSvg";
import LocationTable from "../components/main/LocationTable";
import SearchBox from "../components/main/SearchBox";
import { OPEN_MENU_VAR, searchedDataForBarVar } from "../utils/reactiveVars";
// import os from "os";
// import { detect } from "detect-browser";
import HighBarChart from "../components/charts/highBarChart";
import HighLineChart from "../components/charts/highLineChart";
// import VWorldMap from "../components/vworld/VWorldMap";
// import DownpourPredictionTable from "../components/main/DownpourPredictionTable";
import MapandDownpour from "../components/main/MapandDownpour";
import Devices from "../components/main/Devices";
import { GOOGLE_DOWNLOAD, APPLE_DOWNLOAD } from "../static/images";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import lib from "react-https-redirect";

const Container = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
  margin-top: 100px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const ChartCon = styled.div`
  min-height: 300px;
`;
const FixedStore = styled.div`
  position: fixed;
  top: 100px;
  right: 100px;
`;
const DownloadImg = styled.img`
  width: 100px;
  z-index: 100;
`;

const Main = () => {
  OPEN_MENU_VAR(false);
  const searchedDataForBar = useReactiveVar(searchedDataForBarVar);
  //console.log("os : ", os.platform());
  //alert(detect().os.includes("Android"));
  useEffect(() => {
    const js_libs = [
      // "lib/jquery/jquery.min.js",
      // "lib/bootstrap/js/bootstrap.min.js",
      // "lib/owlcarousel/owl.carousel.min.js",
      // "lib/venobox/venobox.min.js",
      // "lib/knob/jquery.knob.js",
      // "lib/wow/wow.min.js",
      // "lib/parallax/parallax.js",
      // "lib/easing/easing.min.js",
      // "lib/nivo-slider/js/jquery.nivo.slider.js",
      // "lib/appear/jquery.appear.js",
      // "lib/isotope/isotope.pkgd.min.js",
      "contactform/contactform.js",
      "js/main.js",
    ];
    js_libs.forEach((lib) => {
      const script = document.createElement("script");
      script.src = lib;
      script.async = true;
      document.body.appendChild(script);
    });
  }, []);
  return (
    <div>
      {/* Start Slider Area */}
      <div id="home" className="slider-area">
        <div className="bend niceties preview-2">
          <div id="ensign-nivoslider" className="slides">
            <img
              src="img/slider/slider1.jpg"
              alt=""
              title="#slider-direction-1"
            ></img>
            <img
              src="img/slider/slider2.jpg"
              alt=""
              title="#slider-direction-2"
            ></img>
            <img
              src="img/slider/slider3.jpg"
              alt=""
              title="#slider-direction-3"
            ></img>
          </div>

          {/* direction 1 */}
          <div id="slider-direction-1" className="slider-direction slider-one">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="slider-content">
                    {/* layer 1 */}
                    <div
                      className="layer-1-1 hidden-xs wow slideInDown"
                      data-wow-duration="2s"
                      data-wow-delay=".2s"
                    >
                      <h2 className="title1">
                        인공지능 AI 기술을 통한 수위측정을 체험하세요!
                      </h2>
                    </div>
                    {/* layer 2 */}
                    <div
                      className="layer-1-2 wow slideInUp"
                      data-wow-duration="2s"
                      data-wow-delay=".1s"
                    >
                      <h1 className="title2">
                        인공지능을 통한 홍수 재난안전 빅데이터 연동 경험
                      </h1>
                    </div>
                    {/* layer 3 */}
                    <div
                      className="layer-1-3 hidden-xs wow slideInUp"
                      data-wow-duration="2s"
                      data-wow-delay=".2s"
                    >
                      <a
                        className="ready-btn right-btn page-scroll"
                        href="https://bbeego.com/"
                        target="_blank"
                      >
                        인공지능 학습 참여하기
                      </a>
                      <a className="ready-btn page-scroll" href="#about">
                        자세한 설명
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* direction 2 */}
          <div id="slider-direction-2" className="slider-direction slider-two">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="slider-content text-center">
                    {/* layer 1 */}
                    <div
                      className="layer-1-1 hidden-xs wow slideInUp"
                      data-wow-duration="2s"
                      data-wow-delay=".2s"
                    >
                      <h2 className="title1">최고의 비즈니스 솔루션을 제공</h2>
                    </div>
                    {/* layer 2 */}
                    <div
                      className="layer-1-2 wow slideInUp"
                      data-wow-duration="2s"
                      data-wow-delay=".1s"
                    >
                      <h1 className="title2">
                        다양한 기후변화를 AI 기술을 통해 치밀하고 심도있게 관찰
                      </h1>
                    </div>
                    {/* layer 3 */}
                    <div
                      className="layer-1-3 hidden-xs wow slideInUp"
                      data-wow-duration="2s"
                      data-wow-delay=".2s"
                    >
                      <a
                        className="ready-btn right-btn page-scroll"
                        href="#services"
                      >
                        WaterAI 서비스
                      </a>
                      <a className="ready-btn page-scroll" href="#about">
                        자세한 설명
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* direction 3 */}
          <div id="slider-direction-3" className="slider-direction slider-two">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="slider-content">
                    {/* layer 1 */}
                    <div
                      className="layer-1-1 hidden-xs wow slideInUp"
                      data-wow-duration="2s"
                      data-wow-delay=".2s"
                    >
                      <h2 className="title1">
                        하천의 홍수를 감시 감독하는 더 정확하고 편리한 시스템
                      </h2>
                    </div>
                    {/* layer 2 */}
                    <div
                      className="layer-1-2 wow slideInUp"
                      data-wow-duration="2s"
                      data-wow-delay=".1s"
                    >
                      <h1 className="title2">
                        일상생활속의 안전을 인공지능을 통해 24시간 모니터링
                      </h1>
                    </div>
                    {/* layer 3 */}
                    <div
                      className="layer-1-3 hidden-xs wow slideInUp"
                      data-wow-duration="2s"
                      data-wow-delay=".2s"
                    >
                      <a
                        className="ready-btn right-btn page-scroll"
                        href="#services"
                      >
                        WaterAI 서비스
                      </a>
                      <a className="ready-btn page-scroll" href="#about">
                        자세한 설명
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Slider Area */}

      {/* Start graph area */}
      <div id="graph" style={{display:"none"}}>
        {/* <img src="img/graph.png" alt="index.html" title="waterAI" /> */}
        {/* <div id="root"></div> */}
        <Container id="main-intro">
          {/* <FixedStore>
        <Link to="https://play.google.com/store/apps/details?id=com.wateraiinfo">
        <DownloadImg src={GOOGLE_DOWNLOAD} />
        </Link>
        <DownloadImg src={APPLE_DOWNLOAD} />
      </FixedStore> */}
          <div className="section dashboard">
            <Row>
              <div className="col-md-7">
                <Row>
                  <MapandDownpour />
                </Row>
                {/* <VWorldMap /> */}
              </div>
              <div className="col-md-5">
                <Row>
                  <div className="col-md-12">
                    <SearchBox />
                  </div>
                </Row>
                <Row>
                  <ChartCon className="col-md-12">
                    <Devices />
                  </ChartCon>
                </Row>
                <Row>
                  <ChartCon className="col-md-12">
                    {/* <MyResponsiveBar data={searchedDataForBar} /> */}
                    <HighBarChart data={searchedDataForBar} />
                  </ChartCon>
                </Row>
                <Row>
                  <ChartCon className="col-md-12">
                    {/* <MyResponsiveLine data={searchedDataForBar} /> */}
                    <HighLineChart data={searchedDataForBar} />
                  </ChartCon>
                </Row>
                <Row>
                  <div className="col-md-12">
                    <LocationTable />
                  </div>
                </Row>
              </div>
            </Row>
          </div>
        </Container>
      </div>
      {/* End graph area */}
      {/* Start About area */}
      <div id="about" className="about-area area-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="section-headline text-center">
                <h2>About WaterAI Business</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {/* single-well start*/}
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="well-left">
                <div className="single-well">
                  <a href="#">
                    <iframe
                    style={{aspectRatio:"16/9"}}
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/rd2bomtm3LM?autoplay=1&mute=1"
                      title="AI 재난안전 실증"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </a>
                </div>
              </div>
            </div>
            {/* single-well end*/}
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="well-middle">
                <div className="single-well">
                  <a href="#">
                    <h4 className="sec-head">AI 재난안전 스마트 계측 시스템</h4>
                  </a>
                  <p>
                    우리나라에서 발생되는 자연재해는 호우 또는 태풍으로 인한
                    발생되는 경우가 많으며, 센서를 통한 홍수로인한 소하천인
                    경우에는 물밖에 CCTV로인한 계측하는것이 AI로 인한
                    인공지능으로 우리나라 기후를 테스트를 할수 있다.
                  </p>
                  <ul>
                    <li>
                      <i className="fa fa-check"></i> 영상정보 처리기술
                    </li>
                    <li>
                      <i className="fa fa-check"></i> 이미지정보처리기술
                    </li>
                    <li>
                      <i className="fa fa-check"></i> 데이터학습 딥러닝기술
                    </li>
                    <li>
                      <i className="fa fa-check"></i> 빅데이터분석기술
                    </li>
                    <li>
                      <i className="fa fa-check"></i> 소하천 홍수로인한
                      인공지능으로 데이터 분석 기술
                    </li>
                  </ul>
                </div>
                
              </div>
            </div>
            {/* End col*/}
          </div>
        </div>
      </div>
      {/* End About area */}

      {/* Start Service area */}
      <div id="services" className="services-area area-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="section-headline services-head text-center">
                <h2>WaterAI 주력서비스</h2>
              </div>
            </div>
          </div>
          <div className="row text-center">
            <div className="services-contents">
              {/* Start Left services */}
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <div className="about-move">
                    <div className="services-details">
                      <div className="single-services">
                        <a className="services-icon" href="#">
                          <i className="fa fa-file-video-o"></i>
                        </a>
                        <h4>영상정보처리기술</h4>
                        <p>
                          - 수위영상처리,유속영상처리,우량영상처리기술
                          <br />
                          - 연속프레임이미지에서 Snap Shot 이미지를 캡쳐
                          <br />
                          - 수위이미지정보처리 AI에 전달
                          <br />- 분석 및 계측단계까지 끊김없이 양질의 이미지를
                          추출
                        </p>
                      </div>
                    </div>
                    {/* end about-details */}
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <div className="about-move">
                    <div className="services-details">
                      <div className="single-services">
                        <a className="services-icon" href="#">
                          <i className="fa fa-camera-retro"></i>
                        </a>
                        <h4>이미지정보처리기술</h4>
                        <p>
                          - 수위이미지처리
                          <br />
                          - 목차이미지, 실제수위계참값비교딥러닝
                          <br />- 수위및설위계측
                        </p>
                      </div>
                    </div>
                    {/* end about-details */}
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  {/* end col-md-4 */}
                  <div className="about-move">
                    <div className="services-details">
                      <div className="single-services">
                        <a className="services-icon" href="#">
                          <i className="fa fa-code"></i>
                        </a>
                        <h4>데이터학습 딥러닝기술</h4>
                        <p>
                          - 수위데이터,유속데이터,유량데이터에 대한 딥러닝
                          <br />
                          - 고성능의 GPS 서버가 필수적인기술
                          <br />- 수위이미지정보처리 AI에 전달
                        </p>
                      </div>
                    </div>
                    {/* end about-details */}
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  {/* end col-md-4 */}
                  <div className="about-move">
                    <div className="services-details">
                      <div className="single-services">
                        <a className="services-icon" href="#">
                          <i className="fa fa-database"></i>
                        </a>
                        <h4>빅데이터 분석기술</h4>
                        <p>
                          - 소하천 및 계곡의 수위,유속,유량등의 계측정보와
                          기상청 날씨마루와 같은 기상기후빅데이터분석
                          플랫폼으로부터 취득한 호우피해이력정보와
                          호우피해예측정보 융합하여 데이터셋으로 축적
                          <br />- 제안사의 자동기상관측장치 및 원격
                          우량측정시스템으로부터 탄천 등 소하천 유역의 기상 및
                          우량데이터 기초로 빅데이터분석
                        </p>
                      </div>
                    </div>
                    {/* end about-details */}
                  </div>
                </div>
              </div>
            </div>
          </div>
           <div className="single-well">
          <a style={{paddingTop : "5%"}} href="#">
          <iframe width="100%" height="550px" src="https://www.youtube.com/embed/mMX2i4UZGuQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </a>
        </div>
        </div>
       
      </div>
      {/* End Service area */}

      {/* our-skill-area start */}
      <div className="our-skill-area fix hidden-sm">
        <div className="test-overly"></div>
        <div className="skill-bg area-padding-2">
          <div className="container">
            {/* section-heading end */}
            <div className="row">
              <div className="skill-text">
                {/* single-skill start */}
                <div className="col-xs-12 col-sm-3 col-md-3 text-center">
                  <div className="single-skill">
                    <div className="progress-circular">
                      <input
                        type="text"
                        className="knob"
                        value="0"
                        data-rel="95"
                        data-linecap="round"
                        data-width="175"
                        data-bgcolor="#fff"
                        data-fgcolor="#3EC1D5"
                        data-thickness=".20"
                        data-readonly="true"
                        disabled
                      />
                      <h3 className="progress-h4">영상정보처리기술</h3>
                    </div>
                  </div>
                </div>
                {/* single-skill end */}
                {/* single-skill start */}
                <div className="col-xs-12 col-sm-3 col-md-3 text-center">
                  <div className="single-skill">
                    <div className="progress-circular">
                      <input
                        type="text"
                        className="knob"
                        value="0"
                        data-rel="85"
                        data-linecap="round"
                        data-width="175"
                        data-bgcolor="#fff"
                        data-fgcolor="#3EC1D5"
                        data-thickness=".20"
                        data-readonly="true"
                        disabled
                      />
                      <h3 className="progress-h4">데이터학습딥러닝기술</h3>
                    </div>
                  </div>
                </div>
                {/* single-skill end */}
                {/* single-skill start */}
                <div className="col-xs-12 col-sm-3 col-md-3 text-center">
                  <div className="single-skill">
                    <div className="progress-circular">
                      <input
                        type="text"
                        className="knob"
                        value="0"
                        data-rel="75"
                        data-linecap="round"
                        data-width="175"
                        data-bgcolor="#fff"
                        data-fgcolor="#3EC1D5"
                        data-thickness=".20"
                        data-readonly="true"
                        disabled
                      />
                      <h3 className="progress-h4">이미지정보처리기술</h3>
                    </div>
                  </div>
                </div>
                {/* single-skill end */}
                {/* single-skill start */}
                <div className="col-xs-12 col-sm-3 col-md-3 text-center">
                  <div className="single-skill">
                    <div className="progress-circular">
                      <input
                        type="text"
                        className="knob"
                        value="0"
                        data-rel="65"
                        data-linecap="round"
                        data-width="175"
                        data-bgcolor="#fff"
                        data-fgcolor="#3EC1D5"
                        data-thickness=".20"
                        data-readonly="true"
                        disabled
                      />
                      <h3 className="progress-h4">빅데이터분석기술</h3>
                    </div>
                  </div>
                </div>
                {/* single-skill end */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* our-skill-area end */}

      {/* Faq area start */}
      <div id="faq" className="faq-area area-padding"></div>
      {/* End Faq Area */}

      {/* Start reviews Area */}
      <div className="reviews-area hidden-xs">
        <div className="work-us">
          <div className="work-left-text">
            <a href="#">
              <img src="img/about/2.jpg" alt=""></img>
            </a>
          </div>
          <div className="work-right-text text-center">
            <h2>WaterAI 인공지능 기술문의</h2>
            <h5>AI 재난안전 WaterAI 솔루션 도입 관련  문의해주세요!</h5>
            <a href="#contact" className="ready-btn">
              기술문의
            </a>
          </div>
        </div>
      </div>
      {/* End reviews Area */}

      {/* Start portfolio Area */}
      <div id="portfolio" className="portfolio-area area-padding fix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="section-headline text-center">
                <h2>WaterAI 인공지능 기술</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Start Portfolio -page */}
            <div className="awesome-project-1 fix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="awesome-menu">
                  <ul className="project-menu">
                    <li>
                      <a href="#" className="active" data-filter="*">
                        All
                      </a>
                    </li>
                    <li>
                      <a href="#" data-filter=".development">
                        소하천 계측
                      </a>
                    </li>
                    <li>
                      <a href="#" data-filter=".design">
                        인공지능 AI 기술을 통한 수위 측정
                      </a>
                    </li>
                    <li>
                      <a href="#" data-filter=".photo">
                        하천인근 계측 상황
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="awesome-project-content">
              {/* single-awesome-project start */}
              <div className="col-md-4 col-sm-4 col-xs-12 design development custom_position">
                <div className="single-awesome-project">
                  <div className="awesome-img">
                    <a href="#">
                      <img src="img/portfolio/1.jpg" alt=""></img>
                    </a>
                    <div className="add-actions text-center">
                      <div className="project-dec">
                        <a
                          className="venobox"
                          data-gall="myGallery"
                          href="img/portfolio/1.jpg"
                        >
                          <h4>소하천 계측</h4>
                          <span>소하천 계측 홍수나 태풍 강수량측정</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* single-awesome-project end */}
              {/* single-awesome-project start */}
              <div className="col-md-4 col-sm-4 col-xs-12 photo custom_position">
                <div className="single-awesome-project">
                  <div className="awesome-img">
                    <a href="#">
                      <img src="img/portfolio/2.jpg" alt=""></img>
                    </a>
                    <div className="add-actions text-center">
                      <div className="project-dec">
                        <a
                          className="venobox"
                          data-gall="myGallery"
                          href="img/portfolio/2.jpg"
                        >
                          <h4>하천 계측기</h4>
                          <span>수시로 강수량 범람을 체크할수 있는 이미지</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* single-awesome-project end */}
              {/* single-awesome-project start */}
              <div className="col-md-4 col-sm-4 col-xs-12 design custom_position">
                <div className="single-awesome-project">
                  <div className="awesome-img">
                    <a href="#">
                      <img src="img/portfolio/3.jpg" alt=""></img>
                    </a>
                    <div className="add-actions text-center">
                      <div className="project-dec">
                        <a
                          className="venobox"
                          data-gall="myGallery"
                          href="img/portfolio/3.jpg"
                        >
                          <h4>실시간 CCTV</h4>
                          <span>실시간 동영상을 송출하여 계측시스템</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* single-awesome-project end */}
              {/* single-awesome-project start */}
              <div className="col-md-4 col-sm-4 col-xs-12 photo development custom_position">
                <div className="single-awesome-project">
                  <div className="awesome-img">
                    <a href="#">
                      <img src="img/portfolio/4.jpg" alt=""></img>
                    </a>
                    <div className="add-actions text-center">
                      <div className="project-dec">
                        <a
                          className="venobox"
                          data-gall="myGallery"
                          href="img/portfolio/4.jpg"
                        >
                          <h4>소하천 계측기</h4>
                          <span>하천에 계측을 실시간 검사</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* single-awesome-project end */}
              {/* single-awesome-project start */}
              <div className="col-md-4 col-sm-4 col-xs-12 development custom_position">
                <div className="single-awesome-project">
                  <div className="awesome-img">
                    <a href="#">
                      <img src="img/portfolio/5.jpg" alt=""></img>
                    </a>
                    <div className="add-actions text-center text-center">
                      <div className="project-dec">
                        <a
                          className="venobox"
                          data-gall="myGallery"
                          href="img/portfolio/5.jpg"
                        >
                          <h4>소하천 실시간 동영상 송출</h4>
                          <span>인공지능으로 실시간 영상 송출시스템</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* single-awesome-project end */}
              {/* single-awesome-project start */}
              <div className="col-md-4 col-sm-4 col-xs-12 design photo custom_position">
                <div className="single-awesome-project">
                  <div className="awesome-img">
                    <a href="#">
                      <img src="img/portfolio/6.jpg" alt=""></img>
                    </a>
                    <div className="add-actions text-center">
                      <div className="project-dec">
                        <a
                          className="venobox"
                          data-gall="myGallery"
                          href="img/portfolio/6.jpg"
                        >
                          <h4>소하천 CCTV 설치</h4>
                          <span>소하천 부지에 CCTV설치로 인한 실시간 감시</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* single-awesome-project end */}
            </div>
          </div>
        </div>
      </div>
      {/* awesome-portfolio end */}

      <div id="contact" className="contact-area">
        <div className="contact-inner area-padding">
          <div className="contact-overly"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="section-headline text-center">
                  <h2>WaterAI CES 혁신상</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <img style={{
                  width:"100%",
                  height:"auto",
                  objectFit:"contain"
                }}
                 src="/img/ces.jpg"></img>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div id="contact" className="contact-area">
        <div className="contact-inner area-padding">
          <div className="contact-overly"></div>
          <div className="container">

            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="section-headline text-center">
                  <h2>WaterAI 과학기술정보통신부 장관상</h2>
                </div>
              </div>
            </div>
            <div className="row" style={{
              display:"flex",
              flexDirection:"row",
              justifyContent: "space-between"
            }}>
              <div className="col-md-4 col-sm-4 col-xs-4">
                <img style={{
                  width:"100%",
                  height:"100%",
                  objectFit:"cover"
                }}
                 src="/img/testimonial_1.png"></img>
              </div>
              <div className="col-md-8 col-sm-8 col-xs-8">
                <img style={{
                  width:"100%",
                  height:"85%",
                  objectFit:"cover",
                  marginTop: "6.7%"
                }}
                 src="/img/testimonial_2.png"></img>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start contact Area */}
      <div id="contact" className="contact-area">
        <div className="contact-inner area-padding">
          <div className="contact-overly"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="section-headline text-center">
                  <h2>WaterAI 기술문의</h2>
                </div>
              </div>
            </div>
            <div className="row">
              {/* Start contact icon column */}
              <div className="col-md-4 col-sm-4 col-xs-12">
                {/*div className="contact-icon text-center">
                  <div className="single-icon">
                    <i className="fa fa-mobile"></i>
                    <p>
                      모바일: 010-9977-9112<br />
                      <span>월요일-금요일 (오전 9시- 오후 5시)</span>
                    </p>
                  </div*/}
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12"></div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="contact-icon text-center">
                <div className="single-icon">
                  <i className="fa fa-map-marker"></i>
                  <p>
                    사무실: 서울시 송파구 법원로 90, 502
                    <br />
                    <span>(문정동, 파트너스2)</span>
                  </p>
                </div>
              </div>
            </div>
            {/* Start contact icon column */}
            <div className="col-md-4 col-sm-4 col-xs-12">
              {/*-div className="contact-icon text-center">
                  <div className="single-icon">
                    <i className="fa fa-envelope-o"></i>
                    <p>
                      이메일: lee@jjnet.co.kr<br />
                      <span>사이트: www.waterai.co.kr</span>
                    </p>
                  </div>
                </div-*/}
            </div>
            {/* Start contact icon column */}
            <div className="col-md-4 col-sm-4 col-xs-12"></div>
          </div>
          <div className="row">
            {/* Start Google Map */}
            <div className="col-md-12 col-sm-6 col-xs-12">
              {/* Start Map */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.1176006321616!2d127.11983111523256!3d37.481551130739994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca5f46d919453%3A0xa2db693f83321b05!2z7YyM7Yq464SI7IqkIElJ!5e0!3m2!1sko!2skr!4v1668610323404!5m2!1sko!2skr"
                width="100%"
                height="380"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
              {/* End Map */}
            </div>
            {/* End Google Map */}

            {/* Start  contact */}
            <div className="col-md-6 col-sm-6 col-xs-12">
              {/*-div className="form contact-form">
                  <div id="sendmessage">
                    귀하의 메시지가 전송되었습니다. 고맙습니다!
                  </div>
                  <div id="errormessage"></div>
                  <form action="" method="post" role="form" className="contactForm">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="담당자성함"
                        data-rule="minlen:4"
                        data-msg="4자를 넘기지 않도록 주의해주세요!"
                      />
                      <div className="validation"></div>
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="담당자 이메일주소"
                        data-rule="email"
                        data-msg="담당자님 이메일주소를 정확히 입력바랍니다."
                      />
                      <div className="validation"></div>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        id="subject"
                        placeholder="상담 제묵입력"
                        data-rule="minlen:4"
                        data-msg="제목은 8자이상 넘지않게 부탁드립니다."
                      />
                      <div className="validation"></div>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        name="message"
                        rows="5"
                        data-rule="required"
                        data-msg="WaterAI계측상황관련 기술문의를 상세내용적어주세요!감사합니다."
                        placeholder="기술상담 상세내용적어주세요"
                      ></textarea>
                      <div className="validation"></div>
                    </div>
                    <div className="text-center">
                      <button type="submit">기술문의 보내기</button>
                    </div>
                  </form>
                </div--*/}
            </div>
            {/* End Left contact */}
          </div>
        </div>
      </div>
      {/* End Contact Area */}

      <a href="#" className="back-to-top">
        <i className="fa fa-chevron-up"></i>
      </a>
    </div>
  );
};

export default Main;
