import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  downpourDataVar,
  fixLocVar,
  locCodeVar,
  searchedDataForBarVar,
} from "../../utils/reactiveVars";

const Container = styled.div`
  position: relative;
`;
const Search = styled.div`
  height: 100px;
`;
const CurrentLoc = styled.div`
  position: absolute;
  top: 150px;
  left: 50px;
  font-size: 20px;
  font-weight: bold;
`;

const KoreaDivisionSvg = () => {
  const [loc, setLoc] = useState();
  const [locCode, setLocCode] = useState();
  //const [fixLoc, setFixLoc] = useState();
  const svgRef = useRef();

  useEffect(() => {
    if (svgRef?.current) {
      const paths = svgRef.current?.getElementsByTagName("path");
      for (let i = 0; i < paths.length; i++) {
        if (paths[i].closest("g")?.id == "레이어_19") {
          continue;
        }
        paths[i].style.cursor = "pointer";
        paths[i].addEventListener("mouseover", (e) => {
          //console.log(paths[i].id);
          e.target.setAttribute("fill", "#0652DD");
          setLoc(paths[i].id);
        });
        paths[i].addEventListener("mouseout", (e) => {
          e.target.setAttribute("fill", "rgb(185, 185, 185)");
        });
        paths[i].addEventListener("click", async (e) => {
          //await locCodeVar(null);
          searchedDataForBarVar([]);
          downpourDataVar([]);
          await setLocCode(paths[i].dataset.code);
          await fixLocVar(paths[i].id);
        });
      }
    }
  }, [svgRef]);

  useEffect(() => {
    locCodeVar(locCode);
    if (locCode) {
      //console.log("locCode : ", locCode);
    }
  });

  //svg 기능 관련
  //마우스가 svg 에 올라가 있는지
  const [isSvgOver, setIsSvgOver] = useState(false);
  //마우스가 클릭된 상태인지
  const [isSvgMouseDown, setIsSvgMouseDown] = useState(false);
  //줌인에 사용
  const [viewBoxX, setViewBoxX] = useState(400);
  const [viewBoxY, setViewBoxY] = useState(400);
  //좌표이동에 사용
  const [coordX, setCoordX] = useState(0);
  const [coordY, setCoordY] = useState(100);
  //svg 클릭시 위치 좌표
  const [coord, setCoord] = useState({ x: 0, y: 0 });

  const onSvgWheel = (e) => {
    console.log("e.deltaY : ", e.deltaY);
    if (e.deltaY <= 0) {
      setCoordX(coordX + 50);
      setCoordY(coordY + 50);
    } else if (e.deltaY >= 0) {
      setCoordX(coordX - 50);
      setCoordY(coordY - 50);
    }
    setViewBoxX(viewBoxX + e.deltaY <= 0 ? 0 : viewBoxX + e.deltaY);
    setViewBoxY(viewBoxY + e.deltaY <= 0 ? 0 : viewBoxY + e.deltaY);
  };
  const onSvgIn = () => {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    setIsSvgOver(true);
  };
  const onSvgOut = () => {
    document.getElementsByTagName("body")[0].style.overflow = "auto";
    setIsSvgOver(false);
  };
  const onSvgMouseDown = (e) => {
    setIsSvgMouseDown(true);
    setCoord({ x: e.clientX, y: e.clientY });
    //console.log("e : ", e);
  };
  const onSvgMouseUp = () => {
    setIsSvgMouseDown(false);
  };
  const onDrag = (e) => {
    if (isSvgMouseDown && isSvgOver) {
      if (coord.x > e.clientX) {
        setCoordX(coordX + (coord.x ? Math.abs(coord.x - e.clientX) : 0));
      } else if (coord.x < e.clientX) {
        setCoordX(coordX - (coord.x ? Math.abs(coord.x - e.clientX) : 0));
      }
      if (coord.y > e.clientY) {
        setCoordY(coordY + (coord.y ? Math.abs(coord.y - e.clientY) : 0));
      } else if (coord.y < e.clientY) {
        setCoordY(coordY - (coord.y ? Math.abs(coord.y - e.clientY) : 0));
      }
      setCoord({ x: e.clientX, y: e.clientY });
    }
  };
  return (
    <Container ref={svgRef}>
      <CurrentLoc>{loc && loc}</CurrentLoc>
      <svg
        style={{ boxShadow: "0 0 10px -5px gray" }}
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="700px"
        height="1540px"
        viewBox={`${coordX} ${coordY} ${viewBoxX ? viewBoxX : 200} ${
          viewBoxY ? viewBoxY : 200
        }`}
        enableBackground="new 0 0 509 716.105"
        preserveAspectRatio="xMidYMid meet"
        onWheel={onSvgWheel}
        onMouseOver={onSvgIn}
        onMouseOut={onSvgOut}
        onMouseDown={onSvgMouseDown}
        onMouseUp={onSvgMouseUp}
        onMouseMove={onDrag}
      >
        <g id="서울특별시">
          <path
            id="강동구"
            data-code="11250"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M171.5,167.272v2.167l1.167,1.25l-0.25,1.083l1.083,1.75l1.917,0.583
l0.333-1.5l3.833-2.833l-0.333-2.5l-0.833-1.667l-3.667-0.333L171.5,167.272"
          />
          <path
            id="송파구"
            data-code="11240"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M171.5,169.439l-2.25,3.25l-3.333,0.417v2.5l2.833,2.25l1-0.083l0.667,1
l1.167,0.917l1.25,2.5l3.75-4.583l-1.167-3v-0.5l-1.917-0.583l-0.917-1.917l0.083-0.917L171.5,169.439z"
          />
          <path
            id="광진구"
            data-code="11050"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M168.583,165.605l-1.5,0.25l-0.583,1.75l-1.25,3l-0.583,1.917l1.25,0.583
l3.333-0.417l2.25-3.25l-0.083-1.917l-1.833-3.083L168.583,165.605z"
          />
          <path
            id="중랑구"
            data-code="11070"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M166.667,158.939l0.417,6.917l1.5-0.25l3-2.917l-0.167-3.917L171,157.855
l-2.583,0.083L166.667,158.939z"
          />
          <path
            id="노원구"
            data-code="11110"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M164.917,146.605l-0.833,2.833l0.667,2.417l-0.5,3.75l-1.083,0.417
l1.75,2.083l1.917,1l1.583-1.167l3,0.333l-3-5.333l-0.5-6L164.917,146.605"
          />
          <path
            id="강남구"
            data-code="11230"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M164.667,172.522L162,171.689l-2.667-0.25l-0.833,1l1.083,2.833l0.5,1.083
l0.333,2.167l1.833,1.417l0.833,1.833l0.667,0.833l1.417-0.417l0.75-0.75l1.917,0.167l0.25,1.25l0.917,0.5l0.75-0.75l3-0.167
l-1.167-2.75l-1.167-0.917l-0.667-1l-1,0.083l-2.833-2.25l0.083-2.25L164.667,172.522z"
          />
          <path
            id="성동구"
            data-code="11040"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M160.5,165.855l-0.25,1.583l-1.083,1.083l-0.5,0.917l0.667,2l2.667,0.25
l2.583,1l1.75-4.083l0.167-1l-2.5-0.917l-0.917-1.083L162,165.689l-0.833,0.5L160.5,165.855z"
          />
          <path
            id="동대문구"
            data-code="11060"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M160.583,164.605l0.667-1.417l1.667-1.167l1.167-1.417l2.75-0.5
l0.25,5.75l-0.667,2L164,166.689l-1.25-0.833l-1,0.167l-0.583,0.167l-0.667-0.333L160.583,164.605z"
          />
          <path
            id="성북구"
            data-code="11080"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M162.5,158.855l-3-0.083l-0.917-1.917l-3.417-2.167l-0.75,1.167l1,2
l0.5,2.167l-0.833,0.833l0.083,0.667l2.25,0.667l1.75,2.417h1.417l0.667-1.417l1.583-0.917l1.583-1.667l2.417-0.5l-0.25-1.333
l-1.5-1l-1.417-1.25L162.5,158.855z"
          />
          <path
            id="강북구"
            data-code="11090"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M160.75,152.939l-2.25-1.333l0.583-2.917l-0.667-2.167l-2.5,2.417
l-0.833,5l0.083,1.083l3,1.75l1.333,2l3,0.083l1.417-2.167L160.75,152.939z"
          />
          <path
            id="도봉구"
            data-code="11100"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M164.75,146.959l-1.542,0.771l-3.854-2.583l-1.083,1.5l0.625,2.021
l-0.479,3.104l2.313,1.229l2.75,3.271l1.042-0.583l0.458-3.813l-0.75-2.271L164.75,146.959z"
          />
          <path
            id="서초구"
            data-code="11220"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M155.5,175.043l3-2.604l1.583,3.917l0.417,1.938l2,1.75l1.25,2.375
l1.417-0.229l0.646-0.896l2.25,0.188l0.021,1.375l0.917,0.5l-3.417,5.083l-2.646-0.833l-1.521-4.167l-5.542-0.333l-0.813-2.188
l0.313-3.625l-0.688-0.938l-0.938-0.313l-0.125-1H155.5z"
          />
          <path
            id="용산구"
            data-code="11030"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M151.813,173.98l-1.813-2l2.938-3.5l3.125,0.063l0.625,0.438l1.979,0.458
l0.667,2l-0.958,1.417l-2.875,2.188h-1.875L151.813,173.98z"
          />
          <path
            id="중구"
            data-code="11020"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M152.313,167.043l1.063-1.188l2-0.375l1,0.563l4.125-0.188l-0.25,1.583
l-1.083,1.083l-0.542,1.271l-1.438-0.5l-1.125-0.75h-2.938L152.313,167.043z"
          />
          <path
            id="종로구"
            data-code="11010"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M151.688,156.98l0.25,3.688l0.5,0.813l0.188,1.813l-0.5,1.25l1.25,1.563
l1.875-0.313l1.25,0.438l3.813-0.313l0.438-1.5l-1.313-0.125l-2.063-2.25l-2.313-0.75l1-1.313l-0.688-2.688l-2.188-1.938
L151.688,156.98z"
          />
          <path
            id="은평구"
            data-code="11120"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M145.438,163.855l-2-1.688l1.75-1.063l0.375-6.125l2.188-0.813l2.75-2.938
l1.583,3.042l1.292,1.271l-1.438,1.5v2.938l-1.25,0.063l-0.75,1.125l-1.063,0.313l-0.625,1.063l-1.313,0.063L145.438,163.855z"
          />
          <path
            id="서대문구"
            data-code="11130"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M148.875,167.105l-3.125-3.5l1.188-1l1.313-0.063l0.625-1.063
l1.063-0.313l0.75-1.125l1.25-0.063l0.063,1l0.688,0.563l-0.063,1.75l-0.438,1l1.188,1.813l-1.063,0.938l-0.813,0.5
L148.875,167.105z"
          />
          <path
            id="마포구"
            data-code="11140"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M148.188,170.668l-4.938-2.25l-2.813-2.188l-2-2.125l2.188,0.125
l2.438-2.188l0.75,0.125l1.563,1.5l0.75,0.25l2.75,2.875l2.813,0.563l0.75-0.125l1,1.438L150,171.98L148.188,170.668z"
          />
          <path
            id="강서구"
            data-code="11160"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M135.563,169.105l2,2.125l2,0.063l0.938-2.25l1.5-0.188v-1.25l-3.563-3.5
l-1.313-0.188l-4.5-3.063l-4.792,7.333l6.229,2.604L135.563,169.105z"
          />
          <path
            id="양천구"
            data-code="11150"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M138.313,174.543l1.188,0.063l0.688,1.063l1.125-0.313l0.938-1.063
l-0.313-2l1.063-1l-0.188-0.938l-1-1.5h-1.563l-1,2.25l-1.688,0.125l-2.188-2.188l-1.125,1.896l1.188,4.667l1.688-0.125
L138.313,174.543z"
          />
          <path
            id="구로구"
            data-code="11170"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M143.688,178.855l-0.188-2.125l-0.625-1.75l-1-0.75l-0.813,1.125
l-0.75-0.063l-0.938-0.875h-1.5l-0.938,0.938l-1.5,0.25l-1.688,1.833l1.375,3.792l2.938-1.313l2.5-1.563l0.75,1L143.688,178.855z"
          />
          <path
            id="금천구"
            data-code="11180"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M146.688,183.73l-0.75-3.688l-0.75-1.063l-1.875-0.438l-2,0.813
l3.271,7.417l2.917-1.729l-0.5-0.938L146.688,183.73z"
          />
          <path
            id="관악구"
            data-code="11210"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M148.063,177.73l3.563,0.188l1.188,2.438l2.25,0.563l0.75,1.813
l-3.438,4.313l-2.125-0.104l-3.75-3.146l-0.75-3.75l-0.313-0.813l1.375-0.5l0.813-1h0.437V177.73z"
          />
          <path
            id="동작구"
            data-code="11200"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M148.438,173.668l-1.25,2l-0.5,1.563l-1.625,1.063l-0.188,0.688
l0.563,0.25l1.438-0.25l0.813-1.063l3.813,0.313l1.313,2.125l2.25,0.563l0.313-3.625l-0.688-0.938l-1.188-0.5l0.125-0.813
l-1.875-1.375L148.438,173.668L148.438,173.668z"
          />
          <path
            id="영등포구"
            data-code="11190"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M142.188,168.355l-0.188-0.75l6.188,3.063L150,171.98l1.375,1.563
l-3,0.438l-1.438,2l-0.25,1.25l-2.188,1.625l-1.25-0.563l-0.063-1.625l-0.563-1.625l-0.75-0.813l-0.125-1.875l1.25-1.063
l-1.188-2.438L142.188,168.355z"
          />
        </g>
        <g id="인천광역시">
          <g id="강화군">
            <path
              id="강화군"
              data-code="23310"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M96.083,132.105l-10.167-7.167l-2.167,1.833h-2.833l-4.167,4.167l0.833,7.333l-2,0.667
l0.5,4.833l5.833,5.333l0.167,4.333l-4,2.833l2.333,4.5l10.333,1.333l5.333,0.833l5.5-3.5l-0.833-2.5l-2.833-3l1.333-3l-1.5-3.5
l0.833-3l-1.667-3.833l1.333-3.167L96.083,132.105z"
            />
            <path
              id="강화군"
              data-code="23310"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M89.25,169.939l-3.167,3l3.333,1.333l1.167-2.833L89.25,169.939z"
            />
            <path
              id="강화군"
              data-code="23310"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M72.917,136.939l-4.167,1l-1.167,5.333l-1.667,0.333l7,5.667l0.167,4.5l4.333-0.5
l2-4.667l-5.333-5.167L72.917,136.939z"
            />
            <path
              id="강화군"
              data-code="23310"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M74.417,129.939l-4.333,5.167l-3.833-0.5l-5,2.667l-2.5-4l1.333-2.833l0.333-2.333
l3.333-1.5l4.333,0.833l5,1.167L74.417,129.939z"
            />
          </g>
          <path
            id="계양구"
            data-code="23070"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M122.917,162.272l-1.5,2l-3.583-0.083l1.833,2l0.583,2l0.583,0.833
l-3,2.667l0.667,1.583l8.75-0.25l0.583-4.833l3-4l-0.583-0.5l-5.833-0.583L122.917,162.272"
          />
          <path
            id="부평구"
            data-code="23060"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M117.917,175.272l1.083,2l-1,3.25l1.75,2l1.833-1.917l4.833,2l1.583-0.75
l-2.583-2.25l-0.083-4.25l1.917-1.583v-0.75l-8.75,0.25L117.917,175.272z"
          />
          <path
            id="서구"
            data-code="23080"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M118.083,180.272l-2.667-0.333l-3-2.25v-1.5l-3-0.417l0.083-2.75l4.25-0.667
l-6.167-0.583l-0.417-4.583l-3.5-4.333l7.417-3.25l2.5-4.5l9.5,7l-1.75,2.333l-3.167-0.25l1.5,2l0.583,2l0.833,0.833l-3.417,2.583
l0.833,1.667l-0.583,2.25l1.083,1.75L118.083,180.272z"
          />
          <path
            id="연수구"
            data-code="23040"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M120.5,188.105l-3.583-0.917l-6.333,0.75l1.417,2.25l3.333,2.75
L120.5,188.105z"
          />
          <path
            id="남구"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M112.583,187.522L112,184.939l1.167-3.25l2.25-1.75l2.25,0.25l1.833,2.083
l0.417,3.583l0.583,2.25l-3.333-1L112.583,187.522z"
          />
          <path
            id="남동구"
            data-code="23050"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M115.333,192.939l5.167-4.833l-0.583-2.25l-0.167-3.333l1.833-1.917
l4.833,2l1.583-0.75l1.75,1.25l-6.25,11.917l-5.417-0.167L115.333,192.939z"
          />
          <path
            id="동구"
            data-code="23020"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M113.125,182.23l-2.375-1.375l-2.875-2.188l2-1.313l1.438-1.438l1.104,0.271
l0.271,1.479l2.5,2.25l-1.75,1.563L113.125,182.23z"
          />
          <g id="중구_1_">
            <path
              id="중구_1_"
              data-code="23010"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M95.917,170.939l-1.5,1.5l-1.167,3.833l-6.833,0.333l0.667,2.667l-2.833,2l4.5,6
l4.667-2.5l2.5-3.5l3.667-1l4.833-1.833l-2.667-4.333L95.917,170.939z"
            />
            <path
              id="중구_1_"
              data-code="23010"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M87.083,190.772l2.5,2.667l1.333-2.333l-2.167-0.833L87.083,190.772z"
            />
            <path
              id="중구_1_"
              data-code="23010"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M110.583,187.939l-1.833-1.146l-0.188-2.063l-1.438,1.188l-1.063-0.938l0.063-2.125
l1.375-1.938l0.188-2.25l1.188,0.625l2.188,2.063l2.063,0.875l-1.063,3.063l0.75,2.375L110.583,187.939z"
            />
          </g>
          <g id="옹진군">
            <path
              id="옹진군"
              data-code="23320"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M90.083,210.772l-3.5,2.167l0.167,5.833l2.5,1l2.833-2.333l1.167-1.5l2.667-0.5
l-1.5-2.833L90.083,210.772z"
            />
            <path
              id="옹진군"
              data-code="23320"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M45.25,212.606l-1.167,3.5l-0.833,4.667l3,0.667l2,1.5l2.333-0.667l2.167-2.5
l-3.333-3.167l-2.5-1.333l-0.333-2.333L45.25,212.606z"
            />
            <path
              id="옹진군"
              data-code="23320"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M73.583,214.439l-5.333-2l0.167,2.333l5.167,3.333l2.667-2.333L73.583,214.439z"
            />
            <path
              id="옹진군"
              data-code="23320"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M20.5,171.355l4-1.75l-2.25-2.5l-4.5-0.25l-3,1.25l-3.75-0.25l-0.75,1l2,2.75l1.25,2.5
l3.5,0.25l3.25,1l-0.25-2.5L20.5,171.355z"
            />
            <path
              id="옹진군"
              data-code="23320"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M21.25,186.105l-1.75-1l-2.5,2.25l-1.75,1l2.25,2.5l3-0.5l0.75-2.25V186.105z"
            />
            <path
              id="옹진군"
              data-code="23320"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M44.25,188.855l-3.25-0.25l-0.25,1.75l3,1.5v-1.5L44.25,188.855z"
            />
          </g>
        </g>
        <g id="경기도">
          <path
            id="김포시"
            data-code="31230"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M131.417,159.105l-16.167-11l2.833-5.5l-1.833-3.833l-0.667-6.333
l-3.167,0.333l-3.333,4.333l-6.333-0.5l-1.833-2.167l-3.667,0.167l1,2.833l0.333,2.333l-0.5,0.833l1,5.333v2.667l0.833,3.333
l-0.167,3l2.833,2.833v2.667l0.833,2.5l7.667-3.333l2.5-4.5l10.833,8l6.667,0.667l1.667-3.167L131.417,159.105z"
          />
          <path
            id="고양시"
            data-code="31100"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M117.083,145.772l2.5,0.333l4.167-1l6.167-0.5l2.333-4.333l2.333,0.833
l4.5-0.833l3.5,2l3-3.667l2.5,0.333l-0.833,2.5v5.667l-0.833,2.5l3.667-0.5l2-2.5l4,2.333l-0.833,5.333l-0.833,2l-2.333-2
l-1.667-2.833l-2.667,3l-2,0.667l-0.333,6.5l-2.5,0.667l-2,2l-3.5-0.167l-4.5-3l-1.5-2l-16.167-11L117.083,145.772z"
          />
          <path
            id="파주시"
            data-code="31200"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M116.583,124.439l3.167-1.667l7.333-0.667l-6-9.833l-5.5-0.333l0.167-6.5
l5.833,3.667l2.833-3l-0.167-1.833l2.5-3.5l1.667,2.333l1.167,5.667l-0.667,3l2.333,0.167l0.833,3.333l2.5-0.833l1.667-10.333
l2.167-3.167l5.167,2.5l0.5-5l3.5,2.167l3.833-0.167l2.667-2.833l4.5,2.833l-5.333,7.667l-2.667,3.333l-1,9.667l-0.833,2.667
l-2.333-0.333l-0.667,3.167l0.5,4.5l2.5,1.667l-0.167,6l-3-0.167l-3,3.667l-3.5-2l-4.5,0.833l-2.333-0.833l-2.333,4.333l-6.167,0.5
l-4.167,1l-3-0.333l1.5-3.167l-1.833-3.833l-0.667-6.333L116.583,124.439z"
          />
          <path
            id="양주시"
            data-code="31260"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M158.083,109.272l-4.333-1.333l-3.167,3.5l-1,9.667l-0.833,2.667
l-2.333-0.333l-0.667,3.167l0.5,4.5l2.5,1.667l-0.167,6l-1.333,2.667v5.667l-0.833,2.5l3.667-0.5l2-2.5l4,2.333l3.333-3.833
l-1.5-4.833l-1.833-3.333l3-2.333l3.833,0.833l4-1.5l3.667-1.667l1.167-6l-1.5-6l-7.833-1.667l-1.333-3.667l-3.833-1.833
L158.083,109.272z"
          />
          <path
            id="의정부시"
            data-code="31030"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M174.583,136.939l-2,2.833l-0.5,2l-4.167,5.167l-3-0.333l-1.667,1.333
l-3.833-2.833l-1.5-4.833l-1.833-3.333l3-2.333l3.833,0.833l7.667-3.167l2.667,2.167L174.583,136.939z"
          />
          <path
            id="동두천시"
            data-code="31080"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M163.917,101.939l-6.167,7.833l-0.5,3.333l3.667,2.333l1.5,3.167
l7.833,1.667l2.333-2.167h2.167l0.667-3.167l-1.833-3.333l-5.167-5v-3.833L163.917,101.939z"
          />
          <path
            id="연천군"
            data-code="31350"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M144.25,86.439l-1.667,5.833l-0.667,3.667l-5.333-2.667l-1.167,5
l-2.333,1.5l1,4.5l2.167-0.167l2.167-3.167l5.167,2.5l0.5-4.5l3.5,1.667l3.833-0.167l2.667-2.833l4.5,2.833l-4.833,7.5l3.833,1.167
l6.333-7.167l4.5,0.833l0.167-2.5h2.167l2.5,0.333l1.667-2l-0.833-6.333l-2.333-0.5l-0.167-2.333l3.667-2.833l4.167,1.333v-6.833
l0.5-12.167l-2.167-3.5l-2.167-5.333l-3.5,1.833l-1.833-2.333l0.167-6.833l-9.333,4.667l-6.333,4.833l-2.167,4.333l-2.167-1.5
l-4.167,2l-2.333-0.667l-3-4l-15.667,16l3.833,3.667l1.333,3.167l6.167,0.333l4.333-2.167L144.25,86.439z"
          />
          <path
            id="포천시"
            data-code="31270"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M174.583,136.939l4.5-0.167l3.667-1l2.5,0.667l5.167-3.333l2.667-14.167
h3.833l1.167-8.167l5.833-1.833l0.333-6.167l4.667-6.333l1.667-4.167l2.5-0.833l-1.667-10.167l-1.667-1.167l-1.167,1.667l-5.5-2
l-3.667,2.667l-1.5,1.5l-2-3.5l-4.167-1l1.5-8.5l-2.167-1.333l-7.333,6.333l-4.167-3.833v8l-0.333,7.333l-4-0.833l-3.667,2.833
l0.167,2.333l2.667,1.833l0.5,5l-1.667,2l-4.667-0.333l-0.167,6.333l4.667,4.333l2,3.167l-0.333,4h-2.167l-2.333,2.167l1.667,6.5
l-1.333,5.5l3.167,2.833L174.583,136.939z"
          />
          <path
            id="구리시"
            data-code="31120"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M178.417,165.605l1.5-3.5l-4-1.667l-1.167-6.667l-2.167,1.167l-3-0.333
l1.833,4.167l0.167,3.667l-2,2l1.667,2.833l3.5-2L178.417,165.605z"
          />
          <path
            id="남양주시"
            data-code="31130"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M193.75,133.939l5,3.667l2.167,4.833l1,4.167l2.167,2.5l-0.667,4.5
l-3.833,4.667l-0.333,6.833l-4.333,6.167l0.167,3.5l-2.667,0.167l-3.333-4l-3.333-3.5l-4-5l-1.833-0.333l-4-1.667l-1.167-6.667
l-2.167,1.167l-3-0.333l-1.167-1.833l-0.5-5.833l4.167-5.167l0.5-2l2-2.833l5.167-0.167l3-1l2.5,0.667l5-2.833L193.75,133.939z"
          />
          <path
            id="하남시"
            data-code="31180"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M174.417,180.439l3.167,0.667l2.5-1.5l6,0.333l1.5,1l1.667-2.333
l-0.5-2.667l3.667-1l-3.333-4l-3-2.833l-4.333-5.667l-1.833-0.333l-1.5,3.5l0.833,1.667l0.333,2.5l-3.833,2.833l-0.333,2l1.167,3
L174.417,180.439z"
          />
          <path
            id="양평군"
            data-code="31380"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M210.917,150.605l-3.833,2.333l-3.667,0.667l-3.833,4.667l-0.333,6.833
l-4.333,6.167l0.167,3.5l3.667-3.167l2.667,0.167l2.667,3.333l1.167,4.833l1,1.833l-1.5,1.833l-1.667,0.5l4,5.667l3.167-3.667
l5.833,2.833l4.5-2.5l4,3.833l5,0.167l3.167-1l4,1.5l4.167-0.5l4,1.333l0.167,3l0.667,2.333h6l5.5-10.667l-1.333-6.333
l-4.667-3.333l7.333-6.833l4.334-1.167l-6.334-5l-4-1.667h-8l-12.333-8.167l-6.167,3l-0.167,6.5l-4,1h-5.667l-1-6.167l-3.5-4.833
L210.917,150.605z"
          />
          <path
            id="가평군"
            data-code="31370"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M213.917,95.939l-0.833-4.5l-2.5,0.833l-1.667,4.167l-4.667,6.333
l-0.333,6.167l-5.833,1.833l-1.167,8.167h-3.833l-2.833,14.667l3.5,0.333l5,3.667l2.167,4.833l1,4.167l2,3.5l-0.5,3.5l3.667-0.667
l3.833-2.333l0.833,2.833l3.5,4.833l1,6.167h5.667l4-1l0.167-6.5l-2.833-4.333l2.333-1.167l0.5-9.667l-4.833,0.667l0.333-3.833
l2.833-3.333l-2.5-4.5l1.833-2.833l-0.833-4.667l6.667-6.333h2.833l1-5.667l-2.167-2.833l-0.333-3.667l-8-0.333l0.333-6.333
l-8.667-0.667L213.917,95.939z"
          />
          <path
            id="여주군"
            data-code="31280"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M212.25,199.605v-5.167l-5.167-4.667l3.167-3.667l5.833,2.833l4.5-2.5
l4,3.833l5,0.167l3.167-1l3.333,1.667l4.833-0.667l4,1.333l0.167,3l0.667,2.333h6l0.334,8.5l-1.5,10.667l-1.667,8l-5.833,9.5
l-7.333-3.667l-5.5-4.833l-2.5,2.5l-5.167-0.5l0.333-2.833l-0.167-6.167l1.5-2.167l-0.833-4.333l1.833-3.167l-1.833-2.667
l-2.833-3.5l-2-0.833l-3-2.333L212.25,199.605z"
          />
          <path
            id="광주시"
            data-code="31250"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M198.917,212.272l4.833-7l2.5-0.333l6-5.333v-5.167l-5.167-4.667l-4-5.667
l1.667-0.5l1.333-1.167l-0.833-2.5l-1.167-4.833l-2.667-3.333l-2.667-0.167l-3.667,3.167l-3.167,0.333l-3.167,0.833l0.5,2.667
l-1.667,2.333l-1.5-1l-6-0.333l-1,1.333l1,1.667l2.167,4.333l-1.667,3.5l-2,2.833l-0.667,3.5l-4.5,4v2.333l2.5-0.333l4-2.5
l1.167,1.833l2.833,0.667l0.5-3.667l5,1.833l3.167,0.833l-0.167,11L198.917,212.272z"
          />
          <path
            id="성남시"
            data-code="31020"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M162.583,188.439l-2,8l6.167,3.167l4.333,3.5h2.333v-2.333l4.5-4
l0.667-3.5l3.667-6.333l-3.5-6.5l-1.167,0.667l-3.167-0.667l-1.667,2l-3,0.167l-4.167,5.833l-2.5-1L162.583,188.439z"
          />
          <path
            id="과천시"
            data-code="31110"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M154.917,191.605l7.167-2.5l1-1.667l-1.667-4l-6.167-0.5l-2.833,3.833
v2.333L154.917,191.605z"
          />
          <path
            id="의왕시"
            data-code="31170"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M153.583,194.272l-1.333,5.333l-2.5,5.667l-1,2.167l3.5,0.5l1-3.167
l3.167-1.833l2.833-2.833l1.333-3.667l1.5-7.333l-6.667,2.5l-1.333,0.833L153.583,194.272z"
          />
          <path
            id="용인시"
            data-code="31190"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M161.417,201.939l-2.167-1.833l1.333-3.667l6.167,3.167l4.333,3.5
l4.833-0.333l4-2.5l1.167,1.833l2.833,0.667l0.5-3.667l8.167,2.667l-0.167,11l6.5-0.5l-0.167,6.833l1.333,3.5l4.333,1.667
l0.167,2.833l3.833,0.167l1.333,3.5l1.833,1.667l-1.667,3.833l-0.833,3.833l-3.833-1l-5-0.333l-2.833-2.333l-2.167,1.667l-4.333-4
l-1.667-2.167l-1.167,3.5l-4.667,3l-2.833,3.5l-5.167-1.333l-4.333-2.167l0.5-4.5l2.5-4.667l2.833-2l-1.5-5.667l-8.167-0.667
l-1.833-2.333l2.667-4.833l-2.5-1.667l2.333-2.667l-3.167-0.667l-0.667-2.167l-2.167-0.667L161.417,201.939z"
          />
          <path
            id="안양시"
            data-code="31040"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M149.25,195.939h-2.5l-2.333,2.667l-3-4.667l1-3.667l2.167-3.5l3.167-2
l2.5,2.167l2.167-0.167v2.333l2.5,2.5l-0.833,0.833l-1.667,5.667L149.25,195.939z"
          />
          <path
            id="부천시"
            data-code="31050"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M134.917,181.105l-0.5,1.833l-5.167,0.167l-3.833-3.833l-0.333-4
l2.167-1.5l0.667-5.333l6.333,2.5l1.5,4.833l-2,1.667L134.917,181.105z"
          />
          <path
            id="광명시"
            data-code="31060"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M136.583,183.605l-2.167-0.667l0.5-1.833l3-1.167l2.5-1.667l1.333,1.833
l2.833,6.667l-2.167,3.5l-0.667,1.667l-3.667-0.5l-0.833-6.833L136.583,183.605z"
          />
          <path
            id="시흥시"
            data-code="31150"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M121.583,197.439l-4,4.5l4.667,3.5l4.333-3.5l5-0.5l3.5-2.833l5.667-1.333
l1-2.167l-0.333-1.167l0.333-2l-3.667-0.5l-1-6.333l-0.5-1.5l-2.167-0.667l-5.167,0.833l-5.667,10.833L121.583,197.439z"
          />
          <path
            id="이천시"
            data-code="31210"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M221.25,245.605l1-5.167l-2.833-4.833l-4-0.833l-1.667-2.5l-2.167,0.167
l-1.833-1.667l-1.333-3.5l-3.833-0.167l-0.333-2.333l-4.167-2.167l-1.333-3.5l0.167-6.833l4.833-7l2.5-0.333l6-5.333l3.333-0.333
l2.333,2.167l2.667,1l4.667,6.167l-1.833,3.167l0.833,4.333l-1.5,2.167v6.833l-0.167,2.167l5.167,0.5l2.5-2.5l5.5,4.833l0.333,9.5
l-3.5,1.167l0.167,2.667l-2.167-0.833l-2.167,0.5l-0.833,4.833l-2.5-1.333L221.25,245.605z"
          />
          <path
            id="수원시"
            data-code="31010"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M149.375,209.355l-1.5,2.625l0.5,2.5c0,0,2.5,0.375,2.5,0.75
s3.25,4.625,3.25,4.625l6-0.25l2-1.875l3.292,0.875l2.667-4.833l-2.5-1.667l2.333-2.667l-3.167-0.667l-0.667-2.167l-2.167-0.667
l-0.5-4l-2.042-1.333l-2.625,2.125l-3.75,2.375l-0.75,2.833l-2.75-0.333L149.375,209.355z"
          />
          <path
            id="군포시"
            data-code="31160"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M148.75,207.439L145,205.73l-3.375-1.25l1.5-5.875l1.25-0.375l2.125-2
l2.75-0.292l3.167,2.167l-0.167,1.5L148.75,207.439z"
          />
          <path
            id="안산시"
            data-code="31090"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M122.25,205.439l5.625,3.042l5.875,0.375l1.5-0.125l2.75,3.875l5.625-2
l4.25,1.375l1.5-2.625l0.125-1.75l-7.5-3.125l1.125-5.875l1.25-0.375l-2.625-3.125l-1,2.167l-5.667,1.333L132,201.48l-5.417,0.458
L122.25,205.439z"
          />
          <path
            id="오산시"
            data-code="31140"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M163,234.98l5.5-0.5l1.375-1.75l-0.5-2.125l-2.5-0.375l-1.5-3.625
l-1.25-2.375h-4.875l-2.375,4.125l3.625,2.375l0.25,3L163,234.98z"
          />
          <path
            id="평택시"
            data-code="31070"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M146.625,269.48l10.125-5.125l4.875,1.625l6.5-2.875l3.25-4.25h2.75
L175,255.23l1-1.125l-0.25-2.125l0.25-1.5l-5.5-1.375l3.125-3.625l-2.375-1.375l0.75-5.125l-0.75-0.875v-4.25l-1.375-1.125
l-1.375,1.75l-5.125,0.375l-2.625-1.125l-3.75,1.875l-1.625,7.75l-6.125,1.375l-6.125-0.625l-5.5,7.875l-5.375,0.75l-1.75,1.875
l5.375,1.625l1.375,5.5l6.75,4l1.5,3.75H146.625L146.625,269.48z"
          />
          <path
            id="안성시"
            data-code="31220"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M193.375,270.48l-4.75-4.125l-4.25-0.375l-3.25-4.25l-7-2.875L175,255.23
l1-1.125l-0.5-2.625l0.5-1l-5.5-1.375l3.125-3.625l-2.375-1.75l0.75-4.75l3.417,1.625l5.167,1.333l2.542-3.583l4.958-2.917
l1.167-3.5l2,2.417l4,3.75l2.167-1.667l3.208,2.667h4.625l3.833,1l0.833-3.833l1.708-3.417l2.125-0.583l1.667,2.5l4.333,1.083
l2.5,4.583l-1,5.167l-7,1.375v3.75l-3.75,3l-4.75,0.75l-0.25,3.875l1.5,1.875l-3.75,3.375h-5.875l-2.625,4.875L193.375,270.48z"
          />
          <g id="화성시">
            <path
              id="화성시"
              data-code="31240"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M135.375,211.98l-4.125,0.125l-1.125,3.125l-0.875,2.5l-3-2.75l-1.625,0.375L121,214.98
l-1.75-0.875l-2.375,0.75l-2.25,2.875l1.75,2.75l-2,4.875l-0.375,3l0.125,2.625l4-0.375l-1.375,3.375l1.875,3.125l4-3.125
l3.125-4.125h4.5l1.75-2.375l-0.125,4.125l1.75,1L132,234.98l-5.25,0.5l0.25,2.875v2.625l-0.125,4.125l-2.625,2.5v2.25
l6.875,1.125l1.125,1.75l5.375-0.75l5.5-7.875l6.125,0.625l6.5-1.25l1.25-7.875l3.75-1.875l-0.25-3l-3.625-2.375l2.375-4.125
h4.875l1.25,2.375l1.5,3.625h2.625l0.375,2.5l1.708,1.208l2.5-4.667l2.833-2l-1.5-5.667l-8.167-0.667l-1.833-2.333l-3.292-0.5
l-2,1.5l-6,0.25l-3.25-4.625l-2.5-0.75l-0.5-2.5l-4.25-1.375l-5.625,2L135.375,211.98z"
            />
            <path
              id="화성시"
              data-code="31240"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M109.625,216.73L108,214.355l-3.125-0.5l-1.625-2.75l-4.5-0.625l2.25,4l1,3l-2.5,1.375
l0.125,5.375l3,0.125l2.625-4l3.75,2.75l-0.125-3.875l1.25-1.125L109.625,216.73z"
            />
          </g>
        </g>
        <g id="강원도">
          <path
            id="철원군"
            data-code="32360"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M231.417,45.272h-3.833l-7,5.167l-3.833-0.333l-1.667-2.167h-3
l-3.167-2.333l-6.5-0.667l-1.667,2l-4.167-0.333l-2,2.333l-1.333-3h-4.333l-1.5-0.833l-6.167,3.333l-2.667-0.833l-1.667-1.833
l-2.833,0.333l-1.667,4.667l-2.167,2.5v6.333l1.833,2.333l3.5-1.833l2.167,5.333l2.167,3.5l-0.333,3.167l4.167,3.833l7.333-6.333
l2.167,1.333l-1.5,8.5l4.167,1l2,3.5l1.5-1.5l3.667-2.667l5.5,2l1.167-1.667l8.167-1.833l1.333-3.5l-1.833-2.167l1.833-6.333
c0,0,4.333-0.667,4.333-1.167s0.167-3.333,0.167-3.333l3.833-1.5l3.667-6l-2.667-2.667l3.167-5.167L231.417,45.272z"
          />
          <path
            id="화천군"
            data-code="32370"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M227.417,85.772l-0.333,3.667l2,2.667l-2.333,2.833l-3.333,2l-0.167,1.167
l-8.667-0.667l-0.667-1.5l-0.833-4.5l-1.5-9.667l-1.833-1.667l8.167-1.833l1.333-3.5l-1.833-2.167l1.833-6.333l4.333-1.167
l0.167-3.333l3.833-1.5l3.667-6l-2.667-2.667l3.167-5.167l-0.333-1.167l2.167,0.167l8.5,0.5l1.667-2.333l3.667,1l4-2.667
l1.833,0.833l3,3.5l2.333,1.167l4.167,12.833l-0.5,5l3.5,1.333l0.333,13.833l2.834,2.833l0.666,5.167l-2.5,2.167h-4l-0.833,2.833
l1.167,0.667l-7.667,4l-3.667-4.167l-1.833-1.833l-2.667,0.333l-1.333,2l-2.833-2.667l-2.833,0.333l-2.167,1.167l-0.833-3.833
l-2.833,0.833l-2.667-2.5l-2-2.167L227.417,85.772z"
          />
          <path
            id="춘천시"
            data-code="32010"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M233.417,148.939l2-3.667l1.833,2.833l2.333-2.5h2.5l-0.5-3l6.167-2.833
l13.667-0.833l1.833-4.167l-2.667-4.667l-1-3.667l-0.166-1.667l0.666-1.333l5.167,1.167l2-4.167l2.167,0.167l4.5,4.833l2-6.167
l1.166-1.667l4.167-9.667l-2.667-2.667l0.834-5.333l2.666-1l1.834-2.833l-1-1.333l-3.5,2l-0.334-3l-4.333-1.667l-0.833,3.833l-4-2
l-0.334-5.5l-2.5,2.167h-4l-0.833,2.833l0.667,1.333l-7.167,3.333l-3.667-4.167l-2.333-1.667l-2.167,0.167l-1.333,2l-2.833-2.667
l-2.833,0.333l-2.167,1.167l-0.833-3.833l-2.833,0.833l-4.667-4.667l-2.667,0.167l-0.333,3.667l2,2.667l-2.5,3.333l-3.167,1.5
l-0.5,7.5l8,0.333l0.333,3.667l2.167,2.833l-1,5.667h-2.833l-6.667,6.333l0.833,4.667l-1.833,2.833l2.5,4.5l-2.833,3.333
l-0.333,3.833l4.833-0.667l4,1.833l2.333,2.833L233.417,148.939z"
          />
          <path
            id="홍천군"
            data-code="32310"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M353.417,120.105l-4.834-5.833l-4.666,0.667l-3.167-0.5l-5.5,3.5
l-2.5-2.833l-2.333,3.333l-3.667,3.5l-1.5,2.5l-4.833-1.667l-2.167-4.333l-4,1l-1.833,3.667l-5.5,3.5l-5-6.167l-0.167-5.5
l-10-0.833l-6.833-6.5l-3.667,0.333l-3.833,9.833l-1.5,1.5l-2.334,6.167l-4.166-4.833l-2.167-0.167l-2,4.167l-5.167-1.167
l-1.166,1.5l1,3.667l3.333,6.167l-1.833,4.167l-13.667,0.833l-6.167,2.833l0.5,3h-2.5l-2.333,2.5l-2-2.333l-1.833,3.167l-1-2.5
l-2.333-2.833l-4-1.833l-0.5,9.667l-2.333,1.167l2.833,4.333l6.167-3l12.333,8.167l8.5,0.167l3.5,1.5l6.334,5l3.833-1.833
l1.833-5.5l3.834-0.833l3.666-1.5l2.167-4.667l4.167,1.833l2.333,2.333l7.167-6.5l3-0.333l1.166-3.333l2.834,0.167l2.5,2.5l7,1.833
l1,3.5l4-0.167l2-5l5.666-6.833l14.334,0.833l1.833-4.667l3-1.833l6.333-0.333l-0.333-3.5l1.833-4.5l1.167-2.667l4.667-1.833
l-1.167-3.333L353.417,120.105z"
          />
          <path
            id="횡성군"
            data-code="32320"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M288,202.855l-0.25-5.75l4-1l-0.5-4l2.75-2l-5-5.5l-6-1.5l-4-1.5l-1.5,2
l-4-3.75l-0.25-3.75l-2.5,1l-0.75,2.75l-4.25,3l0.25,5.5v3.5l-3,1.5l-1.25-5.25l-4.5-1.667l-1.333-6.333l-4.667-3.333l7.333-6.833
l4.334-1.167l3.833-1.833l1.833-5.5l7.5-2.333l2.167-4.667l4.167,1.833l2.333,2.333l7.167-6.5l3-0.333l1.166-3.333l2.834,0.167
l2.5,2.5l7,1.833l1,3.5l4-0.167l2.833,3.333l3,14.25l-7,7.25v4.25l2.5,4.75l-2,2.75l-7.75,0.25l-1.25,3l2,4.5l-2.75,2.75
l-6.75-0.25l-0.5,3.75l-6.25-2L288,202.855z"
          />
          <path
            id="원주시"
            data-code="32020"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M307,216.105l-4.25,4.25l-4.75-3l-3.25,5.25l-8.75,1.75v-2.75l-2.5-5.5
l-5.25-1l-5.25,3.75l0.75,6.25l-0.25,3l-3.5,2.5l-5-1l-7.75,2.75l-8.333-8.083l1.583-6.667l1.584-12l-0.334-8.5l5.5-10.667
l4.5,1.667l1.25,5.25l3-1.5l-0.25-9l4.25-3l0.75-2.75l2.5-1l0.25,3.75l4,3.75l1.5-2l10,3l5,5.5l-2.75,2l0.5,4l-4,1l0.25,5.75l1.5,2
l6.25,2l2.25,4.75l5.75-0.25l3-3.5l2.5,2.5l-1,2.75l0.25,2.25L307,216.105z"
          />
          <path
            id="양구군"
            data-code="32380"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M294.75,44.605l-7,4l-6.25-1.5l-5-1l-3.25-1.5l-8-1l-3.25,2.25
l-3.417,1.583l4.167,12.833l-1.25,4.833l4.25,1.5l0.333,13.833l2.834,2.833l0.583,5.833l0.417,4.833l4,2l0.833-3.833l4.333,1.667
l0.334,3l3.5-2l5.333,0.083l1.25-4.5l4.5,2.5l1-6.5l-4-4.25l2.25-3l-3-5l1.25-1.75l3.5-8l0.5-3.5l4-5.25l0.25-9.5L294.75,44.605z"
          />
          <path
            id="인제군"
            data-code="32390"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M306.75,37.105l-12,7.5l5,1.5l-0.25,9.5l-4,5.25l-0.5,3.5l-4.75,9.75l3,5
l-2.25,3l4,4.25l-1,6.5l-4.5-2.5l-1.25,4.5l-5.333-0.083l1,1.333l-1.834,2.833l-2.666,1L279,105.855l2.25,2.083l3.667-0.333
l6.833,6.5l9.5,1.167l0.667,5.167l5,6.167l5.5-3.5l1.833-3.667l4-1l2.167,4.333l4.833,1.667l1.5-2.5l3.667-3.5l2.333-3.333
l2.5,2.833l5.5-3.5l-1-5.667l0.333-6.333l2.834-2.667l0.333-7.167l-2.5-2.833l-7.833-1.167l-1.167-6.5l0.833-2.833l6-2.667
l-6.666-8.333l2.5-2.5v-5.5l-2.167-2.5l-3.833,1.833l-3.667-1l-1-3.833l-4.167-0.333c0,0-0.666-4.333-0.666-4.833
s-1.167-4.333-1.167-4.333l-2.167,0.333l-2.833,1.167l-1.167-4.333l1-1.833l-3.333-1L306.75,37.105z"
          />
          <path
            id="고성군"
            data-code="32400"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M340.083,66.939l13.334-7l-8.334-17.5l-1.333-4.333l-7.167-10l1.334-1.833
l-8-14.167V8.939l-6.5-8.333l-5.334,2.167L317.75,16.94l-1.167,7.833l-10.166,12.833l2.833,2l2.5,0.833l0.833,0.167l-1,1.833
l0.667,4.333l5.5-1.5l1,3.667l0.833,5.5l4.167,0.333l1,3.833l3.667,1l3.833-1.833l2.167,2.5v5.5L340.083,66.939z"
          />
          <path
            id="속초시"
            data-code="32060"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M356.417,70.939l-0.167-3.667l-1.833-2.667l1-1l-2-3.667l-13.334,7
l-5.666-1.167l-2.5,2.5l6.666,7.833l3.667-1.5l4.667-0.333l4.5-2.5L356.417,70.939z"
          />
          <path
            id="양양군"
            data-code="32410"
            opacity="0.98"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            enableBackground="new    "
            d="M353.417,120.105l7.333-7.333
l3.667,1.5l8.5-2.667l2.5-1.167l6.833-0.333l-5.5-8l-1.333-3l-3.334-3.5l-0.333-3.667l-2.167-0.333l-5.166-5.5l-0.334-2.833
l-7.166-8.5l-0.5-3.833l-5,0.833l-4.834,2.667l-4.333,0.167l-3.667,2l-6,2.667l-1.166,3.333l1.5,6l7.333,1.333l3,2.667
l-0.333,7.167l-3,3.167l-0.167,5.833l1,5.667l3.833,0.833l4-1L353.417,120.105z"
          />
          <path
            id="평창군"
            data-code="32340"
            opacity="0.98"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            enableBackground="new    "
            d="M355.083,210.772l1.167-1.833
l-4.333-10.167l-1.834-4.167l-1.166-3.167l-4.167-2.167l1.833-7.5l5.5-0.333l-0.333-5.667l2,1.167c0,0,0.833-6.833,0.833-7.5
s3-6,3-6l3.667,4.667l3.5-2.333l2.5-4.833l3.333-0.5l1.167-2.5h1.5l-0.5-3.167l-0.5-3.833l4.167-3.667l-1.334-4.667l-1.5-5.333
l-5-4.667h-5.166l-2.167-1l-6,1.5l0.5-5.667l-1.833-1.5l-4.667,1.833l-3,7.167l0.333,3.5l-6.333,0.333l-3,1.833l-1.833,4.667
l-14.334-0.833l-5.666,6.833l-2,5l2.833,3.333l3,14.25l-7,7.25v4.25l2.5,4.75l3.167,0.833l3.5,4.333l1.166,6.167l0.334,5
l3.666-0.833l3.334,3.5l3.166-1.833l0.834-6.667l2.833-0.5l3.667,3.833l0.666,2.167h3.5l2,3.333l4.834-1L355.083,210.772z"
          />
          <path
            id="강릉시"
            data-code="32030"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M380.25,168.105h-8.167l-2.666,1.667l-4.667-4l2.5-4.833l3.333-0.5
l0.667-2.5h2l-1-7l4.167-3.667l-2.834-10l-5-4.667h-5.166l-2.167-1l-6,1.5l0.5-5.667l-2.167-1.667l-0.833-3.167l0.667-2.5
l7.333-7.333l3.333,1.5l8.834-2.667l2.5-1.167l6.833-0.333l2.167,1l0.5,4.333l5.333,6.167l9.167,9.833l-0.334,3h1.667
c0,0,12.667,11.666,12.5,12.333s-1.5,5.333-1.5,5.333l2.167,4.167l-2,10l-9.167,2.5l-1.333-2l-2.667,2.667l-4.5-4.333l-0.833-3.667
l-2.334-0.667l-2.166,4.167l-0.834,5l-1.666,2.833l-3.167,0.5l-1.5-1.833L380.25,168.105z"
          />
          <path
            id="정선군"
            data-code="32350"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M401.25,182.105l4-6l-3.5-4l1-3.333l-1.333-2l-2.667,2.667l-4.5-4.333
l-0.833-3.667l-2-0.5l-2.5,4l-0.834,4.333l-1.666,3.5l-3.167,0.5l-1.5-1.833l-1.5-3.333h-8.167l-2.666,1.667l-4.167-3.833l-4,2.167
l-3.667-4.667l-3,6l-0.833,7.5l-2-1.167l0.333,5.667l-5.166,0.5l-2.167,7.333l4.167,2.167l7.333,17.5l-1.167,1.833l-2.666,3.167
l2,3.667l1.166,2l3.667,0.333l3.167,4.167l7.666,1.167l6.834-4.5l6.666,3.5l4.667,2.5l5.667,0.5l-0.167,2l4-0.667l-1.167-8.333
l-3-8l-0.666-3l-3.167-4.167l0.833-3.667l-2-2.5l0.334-4.833l3.333-5l2.333-1.833l0.5-3L401.25,182.105z"
          />
          <path
            id="동해시"
            data-code="32040"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M407.917,185.605l6.166-5.167l9.667-0.167l3.333-2.333l-2.666-4.667
l-4.5-6.167l2.166-2.5l-1.5-3.333l-6.666-5l-2.334,10l-8.833,2.5l-1,3.333l3.5,3.333l-4,6.667l2.667,3L407.917,185.605z"
          />
          <path
            id="영월군"
            data-code="32330"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M390.917,235.105l2.833,2.333l-2.333,3.667l0.333,2.833l-4-1.167l-6.833-4
l-2.5,3.167l-2,2.333l1,3.333l-4.834-1.333l-7.333-3.333l-5-4l-6.167,0.833l-8-4.833l-5,1l-6.166,0.167l-4.167-6.167h-5.667
l-6.166,2.5l-2.667-4.5l7.667-6.333l-0.334-2.333l-1.833-0.5l-6.167,2.333l-2.833-3.167l-4.25-2.583l-0.25-2.25l1-2.75l-2.5-2.5
l-3,3.5l-5.75,0.25l-2.25-4.75l0.5-3.75l6.75,0.25l2.75-2.75l-2-4.5l1.25-3l7.75-0.25l2-2.75l3.167,0.833l3.166,4.333l1.5,5.167
l0.334,6l3.666-0.833l3,3.5l3.5-1.833l0.834-6.667l2.833-0.5l3.667,3.833l0.666,2.167h3.5l2,3.333l4.834-1l3.666,2.333
l-2.666,3.167l3.166,5.667l3.667,0.333l3.167,4.167l7.666,1.167l6.834-4.5l11.333,6l5.667,0.5l-0.167,2l-1,4.5L390.917,235.105z"
          />
          <path
            id="태백시"
            data-code="32050"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M397.417,213.939l1.166-1.167l0.167-3.833l4.167-8.833l5.166-1.5l-1,2.5
l2.167,3.667l-1.167,3.333l1.334,2l-1.334,3.333l-2,1l2.5,2l-0.666,3.667l2.5,4.5l6.333,1.833l2.667,3.833l-0.167,5.667
l-2.833,2.333l-0.667,2.333l-3.833-0.667l-5.667-1.5l-3,1l-4.333-2.5l-2.334,6.333l-4.833,0.667l-0.333-2.833l2.333-3.667
l-3.167-2.333l2.167-1.333l1-4.5l4-0.667l-1.167-8.333L397.417,213.939z"
          />
          <path
            id="삼척시"
            data-code="32070"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M431.917,244.272l5.333-0.333l-0.333-5.333l5.833-5.667l3.667-0.333
l3.666-4.167h3.5l-3.333-8.667l0.333-1.667l-0.833-2l2.833-1.667l-4.666-7.5l-4.167-4.167l-3.167-1.333l-2.5-7.333l1.167-2.167
l-8.167-5.5l-0.5-5.5l-3.5-3l-3.833,2.333l-9.667,0.333l-5.666,5l-4-0.5l-2.667-3l-5.667,2.167l-1.333,3.667l-2,1.167l-3.333,5
l-0.334,4.833l2,2.5l-0.833,3.667l2.833,3.833l4,11.333l0.834-6.333l1.5-1.5l-0.167-3.5l4.167-8.833l5.166-1.5l-0.666,2.167
l1.833,4l-1.167,3.333l1,2.333l-1,3l-2,1l2.5,2l-0.666,3.667l2.5,4.5l6.333,1.833l2.167,4.167l0.333,5.333l3.667,0.833
L431.917,244.272z"
          />
        </g>
        <g id="충청북도">
          <path
            id="진천군"
            data-code="33350"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M196.75,276.272l2.833,0.833l0.167,4.667l5.167,2.333l3.333,2.667
l-0.333,2.833l2.167,1.833c0,0-0.667,2.5-0.167,2.333s4.667-1.167,4.667-1.167l5.167-2.667l4,2l4.333-3.5l-2.667-4.667l2.5-0.833
l0.333-6.833l1.333-1.667l-7.5-7.167l-3.167-3.167l-2.5,1l-2.333-2.667l-0.167-3.667l0.333-8.042l-3.75,3l-4.75,0.75l-0.25,3.875
l1.5,1.875l-3.25,3.208l-6.375,0.167l-4,6.875l2.542,1.958L196.75,276.272z"
          />
          <path
            id="음성군"
            data-code="33370"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M244.25,273.605l-1.75,4.25l-4.375-1.5l-3.75,3.875l-1.125-2.5l-4.875-2
l1.208-1.292l-10.667-10.333l-2.5,1l-2.333-2.667l0.167-11.708v-3.75l7-1.375l4.125,0.875l2.125,1l0.917-4.375l2.167-0.5
l2.167,0.833l-0.167-2.667L236,239.23l-0.25-9.125l7.375,3.375l2.25,0.625l2.375,1.375l-3.25,4l-1.25,7.25l-2.125,2.5l-0.75,4.375
l-1.25,1.5l0.5,2.625l2.25-1.5l2.5,1.625l4,3.25l2.875-0.25l3.125,4.125l2.125,2.75l-0.75,5.125l-4.125,1.25l-2-0.375
L244.25,273.605z"
          />
          <path
            id="충주시"
            data-code="33020"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M258.375,262.855l3.625,1.25l1.375,3.75l5.5,3.625l5,1l1.25-0.625l2,1.875
l1.625,3.125l1,4.25l3.25,2.375l2.375-1.75l4.625,0.875l2.625,0.375l1.375,1.625l3.75-3.25l-2.875-2l-2.375-2l-2.25,2.25l-2.5-3.5
l2.75-5.625l0.125-4.5l-2.375-2.75l2.375-3.125l3.75,1l-0.75-5.125L292,253.23l2.25-3l-1.875-4.25l0.5-4.25l-4.625-3.5l-1.25-2.25
l-3.875,0.125l-0.625,2l-4-5.375l-1.125-2.125l-3.875-2.5l-3.5,2.5l-5-1l-7.75,2.75l-8.333-8.083l-5.542,8.833l2,1l1.75,1.25
l-2.625,4.125l-1.25,7.25l-2.5,2.375l-0.375,4.5l-1.25,1.5l0.5,2.625l2.25-1.5l3.375,2.25l3.125,2.625l2.875-0.25l3.375,4.5
l2.125-1.375L258.375,262.855z"
          />
          <path
            id="제천시"
            data-code="33030"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M310.5,244.355l5.375-4.25l-1.5-3.125l2.25-1.875l3.125,2.375l1.25-2
l-2.083-3.042l-2.667-4.5l7.667-6.333l-0.334-2.333l-2.208-0.417l-5.792,2.25l-2.833-3.167l-4.25-2.583l-1.5,0.75l-4.25,4.25
l-4.75-3l-3.25,5.25l-8.75,1.75v-2.75l-2.5-5.5l-5.25-1l-5.25,3.75l0.625,5.75l-0.125,3.125l3.875,2.875l1.125,2.125l3.875,5
l0.75-1.625l4.125,0.25l1,1.875l4.375,3.875l-0.25,3.875l1.875,4.25l-2.25,3l1.5,3.125l0.875,4.75l-3.75-1l-2.375,3.125l2.375,2.75
l-0.125,4.5l-2.75,5.625l2.5,3.5l2.25-2.25l5.25,4l2.5-2l6.375,3l3.125-0.375v-5.375l3.875-3.875l-1.5-7.25l2.125-3.625l1.375-1.25
l-1.25-5.25l-2-5.375L310,249.355L310.5,244.355z"
          />
          <path
            id="단양군"
            data-code="33380"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M316.5,273.855l6.5,7.625l3.75,1.375l2.25,2.125l3.5-3.375l3.875,0.25
l3.75-5.75l-1-2.75l-1.625-3.375l2-5.625l9.625-9.25l2.625-0.875l2.25-3.75l2.25-4.25l6.25-0.125l2.75-3.167l-5-4l-6.167,0.833
l-8-4.833l-3.958,0.792l-7.208,0.375l-4.167-6.167h-5.667l-6.166,2.5L321,235.48l-1.625,1.625l-3.125-1.75l-1.875,1.625l1.5,3.125
l-5.375,4.25l-0.5,5l2.375,0.625l2.125,4.75l1.125,5.875l-1.375,1.25l-2.125,3.625l1.25,7.625L316.5,273.855z"
          />
          <path
            id="괴산군"
            data-code="33360"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M257.75,316.355l-3.25-0.625l-7.875-8.375l2.125-3.75l-6.625-5.75
l-4.125-2.25l-2.25-7.25l1.625-2.75l-1.25-3.125l-1.75-2.75l3.75-3.375l4,1.375l2.125-4.125l5.375,0.125l1.375,0.5l4.75-1.375
l0.75-5.125l-1.5-2.5l3.375-2.375l3.625,1.25l1.375,3.75l5.5,3.625l5,1l1.25-0.625l2,1.875l1.625,3.125l1,4.25l3.25,2.375
l2.375-1.75l5.125,1.25l-1.5,5.5l-2.375,4.25l6.125,6.125l-3.5,1l-4.75-3.375l-2,1.75l-4.125-2.625l-2.5,5l-3.25,2.375l-2.875,0.5
l3.125,2.75l2.875,2.875l-1.875,7l-4.75-5l-1.75-2.25l-3,3.375l0.875,2.25L257.75,316.355z"
          />
          <path
            id="증평군"
            data-code="33390"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M240.125,299.605l2-1.75l-4.125-2.25l-2.25-7.25l1.625-2.75l-1.25-3.125
l-1.75-2.75l-1.125-2l-5-1.625l-0.25,6.5l-2.583,1.167l2.333,4.583l0.5,2.125l2.25,0.625l1.875,3.5l1.25,1.875l2.375,4.25h3.875
L240.125,299.605z"
          />
          <path
            id="청주시"
            data-code="33040"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M209.625,293.355l-2.625,1l-2.5-1.5l-3.25-0.25l-2.5,1.25l0.25,2.625
l-2.75,1.5l-0.625,3l-2.25,1.25l0.5,2.125h1.625l-0.5,3.875l-2.75,1.875l1.375,2.625l1.875,2.75v4.5l3.375,0.875l5.056,1.809
l1.736,2.055l-0.571,3.22l-0.346,1.948L204,333.98l1.375-1.375l2.375,1.875l0.125,4.75l4.625,0.875l1.5,1l2.375-3.5l1.5-1.75l1,3.5
l1.25,1.125l-0.875,3.125l2.125,4l1.25-2.5l0.5-3l3.375-1.125l3.375-7.25l-1.625-5.25l6.75-5l0.125-3l3.875-4.625l5.5,2.75
l2.75-1.25l2.125,0.25l2,2.375l3.125-4.25l-7.875-8.375l2.125-3.75L242,298.23l-1.875,1.375l-0.25,1.125H236l-2.375-4.25
l-3.125-5.375l-2.25-0.625l-0.5-1.25l-4,2.708l-4.5-2.208l-4.667,2.875L209.625,293.355z"
          />
          <path
            id="보은군"
            data-code="33320"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M225.917,345.938l-3.292-0.833l0.792-2.5l3.083-1.625l3.25-7.708
l-1.5-4.792l6.75-5l0.125-3l3.875-4.625l5.5,2.75l2.75-1.25l2.125,0.25l2.042,1.833l3.166-3.333l2.334,0.167l1.166,2.667l4.5,2.5
h3.5l1,3.667l4.334,4.5l-0.334,2.833l-3.333,2l-0.167,9.167l0.667,2l-2.167,2.833l2.834,3.834l-5.5,0.333l-10-2.5l-3.167,0.833
l-5.5-4.333l-1.5-2.167l-1.5-2.5l-4.5,0.5l-2,2.5l-0.667,2.5l-2.833-0.5l-1-0.666l-2.667,2.166l-1.667-0.833L225.917,345.938z"
          />
          <path
            id="옥천군"
            data-code="33330"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M233.25,382.438l6.333,1.334l1.5-8.167l3.333-1.667l4.5-4.5l4.833-0.166
l0.833-3.667l5.834-3.667l2.833,0.334l6.167-7l-0.5-3l-5.5,0.333l-10-2.5l-3.167,0.833l-5.5-4.333l-3-4.667l-4.5,0.5l-2,2.834
l-0.667,2.166l-2.833-0.5l-1-0.666l-2.667,2.166l-1.833,1.167l-2.5,0.833l-0.5,5.5l-2.833,2.334l-0.833,4.666l0.833,3.5
l-1.167,3.167l0.167,4.333l3.5-0.833l1.667-0.667l2.167,3.334l4.167-0.334l1.667,1.834L233.25,382.438z"
          />
          <path
            id="영동군"
            data-code="33340"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M244.25,400.605l-3.167,2.667l-3.167-4.167l-2.5-5.833l-3.5-3.334l1.5-3.5
l-0.167-4l6.333,1.334l1.5-8.167l3.333-1.667l4.5-4.5l4.333-0.333l2-3.667l5.167-3.5l2.833,0.334l2.167,5.166l3.333,0.5
c0,0,2.333-4.667,2.833-4s3.334,4.167,3.334,4.167l5.333,2.833l2.667-2.5l4.333,0.167l2.167,2l-2.334,2.833l4.834,6.834
l-3.167,0.333l-4.5-1.333l-3,0.5l1,4.166l1.333,3l-3,4.334l-0.833,3.5l-0.667,5.5l-6.5,2.166l-4.5,5l-3.833-1.833l-2.667,1
l-7.5,2.333l-1.166-2.333l-5.167-1.333l-2-2.5L244.25,400.605z"
          />
        </g>
        <g id="대전광역시">
          <path
            id="유성구"
            data-code="25040"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M209.875,345.855l-2.375-5.5l0.375-1.125l-0.125-4.75l-2.375-1.875
L204,333.98l-2.375-0.25l0.5,4.25l-0.5,3.375l-3.375,3.625l-4.25,0.375l-2.75,3.25v9.375l-1.625,3l-0.5,4.25l-1.625,1.625
l6.25,3.125l5.5-6.875l1.875-2.5l2-7.25l4.125-0.375l1.5-1.375L209.875,345.855z"
          />
          <path
            id="서구_3_"
            data-code="25030"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M208.375,357.23l-1.5-4.375l-3.75,0.5l-2,7.25l-7.375,9.375l-0.875,3.25
l3.5,3.875l3.625,4l3-4.5l-0.125-5.375l1.75-2.875l-0.25-2.5l2.125-2.875l1.125-4.25L208.375,357.23z"
          />
          <path
            id="중구_5_"
            data-code="25020"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M206,368.73l0.375,5.875l3.875,3.875l1.5-2.625l1.5-2.875l-1.25-2.25
l0.5-2.5l-0.875-2.375l3.125-1.625l-3-2.125l-2-2.25l0.125-2.125l-1.5-0.5l-0.875,1.875l-1,3.875l-2.125,2.875l0.375,2L206,368.73z
"
          />
          <path
            id="대덕구"
            data-code="25050"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M216,355.105l-1.5-4.5l4.75-7l0.875-3.125l-1.25-1.125l-1-3.5l-1.5,1.75
l-2.25,3.125l-1.625-0.625l-4.625-0.875l-0.375,1.125l2.375,5.5l-1.125,5.75l-1.875,1.25l1.5,4.375l1.5,0.5l2.375-2.125l1.625,0.75
L216,355.105z"
          />
          <path
            id="동구_4_"
            data-code="25010"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M213.25,380.73l-3-2.25l3-5.5l-1.25-2.25l0.5-2.5l-0.875-2.375
l3.125-1.625l-3-2.125l-2-2.25l0.125-2.125l2.375-2.125l1.625,0.75l2.125-1.25l-1.5-4.5l4.75-6.625l2.125,3.625l1.375-2.125
l3.167,0.458l0.5,1.667l1.667,0.833l-2.208,1.667l-2.125,0.333l-0.375,5.167l-2.958,2.667l-0.792,4.333l0.792,3.833l-1.167,3.167
l0.167,4.333L213.25,380.73z"
          />
        </g>
        <g id="충청남도">
          <path
            id="천안시"
            data-code="34010"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M159,307.48l4.875-6.5l2.875-0.875c0,0,1.125-4.375,1.125-4.75
s1-2.75,1-2.75l2.625-0.875l0.25-4l-2-1.875l-0.875,1.375l-0.5-6.125L171,278.48l-0.375-2.625l-1.5-0.25l-1.375-10.875l0.375-2
l3.25-3.875h2.75l6.625,3.125l3.625,4l3.625,0.625l7.5,5.625l1.25,3.75l2.833,1.125l0.042,4.375l6.125,3.125l2.5,2.167
l-0.125,2.208l1.75,2.75l-0.375,1.25l-2.125,0.875l-2.875-1l-3.25-0.25l-2.5,1.25l0.25,2.625l-3.125,1.75l-0.75,3.125l-1.75,0.875
L189,302.355l-3.75-2.375l-5.125-2.875l-4.625,0.25l-1.75,3.25l2.625,2.5L177,308.48l-1,4l-4.125-3.875l-2.25,0.625L168,310.855
l-2.875-0.25l-1-1.5l-5-0.25L159,307.48z"
          />
          <path
            id="계룡시"
            data-code="34070"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M190.875,374.73l-3.625-0.125l-1.125-2.75l-2.875,0.125l-1.75-4.875
l1.5-2.5l0.125-2.75l-1.5-2.75L184,355.98l3.375,0.875l2.375,3.75l-0.625,4.625l-1.625,1.625l6.25,3.125l-0.875,3.25
L190.875,374.73z"
          />
          <path
            id="공주시"
            data-code="34020"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M170.75,358.105l-2.25,0.25l-3.5,8.75l-9.5-3.625v-11.625l4.375-3.625
l-0.125-5.25l-4.25-4.375h-6.125l-1.5,1.375l-5-6l0.25-8.875l6-8.375l3.125-9.875l3,3l3.875-1l5,0.25l1,1.5l2.875,0.25l1.625-1.625
l2.25-0.625l3.75,3.875l4.375,5.75l3.75,3.125l0.125,5.375L183,332.052l1.188,4.928l3.437,4.001l4.5,2.874l1.875,1.5l-2.75,3.25
v9.375l-1.375,2.25l-2.5-3.375L184,355.98l-2,3l-6,4l-3.5-2.125L170.75,358.105z"
          />
          <path
            id="아산시"
            data-code="34040"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M139,286.23l-0.375-3l-1.625-7.5l4-4.5l5-0.25l-0.125-1.5l1.125-0.375
l10.125-4.875l4.5,1.75l6.5-2.875l-0.375,1.625l1.375,10.875l1.5,0.25L171,278.48l-2.625,2.625l0.5,6.125l0.875-1.375l2,1.875
l-0.25,4l-2.625,0.875l-1,2.375l-1.125,5.125l-2.875,0.875l-4.875,6.5l0.25,1l-3.5,1.25l-3.375-3.25l0.5-3.375l-2.75-1.5
l-4.75-0.375l-2-4l-5.625-3.375l-1.125-5.5l2.375-0.25V286.23z"
          />
          <path
            id="당진시"
            data-code="34080"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M91.625,268.855l1.125-7.75l-0.875-6l-4-5.5l4.625-3.75l0.25-1.625
l5.5,0.5l5.625,6l6.5,3.75l9.875,0.5l9.25,3.25l1.375,6.5l2.5,2.25l2.25,7.75l1.375,1l1.875,9.25l0.125,3.125l-2.375,0.25l-2-0.25
l-6.5,5.75l-5.625-6.875l-2.5-1.625l-5.5,4.5l-5.125-6l-2.125-0.375l-1.625-2.375l-5.375,0.75l-4.125-2.125l-0.375-4.625
l-4.625-2.375L91.625,268.855z"
          />
          <path
            id="예산군"
            data-code="34370"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M138.375,322.105l-5.125,7l-4.75-1l-2.875,0.125L125,323.98l2-4.75
l1.25-2.625l-2.75-4.875l-2-4.75l-4.625-0.25l-9.375,2.75l-0.25,4.125l-4.125-0.125v-5.75l2.375-7.25l-0.625-2.5l3.375-4
l4.25-4.125l5.5-4.5l2.5,1.625l5.625,6.875l6.5-5.75l2.125,0.75l1,5l5.625,3.375l2,4l4.75,0.375l2.75,1.5l-0.5,3.375l-3.25,10.25
l-6,8.375L138.375,322.105z"
          />
          <g id="서산시">
            <path
              id="서산시"
              data-code="34050"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M74,298.23l2.75-11.125l-4-4.75l-0.875-3.25l2.5-3.375l2.5,4.5l0.875-7.25l4.125-2.5
L84,268.105l-0.125-3.125l-4.625-1.25l-6-4.625l-0.75-5l2.125-3.25l1.875,2.375l1.125-1.625L82,251.48l3.625,2l2.75,1.125
l4.25,8.125l-1.5,10l4.625,2.375l0.375,4.625l4.125,2.125l5.375-0.75l1.625,2.375l2.125,0.375l5.125,6l-7.625,8.125l0.625,2.5
l-2.125,6.375l-3.125,3.5l-4.75,0.375h-6.375l-2.375-6.375l-2.5,1.5l0.375,5.625l-3.375,5.375l-2.5-2.625l-3-1.375l-2.5-7.375
L74,298.23z"
            />
            <path
              id="서산시"
              data-code="34050"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M69.375,257.48l-2,1.75v4.25L70,265.605l0.5-4L69.375,257.48z"
            />
          </g>
          <g id="태안군">
            <path
              id="태안군"
              data-code="34380"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M70.5,268.73l-3-2.25l-3.625,6l-4.375,2.375l1-3.5l3-3.625l-4.25,0.5l-4.5,1.875
l-1.75,2.5l2.375,2l-1.125,5.5l-3.25-2.5l-1.375,3.875l0.25,3.875l-3.125,3.625l-3,0.625l3.625,3.5l-0.75,5.125l3.875-1.75
l0.125-3.25l4.5,1.5l3.625,3.625c0,0-4.625-0.5-5-0.5s-5.125,3.75-5.125,3.75l-2,2.875l5.75,1.25l4.5-2.5l4.75-1.5l2.625-1.75
l0.25,4.75l3.375,2.75l0.25,5.75l-0.75,4.125l1.375,1.625l3-2.75l3.25-3.375l-0.625-4.75L74,298.23l2.75-11.125l-4-4.75
l-0.875-3.25l-0.5-4.875L73,271.605L70.5,268.73z"
            />
            <path
              id="태안군"
              data-code="34380"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M76.125,314.48l-3.375,2.125l-2.625,4.125l2.375,4.875l0.25,7.75l-0.5,4.5l3,5.125
l1.25,2.375l4.375,1.75l1.75,1.625L86,344.98l-1.625-7.25l-3.25-7.875l-1-3.5l1.25-5.375l-0.625-3.625L76.125,314.48z"
            />
          </g>
          <path
            id="홍성군"
            data-code="34360"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M91.375,330.605l-3.75-5.875l0.5-6.625l2.75-1.125l-1.375-2.75v-7.125
l1.625,3.625l5.75,0.125l5.375-0.5l2.625-3l0.25,6.125l4.125,0.125l0.25-4.125l9-2.5h5l2,4.375l2.75,5.25l-3.25,7l0.625,4.625
l2.875-0.125l-1.125,3.5l-2.875,5.25h-5.375l-5,2.125l-5.125-2.5l-2.625-0.375l-1.625-2.875l-3.875-0.375l-3.375-1.75l-3-0.875
L91.375,330.605z"
          />
          <path
            id="청양군"
            data-code="34350"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M120.125,348.855l-2-4.375l-3.5-5.875l4.5-1.75h5.375l3-5.625l1-3.125
l4.75,1l5.125-7l4.75,3l-0.25,8.875l5,6l1.5-1.375h5.75l4.625,4.375l0.125,5.25l-4.375,3.625l-0.375,6.5l-3.375,1.5l-4.375,2.25
l-2.75-2.875l-3.75,1.25l-2.125-4.125l-2.125-2.625l1.375-2.5l-3.625,0.375l-2.625,1.625l-1,2.25l-4.25-0.125l-3.375-3.25
l-2.375-0.5L120.125,348.855z"
          />
          <path
            id="논산시"
            data-code="34060"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M163.375,388.355l2,5.625l0.875,3.375l6.125,3l2.875-3.25h7l2.625-2.125
l4.125,1.5l0.75-4.125l4,1l5.25-3l1-9.625l-7.125-7.5l-2,1.5l-3.625-0.125l-1.125-2.75l-2.75-0.375l-1.875-4.375l1.625-2.875
v-2.375l-1.5-2.75L176,362.98l-3.5-2.125l-1.75-2.75l-2.25,0.25l-3.25,8.25l0.5,3.875l-3.5,3.625l-3.25,4.5h-3.625l0.25,4.75
l2.75,3.375l1.25,2.5L163.375,388.355z"
          />
          <path
            id="금산군"
            data-code="34310"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M209.417,409.105l2.167-2.333l3.833,5.833l9.333-0.333l1-8.167l10,6.333
l1.667-5l-1.667-4.166l2.167-2.167l-2.5-5.833l-3.5-3.334l1.5-3.5l-0.167-4l-0.667-4.666l-1.667-2.334h-3.833l-2.5-3l-4.667,1.334
l-6.667,6.958l-2.833-2.625l-4.042-3.5L206,368.73l-1.375-0.375l-1.708,2.417l0.083,5.833l-2.417,4.167L199,390.355l2.75,2.583
l2.167,9.667l4.333,6.167L209.417,409.105z"
          />
          <path
            id="부여군"
            data-code="34330"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M120.75,353.355l-4.125,8.375l2.25,5.875l2.125,3.75l3.375,1.375
l-0.625,2.625L121,376.73l-3.625,4.75l4.75,3l8.875-0.625l0.125,5.375l3.375,1.25l2.625,3.375L138,398.98l3.625-0.375l-0.125-6.375
l2.25-3.875l4.75,0.125l3.125-1.125l2.125,0.875l5.875,1l-1.25-2.5l-2.75-3.375l-0.25-4.75H159l3.25-4.5l3.5-3.625l-0.75-3.375
l-9.5-3.625l-0.375-5.125l-7.75,3.75l-2.75-2.875l-3.75,1.25l-2.125-4.125l-2.125-2.625l1.375-2.5l-3.625,0.375l-3,2l-0.625,1.875
l-4.375-0.625l-3.25-2.75l-2.375-0.5V353.355z"
          />
          <g id="보령시">
            <path
              id="보령시"
              data-code="34030"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M85.25,351.355c-0.5,0-5.625,0.875-5.625,0.875l0.25,1.625l5.375,0.75l2.875,1.25
l0.5-1.125l-2.5-2.25L85.25,351.355z"
            />
            <path
              id="보령시"
              data-code="34030"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M102.625,381.605l2.625,2.625l8.125-0.75l4-2l3.625-4.75l2.75-1.375l0.625-2.625
L121,371.355l-2.125-4.125l-2.25-5.5l4.125-8.375v-1.75l-0.625-2.75l-2-4.375l-3.5-5.875L109,336.48l-2.625-0.375l-1.625-2.875
l-4.375-0.5L98,331.105l-3.5-0.875l-3.125,0.375l-1.125,1.75l0.125,5.375l3.5,3.875l-0.625,6.5l-2.625,3.375l6.75,1.625l2.5,2.875
l-1.125,2.375l-5.25,1.625l5.375,6.875l-0.625,5.25l-1.5,4l1.75,2.375v2.25L102.625,381.605z"
            />
          </g>
          <path
            id="서천군"
            data-code="34340"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M119.875,410.605l3.875-0.625l3.625-4l6.625-0.25l5.375-4.25l1.875-2.875
L138,398.98l-0.875-5.125l-2.625-3.375l-3.375-1.25L131,383.855l-8.875,0.625l-4.75-3l-3.5,1.875l-8.625,0.875l-2.625-2.625
l-5.75,0.125l-1.25,3.5l-2.25,1.25l0.125,3.25l2.625-1.5l4.375,1.875l3,0.625l4.875,5.125l2.75,0.25l-2.25,3l1.75,2.125
l1.625,1.875l1.5,2.375l1,4.375L119.875,410.605z"
          />
        </g>
        <g id="제주특별자치도">
          <g id="제주시">
            <path
              id="제주시"
              data-code="39010"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M381.083,674.105l12.667-11.666l0.333-3.166l4.334-2.834l2.5-3
l8.833-0.334l7.333-3.5l7.334-4h10.333l5.667-2.5l6.333-2l8.833-0.332l2.834-2.5l9,0.332l2,4.834l6.166,1.666l2.834,0.168l0.5,4
l-0.5,3.5l-11.5,5.832l-3.667,0.334l-3.167,3.666h-8.666l-2.667,2.168l-3.5,0.5l-5.167,3.832l-8.5,0.168l-2.333,2.332l-11,0.5
l-0.5,3.334l-8.333-1l-6.5,1.166l-9.5,6.668l-2.167,2.666l-6.167-3.834l-4,1.334V674.105L381.083,674.105z"
            />
            <path
              id="제주시"
              data-code="39010"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M484.583,646.439l-1.5,3.166l2,2.334l1.5-1.166l0.834-3.334L484.583,646.439z"
            />
          </g>
          <g id="서귀포시">
            <path
              id="서귀포시"
              data-code="39020"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M383.25,686.439l-2.167-4l4-1.334l6.167,3.834l2.167-2.666l9.166-6.834l6.334-1l8.833,1
l0.5-3.334l11-0.5l2.333-2.332l8.5-0.168l5.167-3.832l3.5-0.5l2.667-2.168h8.666l3.167-3.666l4.333-0.5l10.834-5.666l3.166,2.5
l1.334,2l-2.334,2.832l1.334,2.168l-4,3.832l-3.334,3.334l-5.833,8l-0.167,3.334l-7.5,0.832l-5.5,4.5c0,0-10.667-0.5-11,0
s-6,6.334-6,6.334l-4.333-1.5l-11,1.166l-5-0.5h-3.167l-2.666-1.332l-2.334,1.666l-9-0.666l-3.333,2.332l-0.5,3.168l-2.833-0.334
l-5.667-6l-3.667-0.834L383.25,686.439z"
            />
            <path
              id="서귀포시"
              data-code="39020"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M394.417,706.773l-0.5,3.5l1.5,0.332l1.333-2.332L394.417,706.773z"
            />
          </g>
        </g>
        <g id="경상북도">
          <path
            id="문경시"
            data-code="37090"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M298.083,313.939l4,2.5l7.834,3.667l1.166,7.167l5.5,0.333l0.667-2.833
l2.833-0.667l-0.166-4.833l-2.334-4.5l6-5.833l2.834,0.5l0.5-1.667l-3-3.667l2-10.333l3.333-0.333l-0.25-8.46l-2.25-2.125
L323,281.48l-6.5-7.625l-3.125-0.75l-3.458,3l-0.167,5.333l-2.667,0.833l-6.833-2.917l-6.25,5.25l-1.375-1.625l-2.042-0.375
l-1.333,5.333l-2.625,4.792l6.125,6.125l-3.5,1l-4.75-3.375l-2,1.75l-4.125-2.625l-2.5,5l-3.25,2.375l-2.375,0.292l5.5,5.833
l-1.875,7l1.875,7.167l-0.167,4.333l4.334,1.5l2.333-3.833l5.667,0.5l2.666-4.667l-0.5-2.167l3.667-3.167l2.667-0.333
L298.083,313.939z"
          />
          <path
            id="상주시"
            data-code="37080"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M298.75,372.772l2.167-3.667l2.333-5.333l7.834-0.5l2.5-6.834l3.666-2.833
l3.167,2.333l2.667-4.666l3.833-4.5l-2.333-4.834l-2.334-3.833l-4.666-1.833l-1.001-8.667l-5.5-0.333l-1.166-7.167l-7.834-3.667
l-4-2.5l-1.666,1.5l-2.667,0.333l-3.333,3.167l0.167,2.667l-2.667,4.167l-5.667-0.5l-2.333,3.833l-4-1.667l-0.167-4.833l-1.875-6.5
l-5.458-5.833l-1.042-1.417l-3,3.375l0.875,2.25l-8.333,1.792l1.166,2.667l4.5,2.5h3.5l1,3.667l3.834,4.667l0.166,2.667l-3.333,2
l-0.167,9.167l1.001,2.167l-2.501,2.666l2.834,3.834l0.333,3.5l-5.666,6.333l1.833,5.333l3.333,0.5l2.833-4l3.334,4.167
l5.333,2.833l2.667-2.5l4.333,0.167l2.167,2l2.833,2.5l3.667-0.833L298.75,372.772z"
          />
          <path
            id="예천군"
            data-code="37400"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M339.417,327.939l2.666-5.5l3.667-2.667v-5.167l4.5-0.667l-0.167-3.167
l6.5-2.667l2.5-0.167l0.834-6.833l-4.334-5.667l-3.166-5.5l-4.667-0.167l-5-1.333l0.167-6.167l0.333-2.333l-3.125-3.833l-3.75,5.75
l-3.875-0.25l-3.5,3.375l-0.25,8.458l-2.833,0.333l-2,10.333l2.666,3.167l-0.166,2.167l-2.834-0.5l-6,5.833l2,4.667l0.5,4.667
l-2.833,0.667l-0.667,2.833l0.667,8.333l5,2.167l3.166-3.833l1-5.667l4.5-0.167l1.167,2.667l3.833-3.167h3.501V327.939z"
          />
          <path
            id="영주시"
            data-code="37060"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M375.583,299.105l-0.333-13.5l-1-3l1.667-5.167l-2.5-3.167l-6.167,0.667
v3.167l-3-3.833l2.167-9.5l2.5-7.333l2.333-11.667l-6-2.833l-2.75,3.167l-6.25,0.125l-4.5,8l-2.625,0.875l-9.625,9.25l-1.917,5.25
l1.542,3.75l1,2.75l3.125,3.833l-0.5,8.5l5,1.333l4.667,0.167l3.166,5.5l2.167,2.667l8.167-0.333l3.666,2.167l2.334-1.167
L375.583,299.105z"
          />
          <path
            id="봉화군"
            data-code="37410"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M408.417,291.439l-2.667,0.5l-3.833-4l-5.167-0.333l-9.167-5.5l-2.5,1.167
l-1.333-2.5l-3.667,2.167l-5.166,1.667l-0.5-2.667l1.5-4.5l-2.5-3.167l-6.167,0.667v3.167l-3-3.833l2.167-9.5l2.5-7.333
l2.333-11.667l5.833,1.5l-0.666-3l5-5.5l5.5,3.833l4.833,1.333l4.167-0.667l3-6.333l4.333,2.5l3-1l9.5,2.167l0.667-2.333
l2.833-2.333l3.667,0.833l8.666,7.333l-3.5,4l-2.833-1.833l-6,3.333l1,6.833l-1.333,3.167l6.833,4.5l2.333,5.667l-4,3.5l-2,4.333
l-6.333,4.167l-3.5,3.833l-5.5,1l0.667,2.667L408.417,291.439z"
          />
          <path
            id="영양군"
            data-code="37340"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M443.75,302.605l2.333-6.667l-3.166-4.5l5.5-6.833l-5-6.833l0.166-5.333
l-3-0.833l-1.666,2.667l-4.167-2.5l-6.667-2l-4,3.5l-2,4.333l-6.333,4.167l-3.5,3.833l-5.333,1.5l0.5,2.167l1,2.167l-0.167,2
l0.667,6.833l-0.167,11.333l1.833,4.5l3.667,0.5l3.5,5.667l6,0.667l2.667,3.667v2.167l3.833-0.167l7.333,1.833l1.667-2.333
l1.167-8.333l-3.167-5.167l2.5-6l-1-4.167l1.5-1.667L443.75,302.605z"
          />
          <path
            id="울진군"
            data-code="37420"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M463.417,303.772l-8.5,0.167l-2.334,2l-4-2.5l-4.833-0.833l2.333-6.667
l-3.166-4.5l5.5-6.833l-5-6.833l-0.334-5.333l-2-0.833l-2.5,2.667l-4-3l-6.5-1.5l-2.333-5.667l-6.833-4.5l1.333-3.167l-1-6.833
l6-3.333l3.167,1.667l3.166-3.833l5.667-0.167l-0.333-5.333l5.833-5.667l3.667-0.333l3.666-4.167h3.5l2.5,7l6.167,5.833
l-2.333,2.833l0.833,7.833l-1,3.5l2.167,4.833l-0.834,6.167l0.834,7.667l4.5,4.5l2.5,7.667l-0.667,2.667l0.833,3.333l-0.5,7.333
L463.417,303.772z"
          />
          <path
            id="안동시"
            data-code="37040"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M397.083,362.438l-2.833-9.833l-4.833-7.667l-1.667-5.333l-10-5.833
l-2.167,1.5l-2.166-0.333l-0.667,5.333l-12,5l-4.5-4.5l-4-8l-5.167-1.5l-3.333-1.833l-2.5,3l-3.5-1.5v-2.833l1.167-0.167l3.5-6
l3.333-2.167v-5.167l4.5-0.667l-0.167-3.167l6.5-2.667l2.5-0.167l0.834-6.833l-2.167-3l8.167-0.333l3.666,2.167l2.334-1.167
l3.666,0.333l-0.666-14.5l5.166-1.667l3.667-2.167l1.333,2.5l2.5-1.167l8.667,5.333l5.667,0.5l3.833,4l2.333-0.5l0.834,8.833
l-0.167,11.333l1.833,4.5l-2.5,3.333l0.334,3.167l-2.5,2.333l3.666,3.833l-2.333,5.833l-1.167,4l1.667,5.333l-2.333,6.167v6.5
l-6,5.667L397.083,362.438z"
          />
          <path
            id="의성군"
            data-code="37320"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M340.583,359.605l-5,6l-7.333-9.667l-4.834,3l-2.999-3l2.833-5.333
l3.333-3.833l-4.333-8.667l3.166-3.833l1-5.667l4.5-0.167l1.167,2.667l3.833-3.167l1.834,0.167v2.833l3.5,1.5l2.5-3l3.333,1.833
l5.167,1.5l3.666,8l4.834,4.5l12-5l0.667-5.333l2.166,0.333l2.167-1.5l10,5.833l1.333,5l4.833,8.167l3.167,9.666l-2,0.334
l-0.333,3.333l-3.167,2.167l5,2.166l-1.833,2.834l2.333,3.666l-0.667,1.667l-5.666-3.167l-5.834,3l-8.333-0.166l-8.333-3.167
l-3.667,1.333l-0.333-5.666l-7-4.667l-3.667,0.667l-1-3.334l-6.5-0.666l-3.5-3.167L340.583,359.605L340.583,359.605z"
          />
          <path
            id="김천시"
            data-code="37030"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M292.916,435.105l2.334-4.832l-4.334-10.335l2.5-0.333l2.334,2.334
l3-5.167l2.833-1.167l3.5-4.667v-5.5l3.5-0.666l3.333-3.334h10.5l-1.5-4.166l0.834-2.167l-2.167-2.333l0.333-3.667l0.834-4.5
l-2.834-2.833l-0.666-2l-4.5-1.167l-0.167-3.333l-7.333-4.167l-4.667-1.5l-2.167,3l-2.499-0.333l-3.667,0.833l-2.833-2.5
l-1.834,2.333l4.334,7.334l-3.167,0.333l-4.334-1.667l-3.166,0.834l1,4.166l1.333,3l-3,4.334l-1.5,9l-6.5,2.166l-4.167,4.5
l0.167,3.834l4.833,7.5l-3.833,2.833l0.333,5.668l5.334,1.832l1.166,4.5l6.167-0.332l3.667,3.666L292.916,435.105z"
          />
          <path
            id="구미시"
            data-code="37050"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M331.916,400.105l4.167-5c0,0,7.001,2.833,7.167,2.333
s3.833-6.666,3.833-6.666l9,0.5l-3.167-6.5l1.167-2.167l-3.5-3.833l-3.833,2.333l-1.5-6.667l-4.5,1l-4.5-4.666l-0.667-5.167
l-7.333-9.667l-4.834,3l-2.999-3l-3.167-2.333l-3.666,2.833l-2.5,6.834l-7.834,0.5l-2.667,5.833l5.333,1.667l6.667,4l0.167,3.333
l4.5,1.167l0.666,2l2.834,2.833l-0.834,4.5l-0.333,3.667l2.167,2.333l3.166,0.333L331.916,400.105z"
          />
          <path
            id="성주군"
            data-code="37380"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M304.917,444.939l1.333-1.834l-0.5-3.332l5.333-1l3.667,4.664l5.167-5.664
l3.5,1.664l5.5,2.5l4.833-6.832v-7.334l2.333-5l-1.333-3.166l-4.167-1.833l-7-6.334l0.334-5.833l-1.501-4.167h-10.5l-3.333,3.334
l-3.5,0.666v5.5l-3,4l-3.333,1.834l-3,5.165l-2.334-2.332l-2.5,0.333l4.334,10.333l-2.334,4.834l6.334,2.5l2.667,5.832
L304.917,444.939z"
          />
          <path
            id="고령군"
            data-code="37370"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M304.583,463.273l0.334-2l6-4.334l-2.334-6.834l-3.666-5.166l1.333-1.834
l-0.5-3.332l5.333-1l3.667,4.664l5.333-6l8.834,4l4.833-7.164l4.833-1.168l6.667,3.334l-0.667,2.5l-6.5,0.5l-2.333,5.334
l-1.5,2.832l6,4.834l-0.667,5.166l-9.166-1l0.666,4.668l4.834,4.5v4.832l-3.334,0.334l-3.166-4.5l-4.5-2.166l-7.834,0.332l-2.833,2
l-3.833-0.666l-1.334-1.5L304.583,463.273z"
          />
          <path
            id="칠곡군"
            data-code="37390"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M348.417,425.939l-4.334-1.334l-0.333-5.167l-2.5-1.166l-5.167,4.501
l-1.333-3.168l-4.667-2.333l-6.5-5.834l-0.166-6.333l-2.501-7.833l0.834-2.167l3.166,0.333l7,4.667l4.167-5l7.167,2.333l4.333-6.5
l8.5,0.334l0.334,7.166l7.333-1.833l0.333,10.167l-3.666,4.5l-6,3l-2.5-1.667l-1.334,1.333l1,3.667l-0.833,4.168L348.417,425.939z"
          />
          <path
            id="군위군"
            data-code="37310"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M372.917,406.771l0.666-8.5l4.667-6l5.5,1.834l3.5,0.833l4.333-7
l6.834-1.167l-0.667-5.666l-1.334-2.5l-5.666-3.167l-5.5,2.833l-8.667,0.001l-8.333-3.167l-3.667,1.333l-0.333-5.666l-7-4.667
l-3.667,0.333l-1-3l-6.5-0.666l-3.5-3.167h-2l-4.666,5.333l0.166,5.167l4.667,5.333l4.5-1l1.5,6.333l3.833-1.999l3.5,3.833
l-0.833,2.5l2.667,5.666l0.5,7.667l7.333-1.833l0.333,10.167L372.917,406.771z"
          />
          <path
            id="영덕군"
            data-code="37350"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M458,365.355l-10.25-0.5l1-5.75l-2.25-2.5l-3.5,0.5l-4.25-4.5l-0.25-5
l-3.25-6.5l-3.75-0.75l-2.25-4.5l-0.25-6.75l1.25-0.5l7.333,1.833l1.167-1.583l1.667-9.083l-3.167-5.167l2.5-6l-1-4.167l1.5-1.667
l3.5-0.167l4.833,0.833l4,2.5l2.334-2l8.5-0.167L462,309.355l0.25,6.5l4.5,5.75l-0.5,6.5l-1.25,7l-0.25,8.75l-6.5,11L458,365.355z"
          />
          <path
            id="청송군"
            data-code="37330"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M419.25,374.605l2-3.25l-4.75-3.75l2.75-1.75l8.25,2.25l5.5,6l6-9.5v-4.25
l4-3.25l-4.25-4.5l-0.25-5l-3.25-6.5l-3.75-0.75l-2.25-5.25l-0.25-6l-2.583-0.333v-2.167l-2.667-3.667l-6-0.667l-3.5-5.667
l-3.667-0.5l-2.5,3.333l0.334,3.167l-2.5,2.333l3.666,3.833l-3.5,9.833l1.667,5.333l-2.333,6.167v6.5l-6,5.667l-4.334,0.5
l-0.333,3.333l-3.167,2.167l5,2.166l-1.833,2.834l2.333,3.666l-0.667,1.667l1.334,2.5l5.5,0.5l4.5,2l5.75-3l2.5-5.25
L419.25,374.605z"
          />
          <path
            id="포항시"
            data-code="37010"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M446.5,395.605l-4.25,0.5l-1.25,3.75l-5.5,1.5l-4-3l-3-0.75l-0.5-2.25
l-4.25-3.25v-2.25l-3.25-0.25l-2.75-4.5l-9-1.75l4.75-2.75l2.5-5.25l3.25-0.75l2-3.25l-4.75-3.75l2.75-1.75l8.25,2.25l5.5,6l6-9.5
v-4.25l4-3.25l3.5-0.5l2.25,2.5l-1,5.75l10.25,0.5l1,4.5l1.5,1.25l-1.75,4l-1.25,0.5l1,2l2.5,2.25l0.75,5.5l4.5,4.25l-1.25,2.5
l-6,6l3.25,4.5l2.25,3.25l5.25,2.75l2.75-4l3.25-2.25l5.75-8.75l2,2l1.25,7.5l-2.75,6.5l-1.25,5.5l-4,2.5l2,7l-0.25,6.25l-1.75,2.5
l-6-3.25l-4.75-5l-2.25,3.75l-6.25-0.5l-0.75-3.25l-2-2.5l-3.5-11.25l-5.25-8l1.75-6L446.5,395.605z"
          />
          <path
            id="영천시"
            data-code="37070"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M406,434.105l-7-1l-4.25-5l0.5-3l-1.75-3.75l-0.5-6l-6.5-2l-3.5-2.75
l-5,0.75l-1.25-3.5l-3.833-1.084l0.666-8.5l4.667-6l9,2.667l4.333-7l6.834-1.167l-0.667-5.666l5.5,0.5l4.5,2l10,1.5l2.75,4.5
l3.25,0.25l0.25,3l4,2.5l0.5,2.25l1,0.75l-1,3.25l1,5.25l-3-0.75l-1.75-1l1.5,9.25l3.25,4.5l-2.75,1l-2.5-2.75h-4.75l-3.5,6
l-0.5,7.25l-5.75,4.5L406,434.105z"
          />
          <path
            id="경산시"
            data-code="37100"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M402.75,445.355l-4,0.75l-5.25,2l-8,0.5l-3.75,7.25l-5.5-3l-3.75-4.25
l1.25-2.75l-1-3.25l3.25-2.5v-4.75l1.5-3.75l3.5-2v-7.5l-2.5-1.75l-0.5-9l5-0.75l2.75,2.75l7.25,2l0.5,6l1.75,3.75l-0.5,3l4.25,5
l8,1.25l-1,5.25L402.75,445.355z"
          />
          <path
            id="청도군"
            data-code="37360"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M369,472.855l-5.5,2.5l-9.25-7.5l-2-5.25l-0.75-3.75l0.75-4.25l4.5-3.5
l7.25,0.25l-0.5,4.5l7-2.5l2-4.75l2.75,3.5l6.5,3.75l3.75-7.25l6.25-0.75l7-1.75l4-0.75l3.25-5.75l1-5.25l2.75,0.5l4.5-3.25
l-2.75,6.5l-2.5,8l5,9.25l2.25,1.25l2.75,8l-5.25,2l-3.5,3.25l-3.5-3.5l-4.25-1.75l-6.25,2.25l-2.5,5.25l-5.5,0.25l-2.75,3.75
l-7.25-0.75l-5.25-1.75L369,472.855z"
          />
          <path
            id="경주시"
            data-code="37020"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M458,457.355l-7.25,4.75l-4.5-1.25l-0.5-7l-6.5-1.75l-6.75,0.25l-1.25-1
l-3,2.5l-4.75,0.75l-3.25,6.5l3,3.5l-4.25-0.75l-2.75-7.25l-2.25-1.25l-5-9.25l5.25-14.5l1.25-1.25l0.5-7.25l3.5-6h4.75l2.5,2.75
l2.75-1l-3.25-4.5l-1.5-9.25l4.5,1l-0.75-4.5l1-3.25l2.75,0.25l3.25,2.75l5.5-1.5l1.25-3.75l4.25-0.5l1.25,2.75l-1.75,5l5.25,8
l3.5,11.25l2,2.5l0.75,3.25l6.25,0.5l2.25-3.75l4.75,5l6,3.25l0.5,2.75l-2.75,3.25l-0.5,8.75l-1.75,6l0.25,2.75l-4.25,4.75
L458,457.355z"
          />
          <g id="울릉군">
            <path
              id="울릉군"
              data-code="37430"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M493.25,352.105l-3.25-0.25l-2.25,1.5l-3.75,2.5l-4.25,0.25l-2.75,0.5l1.25,2.5l1,2.5v2
l8,3l0.75-2l4-2.75l0.25-6L493.25,352.105z"
            />
            <path
              id="울릉군"
              data-code="37430"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M504.25,365.105l-1.25,2.5l2,1l1.25-2.5L504.25,365.105z"
            />
          </g>
        </g>
        <g id="대구광역시">
          <path
            id="동구_3_"
            data-code="22020"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M367.25,428.605l-3.333,1.834l-1.334-1.834l1.667-3.166l0.667-2.834
l-1.167-4.333l-3-7.334l3.333-4.166l8.834-0.001l3.833,1.084l1.25,3.5l0.5,9l2.5,1.75l-0.417,7.5l-2.5,1.668l-6.666-0.668
l-0.5-1.832L367.25,428.605z"
          />
          <path
            id="북구_2_"
            data-code="22050"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M360.917,428.773l-2.667,0.332l-0.333-2.5l-3.167-0.5l-3.667,1.168
l-2.666-1.334l2.333-4.166l0.833-4.168l-1-3.667l1.334-1.333l2.5,1.667l6-3l3.333,7l1.167,4.333l-0.667,2.834l-1.5,2.666
L360.917,428.773z"
          />
          <path
            id="수성구"
            data-code="22060"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M361.75,432.273l-0.333,5l-1.667,1.5l4.5,0.5l3.333,1.5l5.167,1.832
l3.25-2.5l-0.417-4.666l2.5-4.166l-6.666-0.668l-0.5-1.832l-3.667-0.168l-3.333,1.834L361.75,432.273z"
          />
          <path
            id="중구_4_"
            data-code="22010"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M357.75,431.939l0.5-2.834l4-1l1.667,2.334l-2.167,1.834L357.75,431.939z"
          />
          <path
            id="서구_2_"
            data-code="22030"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M350.25,430.105l0.833-2.832l3.667-1.168l3.167,0.5l0.333,2.5l-0.5,1.834
l-2.667,1h-1.833L350.25,430.105z"
          />
          <path
            id="남구_3_"
            data-code="22040"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M356.083,433.773l-0.666,2.666l4.333,2.334l1.667-1.5l0.333-5l-4-0.334v-1
l-1.5,0.834L356.083,433.773z"
          />
          <path
            id="달서구"
            data-code="22070"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M347.417,439.105l2.166,1.334l2.667,2.666l4.5,1.5l1.833-2.832l1.167-3
l-4.333-2.334l0.666-3.166l0.167-1.5l-1.167,0.166h-1.833l-3-1.834l-4.833,0.668l-0.167,5.664l-0.5,2L347.417,439.105z"
          />
          <g id="달성군">
            <path
              id="달성군"
              data-code="22310"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M345.417,430.773l4.833-0.668l0.833-2.832l-7-2.668l-0.333-5.167l-2.5-1.166
l-4.667,4.001l-2.833,4.832v7.168l4.833-1.168l6.667,3.334L345.417,430.773z"
            />
            <path
              id="달성군"
              data-code="22310"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M342.583,466.773l7-0.168l1-5.5l0.917-2.25l0.75-4.25l4.5-3.5l7.25,0.25l-0.5,4.5
l7.417-2.582l1.583-4.668l1.25-2.75l-1-3.25l-8.5-3.332l-4.5-0.5l-1,2.332l-2,3.5l-4.5-1.5l-2.667-2.666l-2.5-1.666l-2.333-0.334
l-6.667,1l-3.833,8.166l6,4.834l-0.667,5.166l-9.166-1l0.666,4.668l4.5,4.5l0.334,4.832L342.583,466.773z"
            />
          </g>
        </g>
        <g id="울산광역시">
          <path
            id="북구_1_"
            data-code="26040"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M456.75,471.355h-3.375L451,472.73l-0.5-7.125l0.25-3.5l7.25-4.75
l10.25,4.25l1.25,4.5l2.125,3l-0.25,3.375l-1.5,2l-3,0.75l-2.625,2.5v4.75l-6.375-4.75l-0.625-4.5L456.75,471.355z"
          />
          <path
            id="중구_3_"
            data-code="26010"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M455.875,477.98l-4.5,0.125l-1,0.875l-2.125-1.375l-2.125-0.25
l-0.375-3.375l3.25-1.5l2,0.25l2.375-1.375h3.375l0.5,2.25l0.625,4.125L455.875,477.98z"
          />
          <path
            id="동구_2_"
            data-code="26030"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M463.25,484.98l1-2.5v-4.75l2.625-2.5l3-0.75l1.375,1.75l-1.125,2.875
L470,484.73l-1.625,3l-3,2.375l-0.375-4.25L463.25,484.98z"
          />
          <path
            id="남구_2_"
            data-code="26020"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M462,489.605l-3.875,2.375l-2.375-3.625l-3.5-2l-4.75-5.125l-3.5-1
l2.125-2.875l2.125,0.25l2.125,1.375l1-0.875l6.5-0.375l5,3.625l-0.875,2.5l-0.25,2.375L462,489.605z"
          />
          <path
            id="울주군"
            data-code="26310"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M459.5,507.605l-0.875-4.25l0.875-2.625l-0.625-2.75l1.25-2.25l-2-3.75
l-2.375-3.625l-4.25-2.25l-4-4.875l-3.5-1l2.125-2.875l-0.375-3.375l3.625-1.625L451,472.73l-0.5-7.125l-0.125-3.125l-4.125-2
l-0.5-6.625l-6.5-1.75l-6.75,0.25l-1.25-1l-3,2.5l-4.75,0.75l-3.25,6.5l2.375,3.375L419,464.605l-5.25,2l2.125,3.125l-0.75,3.75
l-5.25,3v2.875l3,2.5l1.5,1.5l6.5,0.875l5.875,4.25l6.125,8.25l5.625-0.375l2.875,3.75l-2,3.75l5.25,0.75L447,503.73l2.5,2.125
l0.375,5.75l1.875,1.625l4.75-3.875l2.625-0.625L459.5,507.605z"
          />
        </g>
        <g id="경상남도">
          <path
            id="거창군"
            data-code="38390"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M243,446.855l4.75,3.5l0.5,3l5-1.5l3.5,4l4.75,4l3.75,4l3-0.75l0.25,7.75
l-4,6.5l4.25,2.25l1.25,4.25l8.75,3l2.25-6l3-6.25l1.75-8l5.75-6l3.75-1l-2.25-6.75l-2.25-7.5l-0.75-1.75l6.25-7.25l-2.5-1.5
l-7.5,1.584l-4.25-3.584l-5.584,0.25l-1.166-4.5l-5.334-1.832l-0.166-1.918l-3,0.75l-2,4.5l-7,4.75l-6.5,0.75l-1.75,5.75
L243,446.855z"
          />
          <path
            id="합천군"
            data-code="38400"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M297.25,508.855l-5.25-7h-3.75l-6-9.5l-3.5-6.25l5.25-11.5l1.75-8l5.75-6
l3.75-1l-4.5-13.5l-0.75-2.5l6.25-7.25l3,1.25l2.667,5.834l3,1.5l3.666,5.166l2.334,6.834l-6,4.334l0.583,1.832l3.583,1.334
l1.334,1.5l3.833,0.666l2.833-2l7.834-0.332l4.5,2.166l3.166,4.5l-2.083,2.916l0.25,5l-1.75,2.75l2.75,4.5v3l-10.25-2.25
l-6.25,4.25l-4.75,1.75l-0.5,6.5l-1.25,5.5l-5.75,0.5L297.25,508.855z"
          />
          <path
            id="함양군"
            data-code="38380"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M247.25,516.105l4.75-4l6.5-15.25l2.25-4.75l0.5-4.25l1.25-4l3.75-5.5
l-1.75-1l4-6.5l-0.25-7.75l-3,0.75l-3.75-4l-5.5-4.75l-2.75-3.25l-5,1.5l-0.5-3l-4.75-3.5l-0.5,3.25l-1,6.5l-3,2.5l-2,5.5l2,5.75
l-2.5,2.5l-1,3.25l-3,2.25l4.25,4.5l3.75,6.75l-1.75,3l1,4l3.75,1.5l-1.75,4.5l-5.25,4.75l-0.5,4.25l2,3.25L247.25,516.105z"
          />
          <path
            id="창녕군"
            data-code="38330"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M335,494.105l3.75-4l-4.75-3.75l-2.25-0.25l-2.75-4.5l1.5-4l-0.25-4.5
l2.333-2.166l3.334-0.334l5.833-3.75l7.833-0.25l1-5.5l0.667-1.5l1,3l1.25,4l10,8.75l-3.25,1.5l0.75,4l-2.5,2.25l1.5,3.25l-1.25,3
l1.25,5l5.25,1l3.5,4.75l-0.5,6.75l-6.75-2.25l-3.5,2l-7.25-3l-4.75,3l-5-0.75l-0.75-4.5l-6.25-2.5L335,494.105z"
          />
          <path
            id="밀양시"
            data-code="38080"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M387.25,510.605l8-6.25l4.5-5l4,0.25l-1.25-4.25l6.25-2.25l-0.25-6.75
l3-5.5l-1.625-1.5v-2.875l5.25-3l0.75-3.75l-2.125-3.125l-3.5,3.25l-3.5-3.5l-4.25-1.75l-6.25,2.25l-3.25,5.25l-5,1l-2.5,3
l-7.25-0.75l-9.25-2.5l-8.75,4l0.75,4l-2.5,2.25l1.5,3.25l-1.25,3l1.25,5l5.25,1l3.5,4.75l-0.5,6l4.75,0.25l5.75,5.25
L387.25,510.605z"
          />
          <path
            id="양산시"
            data-code="38100"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M430.5,507.605l-1.5,9.5l-12,5l-6.75-5.25l-0.75-4.25l-5.5-4.25l-5.75-0.5
l-1.5-4.75l3-3.75l4,0.25l-1.25-4.25l6.25-2.25l-0.25-6.75l3-5.5l3.5,2.75l6.5,0.75l5,3.5l6.375,8.875l5.625-0.375l2.875,3.75
l-2,3.75l-0.875,3.75l-3,2l-3-2.25L430.5,507.605z"
          />
          <path
            id="김해시"
            data-code="38070"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M388.5,541.605l-7.75-2.75l-0.25-5.25l-2-0.5l-1-5.75l-4-9.25l5-3.25
l1.25,1.75l3.25-5.25l4.25-0.75l9.5-7.5l1.5,4l5.75,1.25l5.5,4.25l0.75,4.25l5.75,4.5l-2.25,5.75l-7,2.75l-2.5,2.5
c0,0-4.5,0.25-5.25,0.25s-2,4.5-2,4.5l0.25,3.5l-3.5-1.75l-1.75,2.25L388.5,541.605z"
          />
          <path
            id="창원시"
            data-code="38110"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M358.875,509.605l-1.625,2.375l0.25,3.375l1.875,1.75v2.75l1.375,4.125
l-4.75-0.75l-4.875,2.25l-2-2l-1.375,3l-2.375,4.5l2.25,3.75l-0.625,2.5l-3.125,0.125l-2.125,3.625l-3.375-2.625l-1.75-3.625
l-3.5,2.125l-1,8.75l-1.5,3.375l4.125,1.5l3.625,3l2.875,0.25l2.25,2.125l2.875-2l-3-2.75l1.375-1.375l4.25,1.5l1.875-0.75
l2.5-1.875l3,3.375l2.75,0.75l-1.375,3.125l3,0.375l-0.875,1.75l5.375-0.875l1-2.75l-1.875-1.5l-0.375-1.75l-2.5-5.5l0.625-2.5
l-2-2.75l-1.625-2.875l2.875-2.75l1.625,3.875l2.125,4v2.25l2.375-1.875l3.25,1.75l2.625-1.75l1.375,4l-1.375,3.25l4,1.625
l4.625-0.25l4.5-2.25l0.375,3.375l4.125-1l2.625-2.25l-0.5-2.875l-4.25-2.75L388,541.48l-7.25-2.625l-0.25-5.25l-2-0.5l-1-5.75
l-3.875-8.75l4.875-3.75l1.25,1.75l3.25-5.25l-4.25,0.25l-6.125-5.125l-4.375-0.375v0.75l-6.75-2.25l-3.5,2L358.875,509.605z"
          />
          <path
            id="함안군"
            data-code="38320"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M322.25,527.773l-2.167-1.5l3.667-3.668l-0.5-3.5l1.5-4.166l1.667-5
l3.166,1.166l3,2.168l3.5-0.168l-0.666-2.5l4-0.166l1.333-4.166l5.25,0.332l4.75-3l6.833,3.5l1.292,2.5l-1.125,2.168l-0.5,3
l1.667,2.666l0.458,2.416l1.375,4.125l-5.167-0.707l-4.166,1.666l-2.292-1.459l-3.375,7.459l1.875,3.791l-0.625,2.5l-3.125,0.125
l-2.125,3.625l-3.375-2.625l-1.625-3.082l-3.625,1.582l-3.375-2.25h-3L322.25,527.773z"
          />
          <path
            id="의령군"
            data-code="38310"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M310.417,525.273l-2.834-4l0.5-5.5l-4-3l-3.333,3l-3.5-2.168v-4.75
l5.75-3.5l5.75-0.5l1.25-5.5l0.5-6.5l4.75-1.75l6.25-4.25l10.25,2.25v-3l2.25,0.25l4.75,3.75l-3.75,4l-0.583,4.168l5.833,3.082
l0.75,4.5l-1.583,4.584l-4,0.166l0.666,2.5l-3.5,0.168l-2.666-2l-3.5-1l-3.167,8.832l0.5,3.5l-3,3.5l-6.5,0.334L310.417,525.273z"
          />
          <path
            id="산청군"
            data-code="38370"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M269.25,526.773l-4.167,5.166h-8.166l-4.334-4.334l-5.667-3.5l0.333-8
l4.333-3.666l6.917-15.584l2.25-4.25l0.667-5.666l1.083-3.084l3.75-5.5l2.5,1.25l1.25,4.25l8.75,3l9,14.584l4.25,0.416l4.917,6.418
l0.333,5.332l-4.333,1.834l-1,3.166l-4.834,1l-4.5,5.5l-1.5,3.334h-5.833l-1.333,3.666l-2-0.332l-1.167-3.668L269.25,526.773z"
          />
          <path
            id="진주시"
            data-code="38030"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M307.417,551.939l-2.834-3.166l-6.333,2.332l-2.167-2.666l-3.333-1.5
l-5.833,2.334l-2.5-6.834l-6.334,0.166l-2.333-3.832l-4-2.5l-0.167-4.334l2.334,0.166l1.333-3.666h5.833l1.5-3.334l4.5-5.5l4.834-1
l1-3.166l4.333-1.834l3.5,2.168l3.333-3l4,3l-0.666,4.832l2.666,4.168l4.167,1.666l5.833-0.166l2.167,1.166l4.5,7.166h3l3.375,2.25
l-1,8.75l-1.542,2.5l-13.5-0.5l-4.333,1.834l-2.5,3.834l3.167,1.832l-3.5,3.334l-4.5-2.834L307.417,551.939z"
          />
          <path
            id="고성군_1_"
            data-code="38340"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M299.917,578.939l4.833-10.334l2.667-0.5l-1.167-5.166l3.667-2.334
v-2.166l3.166-3.334l-2.833-1.832l2.5-3.834l4.333-1.834l13.834,0.334l-0.292,1.041l4.125,1.5l3.625,3l3.375,0.625l1.75,1.75
l-2.583,0.75l-5.167,1.334l-2,3.5l-1.5,4l4-4l2.167-2l2.833-0.166l3-2.334l1.833-0.666l-1.666,3.332l3.666,2.668l-0.166,4
l-5.667,1.5l-3.333-0.668l-0.167,1.334l4,1.5l-6,1.834l-2.333,2.832l-4,1.834l-2.334-2.666l-2.5,2.166l-1.833,5.5l-3-0.334
l-1.833-4.666l-6.5,0.334l-0.334,5.666h-6.5h-2.666l-3.5-1.334L299.917,578.939z"
          />
          <g id="거제시">
            <path
              id="거제시"
              data-code="38090"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M354.083,578.939l4.667,2.666l3.5-2l3.167,2.5l-3.167-5.666l2.5-4.666l3,0.166l3-3.166
l1.833-3.668l0.334-3.5l5.166-0.166v2.5l-3.166,2.666l0.5,4.668l2.833,1.5l0.333,5.5l-2.166,4.832l2,3.168l3.166-2.834
l0.334,3.166l-1.834,2.168l0.5,1.832l-3.166,0.168l-0.834,2.832l3.5,1.5l2,3.5l-2.833-0.166l-2.5-2.334l-5.167,0.168l0.334,3
l-2.667,1.832l0.333,4.5l3.834,1.5l-1.5,2.168l-3-1l-2,0.332l1.5,2.334l-5.834,1.5l-1.666-1l2-3.166l-0.667-1l-1.5-2l0.5-3
l-3.167,0.666l-0.166-2.834l3.333-2.666l1.833-3.334l-2.833-4.166l-3.167,2l-4,1.5l-1.166,1.5l-0.834-2.666l-2.666-2l-0.334-5
l3.667-4.168L354.083,578.939z"
            />
            <path
              id="거제시"
              data-code="38090"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M354.083,570.773l-1.833,1.332l0.167,4c0,0,3.333,1.168,3.333,0.168s0-3,0-3
l-1.167-0.168l-0.5-2.666"
            />
          </g>
          <path
            id="사천시"
            data-code="38060"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M276.583,544.273l-4-0.5c0,0-2.166,1.834-1.666,2s3.666,3.666,3.666,3.666
l-0.5,3.666l1.667,4.5l0.833,5.834v3.334l3.667,3.832l1.5-1.666l4.167,0.334l2-4l-4.5-3.334l2.166-1.834l2.334,2.168l1.166-5.668
l4-3.5l-2.166,6.834l-0.5,4.334l2,1l-2.667,3.332l1.833,1.5l-2.166,4l3.833,4.168l6.167,2.832l0.166-2.166l5.167-10.334l2.667-0.5
l-0.667-5.166l3-3l0.167-1.5l-4.5-2.834l2-3.332l-2.834-3.5l-6.333,2.332l-1.833-2.332l-3.667-1.834l-5.333,2l-3.334-6.334h-6
L276.583,544.273z"
          />
          <path
            id="하동군"
            data-code="38360"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M236.083,516.105l-5,4.5l1.833,3.168l4.833,6.332l-0.667,8.168l8.5,7.666
l0.667,6l5,4l1.333,2.666l2.667,1.168l1.333,5.5l1.834-0.168l-0.667,3.834l-2.333,2.334l1.833,4l5.667,0.832l2.833-1.832
l1.167,1.666l5.833-1.5l2.667-4.666v-4.668l1.333-0.5l-1-7l-1.667-4.5l0.5-3.666l-4.166-4l2.166-1.666l4,0.166l1.5-1.334
l-2.333-3.832l-4-2.5l-0.167-4.334l-0.833-3.834l-1.5-1.332l-4.167,5.166l-8.166-0.5l-4.334-3.834l-5.667-3.5l0.333-8l-9.75-1.25
L236.083,516.105z"
          />
          <g id="남해군">
            <path
              id="남해군"
              data-code="38350"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M276.583,580.939l-1.833-3.5l-4-1.334l-3.833,2.334c0,0,0.666,3.332,0.166,3
c-0.5-0.334-2.833-0.166-2.833-0.166l-0.667,5.332l-2.166,2.5l2.833,6.334l2.167,6.166l-0.167,3.668l-1,1.666l4.5,3.334
l4.167-0.334l0.166-4.834l3.334-2.166l2.833,0.834v6.332l3,3.334l4.333-2l2.834,2.834l2.166-1.334l-1.5-2.5l2.834-3.666
l-0.667-4.668l0.333-4l-1.666-2.832l-3.167-1l-5.167,1.166l-4,2.5l-1.166-3l-2.667-3.334l-1.833-6l2.833-2.832L276.583,580.939z"
            />
            <path
              id="남해군"
              data-code="38350"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M289.75,579.773l-4.5,0.832l-3.667,5.5l1.5,5.834h8l4,1.166l-1.333-3.666l0.833-3.166
l-4-2.834l-1.833,4.334l-0.5-3.668l1.167-2L289.75,579.773z"
            />
          </g>
          <g id="통영시">
            <path
              id="통영시"
              data-code="38050"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M343.917,583.105l-3.5,3.5l-3.667,2.168l-1.167,2.832l2.834,0.834l2.666,0.666l1.834-2.5
l1.833-4l1.667-1.666L343.917,583.105z"
            />
            <path
              id="통영시"
              data-code="38050"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M332.917,591.105l-2.667,1l1,4.334l5.667,6l4.333-5.334l-4.833-1.832L332.917,591.105z"
            />
            <path
              id="통영시"
              data-code="38050"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M348.083,596.939l-2.333,2.334l2,5.166l2.167-2.334L348.083,596.939z"
            />
            <path
              id="통영시"
              data-code="38050"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M345.917,607.939l-1.5,4.834l2.833-2.668L345.917,607.939z"
            />
            <path
              id="통영시"
              data-code="38050"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M343.083,571.773l-2.166,2.832l0.166,4l-1,4.834l-3,4l-2.333-2.5l-2.167,1.834
l-6.5-2.668l0.167-2.5l5.833-0.5l-1.666-4.666l4-1.834l2-3.166l6.333-1.5L343.083,571.773z"
            />
            <path
              id="통영시"
              data-code="38050"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M314.25,589.439h-6.167l3.334,4.334l2.166-2.5L314.25,589.439z"
            />
            <path
              id="통영시"
              data-code="38050"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M317.25,593.439l-4.167,0.834l5.167,5l3-2.168L317.25,593.439z"
            />
            <path
              id="통영시"
              data-code="38050"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M319.083,622.273l-2.333,0.332l0.5,3.5l4.167-0.5L319.083,622.273z"
            />
            <path
              id="통영시"
              data-code="38050"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M309.25,612.105l-1.333,2.334l3.333,2l1-2.166L309.25,612.105z"
            />
          </g>
        </g>
        <g id="부산광역시">
          <path
            id="기장군"
            data-code="21310"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M442.875,534.855l-3.625-0.75l-4.625-2l-0.125-3.75l-3.5-4l-0.875-0.75
v-5.125L429,517.105l1.5-9.5l2.375-0.125l2.625,2.125l3-2l1.25-3.75l4.875,0.75l2.125-0.5l2.75,1.75l0.375,5.25l1.875,2.125
l-0.625,1.75l-2.25-1.125l-1.875,3.25l1.5,1.875l-2,2.875l-1.375,1.25l1.875,1.5l-1.25,2.75l-0.875,2.25l-1.625,0.375
L442.875,534.855z"
          />
          <path
            id="북구"
            data-code="21080"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M412.75,533.98l0.875-5.625l0.125-1.25l2.25-5.75l1,0.75l3.25-1.25
l-0.875,4.125l2.375,4.875l-1.875,3.875l-3.375,1.875L412.75,533.98z"
          />
          <path
            id="금정구"
            data-code="21110"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M428.75,532.73l3.375-4.625l0.25-2l-2.125-2.875l-0.125-4.75L429,517.105
l-8.75,3.75l-0.875,4.125l2.375,4.875l4,1L428.75,532.73z"
          />
          <path
            id="동래구"
            data-code="21060"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M424.125,534.105l3,1.75l2,0.75l-0.375-3.875l-2.625-1.625l-4.25-0.875
l-2,3.5L424.125,534.105z"
          />
          <g id="강서구_1_">
            <path
              id="강서구_1_"
              data-code="21120"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M393.625,552.855l6-0.125l1.125-5.125l1.5-0.875l0.125,5.625l1.75-0.5l1.875-3.75l2-0.75
l0.625-3.25l0.25-6.625l3.875-3.5l0.875-6.5l-6.875,2.375l-2.5,2.5l-5.25,0.25l-2,4.5l0.25,3.5l-3.5-1.75l-1.75,2.25l-4,0.375
l0.875,2.375l4.375,3.375l0.375,2.25l-2.375,1.875L393.625,552.855z"
            />
            <path
              id="강서구_1_"
              data-code="21120"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M392.75,554.73l-4.875,3.25l1.625,1l0.625,3l1.625,2.125l0.75,3.5l1.375-2.125
l-0.5-2.625l1.25-2.625l1-3l-1-1.75L392.75,554.73z"
            />
            <path
              id="강서구_1_"
              data-code="21120"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M398.875,554.23l-3,0.375l1,1.625l2.5-0.375L398.875,554.23z"
            />
          </g>
          <path
            id="사상구"
            data-code="21150"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M410,547.105h3.25l2.75-3.875l-0.125-5.25l0.625-2.375l-3.75-1.625
l-3.875,3.5l-0.25,6.625l-0.625,3.25L410,547.105z"
          />
          <path
            id="사하구"
            data-code="21100"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M413.625,551.73l1.875-0.25l-1.25-2.875l-1-1.5l-5.25,0.25l0.375,7
L410,559.48l2.25-3.125l1.5,2.25v-3l-0.625-2.25L413.625,551.73z"
          />
          <path
            id="연제구"
            data-code="21130"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M420.125,535.605l2.375,1.5l2,1.375l0.125,1.5l1.875-0.25v-2l2.625-1.125
l-2-0.75l-3-1.75l-4.25-0.375L420.125,535.605z"
          />
          <path
            id="해운대구"
            data-code="21090"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M431.625,540.105l-2.5-3.5l-0.375-3.875l3.375-4.625l0.5-1.625
l1.875,1.875l0.25,3.25l4.875,2.625l3.25,0.625l-3.125,2.25l-1.25,3.75h-2.75l-0.875-0.875l-1.5,1L431.625,540.105z"
          />
          <path
            id="부산진구"
            data-code="21050"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M417.875,543.855L420,541.98l2.25,1.25l2.375-3.25l-0.375-1.875
l-4.125-2.5l-0.25-1.875l-3,1.875l-1,2.375l0.125,5.25L417.875,543.855z"
          />
          <path
            id="수영구"
            data-code="21140"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M428.875,542.605l-2.375-2.875l0.375-2l1.875-1.125l2.875,3.5l1.75,0.875
l-3.125,0.5l-0.625,1.625L428.875,542.605z"
          />
          <path
            id="남구_1_"
            data-code="21070"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M429.625,549.23l-2.25-0.25l-0.75-1.25l-2.375,0.875l-0.75-2.125l-1-1.625
l-0.25-1.625l2.375-3.25l1.875-0.25l2.375,2.875l0.75,0.5l1.125,2.125L429.625,549.23z"
          />
          <path
            id="동구_1_"
            data-code="21030"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M421.25,546.855l-3,0.5l-1-1.5l0.625-2L420,541.98l2.25,1.25l0.25,1.625
L421.25,546.855z"
          />
          <path
            id="서구_1_"
            data-code="21020"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M416.875,557.105L415,554.73l0.125-3.25l-0.875-2.875l-0.625-1.625
l2.375-3.75l1.875,0.625l-0.625,2l1,1.5l-1.25,2l1.625,1.375l-1.75,2.125V557.105z"
          />
          <path
            id="중구_2_"
            data-code="21010"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M420.125,548.855v-1.75l-1.875,0.25l-1.25,2l1.625,1.375L420.125,548.855z
"
          />
          <path
            id="영도구"
            data-code="21040"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M423.75,550.355l-3.625,0.25l-0.5,2.25l4.625,3.75l1.625-0.875l-1-3
l-0.125-1.625L423.75,550.355z"
          />
        </g>
        <g id="전라북도">
          <path
            id="무주군"
            data-code="35330"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M230.083,429.605l1.333-3.332l-3.667-4.5v-3.001l-3-6.5l1-8.167l10,5.833
l1.667-4.5l-1.667-4.166l2.167-2.167l3.667,4.167l2.667-2.667l3,4.833l5.667,1.167l1.166,2.333l10.167-3.333l3.833,1.833l0.5,3.334
l5.167,7.333l-4.167,3l0.167,3.75l-3,0.75l-2,4.5l-7,4.75l-6.5,0.75l-1.75,5.75l-3.083,1.75l-4.5-3.332l-4.833-0.5l-0.667-3.334
l-2.333-5.5L230.083,429.605z"
          />
          <path
            id="진안군"
            data-code="35320"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M191.75,445.273l4.833-8.668l2.5-7.666v-4.166l2-0.5l0.5-8.668
l-1.667-1.667l3-2.5l1.667-6.833l3.667,4.167l1.167,0.333l2.167-2.333l3.833,5.833l9.5-0.667l2.833,6.834v2.667l3.667,4.5
l-1.333,3.666l4,0.834l2.667,5.834l-2.333,0.166l-1.333,4.5l-1.833,1.5l-6-1.5l-3,2l0.333,7l-4.833,5l-1.167,6.5l0.833,3.834
l-0.5,4l-5,0.332l-0.833-2.166l-3-1.5l-4.5-2.834l-5.333-0.332c0,0-3.833-0.168-3.833-1.334s0.167-7.334,0.167-7.334l-3-4.666
L191.75,445.273z"
          />
          <g id="전주시">
            <path
              id="전주시"
              data-code="35010"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M164.917,438.773l-0.167-4.168l-3.667-1.5l-1.667-1.5l-2.667-3.832l2.333-1l3.167,0.666
l4-0.834h8l3.667,7.168l4.667-0.5l2.167,5.164l1.667,0.668l-1.167,2l-6-0.334l-2.833-3.334l-5.167-0.5l-1.667-1l-1.667,1
L164.917,438.773z"
            />
            <path
              id="전주시"
              data-code="35010"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M165.917,445.105l-0.5-3.5l0.333-1.5l-0.833-1.332l4.667-2.834l1.667,1l5.167,0.5
l2.833,3.334l6,0.332l-4.5,4.668l-3.667-0.168l-2.5,3.5l-5.833,1.168l-0.667,2.332l-3.667-3.332l0.667-2.168L165.917,445.105z"
            />
          </g>
          <g id="완주군">
            <path
              id="완주군"
              data-code="35310"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M179.417,452.773l-5.5,4.666l-0.167,6.166l-0.167,3.168l-5.667,6l-0.167-9.668
l-2.667-2.166l2.5-7.834l1.167-2.832l5.833-1.168l2.5-3.5l3.667,0.168l3.167-3.168l2.5-3.5l-1.667-0.666l-2.167-5.166l-4.667,0.5
l-3.667-7.168h-8l-4,0.834l-0.333-3.5l4-0.166l3.333-4.835l0.833-7.333l3.333-2.5l-0.833-4l1-3l-1-2.167l2.667-2.833h7
l2.167-1.833L189,396.48l0.75-4.125l4,1.583l4.667-3.666l3.333,2.666l2.167,9.667l1.333,2.167l-2.333,6.666l-2.667,2.834
l1.333,1.333l-0.5,8.668l-1.5,0.166l-0.5,4.5l-2.5,7.666l-4.833,8.668l-3.833,4.164l0.667,2.168l0.833,2.666l0.5,5l-1.5,0.166
l-2.833-2.666l-2.333-0.834l-1.667-2.832L179.417,452.773z"
            />
            <path
              id="완주군"
              data-code="35310"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M156.75,436.105l2.167-4.832l2.167,1.832l3.667,1.5l0.333,3.5l0.667,2l-0.333,1.5
l0.5,3.5l-2.5-1l-4.333-2.332L156.75,436.105z"
            />
          </g>
          <path
            id="익산시"
            data-code="35030"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M153.583,426.105l-3-1.166h-11.667c0,0,5-8.334,5-8.834
s2.167-6.667,2.167-6.667l-4.833-4.166l-1.167-4.167l1.167-2.5l0.25-6.375l2.25-3.875l4.75,0.125l3.125-1.125l2.792,1.083
l5.208,0.792l3.292-1.125l3.333,9.25l6.333,2.583l1,2.167l-1,3l0.833,4l-3.333,2.5l-1,7.667l-3.333,4.167l-3.833,0.5l0.167,3
l-3-0.166l-2.333,1L153.583,426.105z"
          />
          <path
            id="장수군"
            data-code="35340"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M203.083,480.273l2.333-3l0.5-6l6-1.668l5-0.332l0.5-4l-0.833-3.834
l1.167-6.5l4.833-5l-0.333-7l3-2l6,1.5l1.833-1.5l1.333-4.5l2.333-0.166l0.333,2.666l4.833,0.834l5,3.666L243,446.855l-1.5,9.75
l-2.417,2.168l-2.583,5.832l2,5.75l-2.417,2.084L235,476.105l-2.583,2.334l-2,3.334l0.333,6.666l-2.667,4.166l-4.667-0.332v-4
l-6-5.5l-3.5-0.334l-4.667-2.166l-1,1.832L203.083,480.273z"
          />
          <path
            id="남원시"
            data-code="35050"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M181.25,513.73l2.875-4l2.125-4.875l3.375-0.375l0.5-3.125l3.75-0.75
l3.5-5.125l-0.75-3.25l0.75-2.375h5.75l-0.875-3.125l-1.25-1.875l-0.375-3.25l2.75-1l4.875,1.5l1-1.832l4.375,2.332l3.792,0.168
L223,488.23l0.417,4.043l4.667,0.332l2.667-4.166v-6.959l1.667-3.041l3.833,4.416l3.75,6.75l-1.75,3l1,3.625l3.375,1.625
l-1.75,5.125L236,507.355l-0.625,3.875l2.125,3.625l-6.25,5.25l-4.25-4.25l-7-4.5l-6.875-0.25l-6.375,8.625l-4.5-2.25l-5.25,3.375
l-4.125-2.5l-3.5-0.25l-3.125-3.625l-2.25,3.875l-3.125-1.125L181.25,513.73z"
          />
          <path
            id="임실군"
            data-code="35350"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M178.875,495.23l-3.75-0.625l-1.875-5.5l-4.875-1.5v-2.75l2.375-2.125
l-2.375-2.125l-0.125-4.75l1.625-1.625l-1.5-2l5.208-5.457l0.042-9.168l5.792-4.832l2.167,0.332l1.667,2.834l2,1.041l3.167,2.459
l1.208-0.459l-0.208-4.707l-1.5-4.834l3.458-3.584l0.208,3.584l3,4.666l-0.083,7.75l2.625,0.875l6.458,0.375l7.5,4.334l0.833,2.166
l-5.667,1.375l-0.75,6l-2.5,3.625l-2.375,1l0.375,3.25l1.25,1.875l0.875,3.125h-5.75l-0.75,2.375l-8.625,0.5l-3.125-0.875
l-1.5-1.625l-2.75,2l-0.75,2.875L178.875,495.23z"
          />
          <path
            id="순창군"
            data-code="35360"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M138,492.605l10.25-0.375l0.375-4.25l-0.25-2.625l5.375-4.625l2.5,2.125
l2.875-0.875l4.875,5.625h4.375l4.875,1.5l2.25,5.5l4.375,0.5l0.75-2.875l2.75-2l1.5,1.625L188,492.73l8.625-0.5l0.75,3.25
l-3.875,5.25l-3.375,0.625l-0.5,3.125l-3.375,0.375l-1.875,4.5l-3.125,4.375l-8.75,7l-8.125-2.5l-1.125-3.125l2.375-0.625
l-0.75-6.375l-3-0.625l-1-2.375l3-1.25v-2.875l-1.5-1.25l-0.875-4.125l-4.25,0.5l-1.75,4.25l-1.75,1.375l-0.25,2.875l-4.125,0.75
l-3.75-3.875l-0.875-3.375l-3.5-2.125l-3.5-1.75L138,492.605z"
          />
          <path
            id="정읍시"
            data-code="35040"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M142,449.105l-0.75,1.375l0.375,0.875L139,453.48l-6,1.625l2.875,2.75
l-1.125,2.375l-7.875,0.375l-5,3.5l-1.5,6.25l1.25,7.75l2,0.375l2.5-0.875v3.875l0.25,3.25l1.625,0.75l-1.125,3.375l1,5.375
l5-0.125l1.5-3.125l3.625,1.625l10.25-0.375l0.375-4.25l-0.375-2.25l5.5-5l2.5,2.125l2.875-0.875l4.875,5.625h4.375v-2.75
l2.375-2.125l-2.375-2.125l-0.125-4.75l1.625-1.625l-1.875-2.5l-0.25-8.625l-2.5-2.5l-1.875,2.25l-4.25-0.5L156,459.23l-0.75-2.75
l-3-2.375l-3.625,0.75l-2.375-3.75L142,449.105z"
          />
          <path
            id="김제시"
            data-code="35060"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M127.875,441.73l-10-5.75l3.75-4.125l3-0.75l4.25,1.125l2.25-4.25l5.875-3
h2.5l11.083-0.041l3,1.166l3.167,1.668l2.167,3.5l-2.167,4.832l2.333,5.668l7.167,3.207l-1.625,3.875l3,3.625l-2.375,7.625
l-1.875,2.75l-4.25-0.5L156,459.23l-0.75-2.75l-3.375-2.25l-3.25,0.625l-2.375-3.75l-4.25-2l-0.75,1.375l0.375,0.875L139,453.48
l-6,1.625l-4.625-6.5L127,443.98l1.5-1L127.875,441.73z"
          />
          <path
            id="부안군"
            data-code="35380"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M114.875,478.73l-4.75-5.5l-10,0.875l-5,2.5l-7.625-6.25l0.25-4.25
l10.5-6.5l3.5-0.5l6.75-7.5l0.75-9.5l10.5,1l6.875,2.625l1.75,2.375l4.625,7l3.25,3l-1.5,2.125l-7.875,0.375L122,463.73
l-1.25,6.125l0.875,8.25L114.875,478.73z"
          />
          <path
            id="고창군"
            data-code="35370"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M84.125,499.23l7.375-13.125l7.5-3.25l6.25-0.125l4.125-3.5l5.25,1.25
l0.25-1.75l6.75-0.625l1.5,0.5l3-1l0.625,6.875l1.25,1l-0.875,3l0.75,5.25L124,497.855l1.375,2.5l-1,4.125
c0,0-2.25,1.125-2.375,1.625s-0.125,4.25-0.125,4.25l-7.75,2.625l-3.625,3.875l-4.375-1.375l-2.875,0.375l0.125,4
c0,0-7.375-1.375-7.5-1.75s-0.5-5.875-0.5-5.875L93,511.98l-2.375-5.125l1-6.625L84.125,499.23z"
          />
          <g id="군산시">
            <path
              id="군산시"
              data-code="35020"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M127.75,427.773l5-3.168l6.167,0.334l5-8.834l2.167-6.667l-4.833-4.166l-1.167-4.167
L134,405.73l-6.625,0.25l-3.625,4l-3.5,0.458l-3.167-0.333l-1.167,2.333l-13.333,1.667l-1.667,1.833l-1.833-1l0.5,2.834l5.333,2
l2.833,1.667v6.834l7,0.5l2.333-0.834l3.5,0.334l1.167,1.166l2-0.666L127.75,427.773z"
            />
            <path
              id="군산시"
              data-code="35020"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M80.583,437.105l-0.5,5l3-1.5L80.583,437.105z"
            />
          </g>
        </g>
        <g id="광주광역시">
          <path
            id="광산구"
            data-code="24050"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M136.833,531.355l-5.083,2.25l-6.167-2.916l-0.25-2.666h-2.25l-1.75,6.166
l-4.417,0.584l-3.917,3.75l1.5,2.75l-2.25,1.75c0,0-0.25,5.58,0,5.916c0.25,0.334,2.917,2.166,2.917,2.166l9.083-0.166l3.083,3.834
l-0.583,2.25l5.5-4.25v-2.334l2.25-3.084l2.5-7.5l1.833-1.082l-0.917-1.668l-0.25-5L136.833,531.355z"
          />
          <path
            id="북구_3_"
            data-code="24040"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M145.583,526.605l-3.333,2l-2.833,0.084l-2.583,2.666l0.75,1l0.333,4.75
l0.917,1.668l-1.417,0.75l-0.667,1.916l4.5-0.084l2,1.25l1.5,1.084l1.5-0.084l1.917-1.082l2.25,1.332l1.583,2.084l2.417,1.334
l2.417,2.832l2-3.166l-0.75-1.916v-2.168l1.5-1.416l-2.25-2.916l-3.167,0.082l-2-1.916l-1.75-4.416l-2.667-4L145.583,526.605z"
          />
          <path
            id="동구_5_"
            data-code="24010"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M147.583,547.689l-0.833-2.834l-0.167-1.332l1.75-0.75l2.083,1.082
l1.417,2.334l2.833,1.084l2.167,2.832l-2,3l-3.583,1.668l-1,1.5l-3.833-3.168l0.083-3.666L147.583,547.689z"
          />
          <path
            id="남구_4_"
            data-code="24030"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M134.667,552.773l2.583-2l4.25-1.168l1.583-0.916L143,545.855l1.75-2.166
l1.5-0.084l0.583,1.5l0.75,2.584l-1.083,1.75l-0.083,3.666l-3.917,1.75l-5.25,1.334l-3.25,2.416l-5.5-0.25l-1.75-1.332l5.5-4.25
H134.667z"
          />
          <path
            id="서구_4_"
            data-code="24020"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M133.5,549.023l1.167-1.918l2.083-5.666h4.167l3.833,2.25l-1.417,1.916
l-0.167,2.834l-1.667,1.166l-4.25,1.168l-2.417,2h-2.583v-2.334L133.5,549.023z"
          />
        </g>
        <g id="전라남도">
          <path
            id="구례군"
            data-code="36330"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M210.5,538.355l-5.75-0.5v-5l5.25-2.5l-3.5-6l0.25-4.625l6.375-8.625
l6.875,0.25l7,4.5l4.25,4.25l2.25,4l4.25,6l-0.75,8.75l-1.5,3l-0.75,6l-3,1.25l-4.5,3l-3.25-4.5h-1.75l-4.5-4.25l-3.75-3
L210.5,538.355z"
          />
          <path
            id="곡성군"
            data-code="36320"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M182.25,546.605l-0.333-6.166l0.333-3.666l-2-3.168l-3,3.334l-6,0.166
l-4-8.332l1.5-2l0.167-6.5l3.583,0.457l8.583-6.625l-0.208,3.125l3.125,1.125l2.25-3.875l3.125,3.625l3.875,0.334l4.167,2.666
l4.833-3.625l4,2.293l0.333,4l3.417,6.582l-5.25,2.5v5l4.833,0.418l-0.667,6.832l-2.833,3.168l-1.167,4l-4.167,0.166l-1.333,4
l-6.833-2.334l-4.333-3.5l-3.667-2.5L182.25,546.605z"
          />
          <path
            id="담양군"
            data-code="36310"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M161.417,546.273l6,3.166l1.333-3.166l-2.5-6.5h2.667l2.333-2.668
l-4.333-8.666l1.833-1.666v-6.834l-4.375-1.709l-1.125-3.125l2.375-0.625l-0.75-6.375l-3-0.625l-1-2.375l2.542-1.334l0.458-2.791
l-1.5-1.25l-1.292-3.959l-3.833,0.334l-1.75,4.25l-1.75,1.375l-0.25,2.875l-4.125,0.75l-1.958,1.916l-2.833,1.834l-3.5,4.834
l1.833,2l-0.833,1.832l-2.333,0.5l-0.167,4.5l2.667,5.834l3.333-2l1.833,1l3,4.668l1.167,3.998l2,2l3.75,0.252l2.25,2.916
l-1.5,1.416l0.333,2.75l0.417,1.334L161.417,546.273z"
          />
          <path
            id="장성군"
            data-code="36450"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M108.583,532.771c0,0-4.5-1.998-4.667-2.666
c-0.167-0.666-0.167-3.666-0.167-3.666l4-3.668l0.667-2.5l1.833-0.5l0.25-2.916l3.625-3.875l7.75-2.625l0.042-4.584l2.458-1.291
l1-4.125l-0.958-2.75l3.458-3.875l5.042,0.041l1.833-2.834l3.25,1.668l-0.25,1.625l7,3.875l0.875,3.375l4.458,3.457l-3.167,3.168
l-1.667,0.666l-3.5,4.666l1.167,2.5l-0.833,1.834l-1.833-0.166l-0.667,5.166l2.667,5.834l-2.833,0.082l-2.583,2.668l-4.917,1.916
l-6.333-2.584l-0.5-2.25h-2l-1.75,5.75l-4.417,0.584l-3.917,3.75l-2.25-3.584L108.583,532.771z"
          />
          <path
            id="영광군"
            data-code="36440"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M69.917,539.439l7.5-4.334l-2.667,3.5l1,3.334l5.333-2.5l2.333,0.5
l5.833-1.5l5.833,0.332l7-1l1.833-7.666l-0.167-3.166l4-4.168l0.667-2.5l1.833-0.5l0.25-2.916l-4.375-1.375l-2.875,0.375l0.125,4
l-7.5-1.75l-0.5-5.875L93,511.98l-2.375-5.125l1-6.625l-7.042-0.791l-3.333,3.5v4.5l5.833,5.5l-6-2.668l-2,1.168l-1.5,5
l-0.333,4.666l-6,1.334l-0.333,5.332l-2.667,0.5l-0.667,5.834l0.5,1.666l-0.5,2.5L69.917,539.439z"
          />
          <path
            id="함평군"
            data-code="36430"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M89.75,563.939l-1.5-3.666l1.167-2.834l-4-2.834l2.167-2.666l-5.833-1.334
l-1.5-4.332l-4.5-4.334l5.333-2.5l2.333,0.5l5.833-1.5l5,0.334l7.833-1.002l1.833-7.666l4.667,2.666l1.833,1.668l4.083,6.834
l-2.75,1.832l0.5,5.834l-0.333,2.666l-5.167,1l-2.167,3.834l-0.167,7.5l0.833,3.666l-2.333,3.5l-2.667-1.5l-3,2l-5.167-2.832
l-0.167-4L89.75,563.939z"
          />
          <path
            id="순천시"
            data-code="36030"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M180.75,570.939l-0.167-6.5l0.5-7.334l-0.833-6l2-4.5l6,4l4.333,3.5
l6.833,2.334l1.333-4l4.167-0.166l1.167-4l2.833-3.168l0.667-6.832l0.917,0.082l4.083,2.25l7.667,7H224l3.25,4.5l-2.333,4.834
l2.583,4.666l1.25,5.5l-0.5,7.75l3.167,1.918l1,4.332l-0.833,2.668l-4.333,1.166l0.333,5.166l-0.833,2.834l-3.333-4l-0.5-2.666
l-2.833,1.166l0.167,4.666l-8,1l-2.5,1.168l-5.167-5l-3.833-0.334l-5.833-5.5l-4.167,1.666l-4.5-1.332l0.5-5.168l-2.5-5.666
L180.75,570.939z"
          />
          <g id="광양시">
            <path
              id="광양시"
              data-code="36060"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M228.25,574.855l0.5-7.75l-1.25-5.5l-3-4.5l2.75-5l4.5-3l3-1.25l0.75-6l1.5-3
l8.583,7.084l0.667,6l5,4l1.333,2.666l2.667,2l1.333,4.668l1.834-0.168l-0.667,3.834l-2.333,2.334l-3.167,3.082l-2,0.75l-6.5,7.5
H238l-2.75-4.5l-3.5,2.75l-0.75-4L228.25,574.855z"
            />
            <path
              id="광양시"
              data-code="36060"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M251.917,578.439l-2.334,3.332l2.5,1.334l3.5-3L251.917,578.439z"
            />
          </g>
          <g id="여수시">
            <path
              id="여수시"
              data-code="36020"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M239.083,625.605l-3.333-2.5h-2.5v2.334l-3.333-3.834l-1.5-5.666l1.5-1.334l-1.167-3.332
l5.667-4.668l-4.333-4l-0.5-4.666l-4.667-0.334l1.833-4.666l0.833-2.834l-0.333-5.166l4.333-1.166l3.333,4.5l4.833,4l0.833,3.5
l3.667-3.334l3.5-3.666l4.167,0.332l2,1.5l1.666-1.832l2,2.166l-1,4l-0.333,5.666l-2.167,3.334l0.5,4.334l-2.333,3.166
l-5.333-0.666l-4.167-3.668l-2.333,4l-3.833,2l1,3.168l0.333,2.166l1.833,1.834L239.083,625.605z"
            />
            <path
              id="여수시"
              data-code="36020"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M260.75,632.439l-4.667-0.5l-5.666-5l0.166-3.666l3.5-6l-0.166-5l1-1.5l3,2l-0.667,5.332
l3.167,3l-0.834,7.168c0,0,1.834,0.832,1.834,1.332S260.75,632.439,260.75,632.439z"
            />
            <path
              id="여수시"
              data-code="36020"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M251.417,638.273l-3.5,3.166l3.5,0.834l2.5,3l3.833,2.666l1.5-1.666l-2.667-2.334
l0.667-1.166l-2.167-3.668L251.417,638.273z"
            />
            <path
              id="여수시"
              data-code="36020"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M241.583,632.273l-2,2.166l1.167,3.334l3.833-0.668v-2.166L241.583,632.273z"
            />
          </g>
          <path
            id="화순군"
            data-code="36370"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M134.417,588.773l4.833-3.5l1.833-9.834l2.833-5.166l-3-5l2.333-6.668
l0.333-4l2.833-1l3.833,2.668l1-1.5l3.583-1.668l4-6.166l2.583-0.666l6,3.166l1.333-3.166l-2.5-6.5h2.667l2.333-2.668l6-0.166
l3-3.334l2,3.168l-0.333,3.666l0.333,6.166l-2,4.5l0.833,6l-0.5,6.5l0.167,7.334l-5.333,2.166l-7.333,6.834v6.334l-1.667,3.5
l-1.833,3.332l-10-0.166l-1.5-1.666l-4.833,3.666l-3.833-3.5l-1.5-4.834l-3.667,2.168H134.417z"
          />
          <path
            id="나주시"
            data-code="36040"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M93.583,578.939l3.333,5.334l2.833,4.832l1.833-5.5l2.5-3.332l3.5,0.332
l1.667,4.168l3.667,0.5l1.833,1l0.167-5.668l-1.167-3.332h3.333l3.833,5.166l0.333,3.834l4.5-1.834l4.333,2.666l4.333,1.668
l4.833-3.5l1.833-9.834l2.833-5.166l-3-5l2.333-6.668v-3.5l-6,1.084l-3.25,2.416l-5.083,0.168l-2.167-1.75l0.5-1.75l-3-4.334
l-8.333,0.166l-3.167-1.666l-0.833,2.166l-5.167,1l-2.167,3.834l-0.167,6.666l0.833,4l-2.333,4l-2.667-1.5l-3,2l-0.5,2.5
l-0.167,2.834l1.667,3.5l-2.5-1.666L93.583,578.939z"
          />
          <g id="무안군">
            <path
              id="무안군"
              data-code="36420"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M72.917,566.939l2.167,2.166l1.333-0.666l0.833,3.334l1.333,2.832l0.5,6.334l1.5,1.334
l-2,3.166l1.5,3.834l0.333,2l3,1.166l2,3.166l1.5,0.834l0.667,4l3,1.834l2.333,1.832l2.5-4.166l3.333-4.666l1-6.168l-5.667-10
l1.667-0.332l2.5,1.666l-1.833-3.166l0.833-5.668l-5.167-2.832l-0.167-4l-2.667-1.5l-1-3l1.167-2.834l-2.167-1.334l-1.833,2.168
l-2.833,2l-0.833,4.166l-1.667-2l-1.333-1.334l-1,2.834l-1.833,1.166L72.917,566.939z"
            />
            <path
              id="무안군"
              data-code="36420"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M72.917,543.105l-4,3l-4.5-1.332c0,0-4.333,0.5-4.333,1s-0.333,4.666-0.333,4.666
l-0.333,1.834l-6.667-1.668l-3.167,2l1.5,3.834l5.667,1.334l0.5,4l0.833,3.332l5-2.666l-0.833-3.666l2.5-0.168l1.667,2.834l2.5-3
v-4.5h1.667l1.5-4l1-3.334L72.917,543.105z"
            />
            <path
              id="무안군"
              data-code="36420"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M74.583,554.273l-2.5,0.832l-1.333,3.834l3,2.666l3.333-1.666l3.167-0.334v-3.666
l-3.167-2.166l-1,2.832L74.583,554.273z"
            />
            <path
              id="무안군"
              data-code="36420"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M72.083,571.439c-0.5,0-2.833-0.666-2.833-0.666l-2-0.168l-1,3.168l5.167,5.832
l0.833,1.834c0,0,3-3.166,3-3.666s0-4.334,0-4.334l-1-2.834L72.083,571.439z"
            />
          </g>
          <path
            id="목포시"
            data-code="36010"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M78.75,593.273l-2.333,2.666l-2.167,3.166l3.333,3.168l5.167-1
l2.333-0.168l2.667-0.332l-0.833-4.334l-1.5-0.834l-2-3.166l-2.5-1.166L78.75,593.273z"
          />
          <path
            id="영암군"
            data-code="36410"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M77.083,609.273l5.167-0.668l3.667,1.5l5.667-0.832l6.5,7.832
l12.167,1.334l3.833-2.5l1.333-3.834l-2-3l6.333-4l3.5,0.834l4.667-5h4l0.167-6.166l5.333-2.668l1.833-3.332h-4.333l-4.833-1.668
l-4.333-2.666l-4.333,1.334l-0.167-3.834l-4.167-4.666h-3.333l1.167,3.332l-0.167,5.334l-1.833-0.666l-3.667-0.5l-1.667-4.168
l-3.5-0.332l-2.167,2.666l-2.167,6.166l-1,6.168l-5.833,8.832l-5.167-3.332l-1,1.666l-4.833,0.834l-4.667,0.666l0.167,2.166
l-2.333,2L77.083,609.273z"
          />
          <path
            id="보성군"
            data-code="36360"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M162.917,623.605l5-4.166l4.5-4.166l2.667,1.332l5.833-1.332l3.833-7.5
l3.167-4.168l1.667-2.5l6.333-3.666h2.5l2.167-1.666l7.333,4.832l1.667-3.166l-5.333-3.334l5.5,0.168l-5.167-5h-3.667l-6-5.5
l-4.167,1.332l-4.667-1.666l0.667-4.834l-3-6l-3-1.666l-4.667,2l-8,7v6.334l-3.5,6.832l-2.833-0.166l-2.667,7.5l1.667,2.834
l-1.5,2.332v4.334l-5,4.666l0.667,2.834l6,0.5L162.917,623.605z"
          />
          <g id="고흥군">
            <path
              id="고흥군"
              data-code="36350"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M171.917,639.939l0.833-6.666l4.5-3l3.5-6l3-1.5l-1.667,4l-1.167,5.332l4.5,0.334
l3.333-5.166l-1.667-4.834v-6.166l4.333-3.668l2,1.334l0.5,5.834l5.667-0.334l0.667-5.334l-0.333-4.332l-1.167-3.334l-4,2.5
l-4,0.166l-2.333-6.166l1.167-1.834l6.333-3.666h2.5l2.167-1.666l7.333,4.832l-0.5,1.834l1.833,1.5l-0.333,2.834l-3.167,2
l1.5,3.666l3.833,2.5l2,2.5l0.333,1.834l6.833,2.5l-1.833,2l4,3.166l-1.5,6.334l-6.167,0.666c0,0-5.333-0.832-5.333-0.166
c0,0.664,0.167,3.666,0.167,3.666l3.667,0.334l-0.667,6.666l-4.5,1.666l-0.167,3l-6.333-0.332l-2.5,3.5l-5-2.5l-2.333-2.668l2-5
l-5.333-0.5l-5.167-0.5l-4.667,1.834l-6-0.666L171.917,639.939z"
            />
            <path
              id="고흥군"
              data-code="36350"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M183.417,647.773h-5.333l-4.167,1.166c0,0-2.833-1.166-3-0.166s-0.5,5.332-0.5,5.332
l4.667,3.834l8-0.5l2-3.166l1-4L183.417,647.773z"
            />
            <path
              id="고흥군"
              data-code="36350"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M219.083,644.105l-3.5,1.168l-0.333,3l5-0.668l1.833-1.332L219.083,644.105z"
            />
            <path
              id="고흥군"
              data-code="36350"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M222.75,651.439l-5-0.666l-1.5,2l6.5,4.5l1.167,2.166l2.667-4L222.75,651.439z"
            />
          </g>
          <path
            id="장흥군"
            data-code="36380"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M137.583,628.105l3.5-0.5l-1-6.666l-1.833-3.334v-5.166l-4.167-5.668
l-2.167-5.832l0.167-6.166l5.333-2.668l1.833-3.332l3.667-2.168l1.5,4.834l3.833,3.5l4.833-3.666l1.5,1.666h7.167l-2.667,7.5
l1.667,2.834l-1.5,2.332v4.334l-4.667,4.166l0.333,3.334l6,0.5l2,5.666l-2.833,3.5l-6-0.5l-0.167,1l3.167,1.834l-1.167,3.332
l-0.667,1.834l2,0.834l-1.333,4l-4,2l2.167,2.832l-0.833,3.5l2.167,2.334l-4.333,2.166l-2.5-2.166v3.666l-7.667-0.832l2,3.832
l-6.5-2.166l0.5-10l5-5.666l-0.833-5L137.583,628.105z"
          />
          <path
            id="강진군"
            data-code="36390"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M122.083,652.605l2.333-1.832l-0.833-1.834l2.167-2
c0,0,1.167-7.832,1.167-9.166c0-1.336,0.667-11.168,0.667-11.168l2-1.5l-1.333,2.834l0.833,5.334l1.667,2.5v13l1.833,2l3.833,3.832
l0.5-10l5-5.666l-0.833-5l-3.5-5.834l3.5-0.5l-1-6.666l-1.833-3.334v-5.166l-4.167-5.668l-2.167-5.498h-4.333l-4.333,5l-3.5-1.168
l-6.333,4l2,3l-1.333,3.834l2.667,1.5l1.5,3l-0.667,3.834l0.833,1.5l-3.167,5.666l2.167,5l-2.5,3.834l1.5,1.666l0.333,8.834
l3.667,0.332L122.083,652.605z"
          />
          <path
            id="해남군"
            data-code="36400"
            fill="#B9B9B9"
            stroke="#FFFFFF"
            d="M71.083,608.939l-5.167-3.334l-2.167,6l-3.167,2.668v9.166l3.167,1.666
v3.334l3.667,4.166l0.167,1.5l9.333,2.668l0.833,3.332l4.5-2.5l4.167,4.168l1,3.5l0.5,7.166l-1.333,1.666l3.833,4.168l3.667-2.668
l-0.833,4.334c0,0-2.833,1.334-3,2.166c-0.167,0.834-1.667,7-1.667,7l4.333-0.332l0.833,4.166l0.667,5.334l4.833-4.168l6,0.834
l-0.167-6.334l2.333-1.832l-1.667-3.668l5.167-3l6.167-3.832l3.667-1.168l1.333-2.5l-1.333-1l-4-0.832l-0.333-8.834l-1.5-1.666
l2.5-3.834l-2.167-5l3.333-6l-1-1.166l0.667-3.834l-1.5-3l-2.667-1.5l-3.833,2.5l-10.167-0.834l0.333,3.5l7,4l-5.167,0.834
l-3.667-3l-3.167-0.5l-0.167,3.834l-5.833-2.668l-5.5-4l2.667-4.5l-2.667-0.332l-1,1.666l-3.167-1.666l-1.667,0.332l0.167,3
l-0.167,4.668l3.667-0.834l2,2.834l4.833,3.5l-1.333,3.832l-7-3.666l-4.167-1l-1.833-7.166l-2.167-4.668l0.833-3.166l-1.5-1.166
L71.083,608.939z"
          />
          <g id="진도군">
            <path
              id="진도군"
              data-code="36470"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M60.083,632.273l-1.667,3.5l2.833,1.166l0.667,3.666c0,0-5.333,0.5-5.833,0.5
s-0.667,2.668-0.667,2.668l-1.333,2.332l-2.333-1.666l-1.167,4l-6.667,4.5l-4.167,4.334l3,5.832l3.333,1.5l-0.333,2.334
l6.667,0.834l6.833-2.5l6-4.168l4.5-2.5l-0.167,1.668l4.667-5.5l-0.5-4.668l3.5-2l-1-3.832l-6.5-5.668l-3-1l-0.167-1.832
L60.083,632.273z"
            />
            <path
              id="진도군"
              data-code="36470"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M33.25,675.273l-0.833-1.168l-3.167,4.668l4,2l5.667-0.168l-0.167-4.5l-2.667-0.166
L33.25,675.273z"
            />
          </g>
          <g id="완도군">
            <path
              id="완도군"
              data-code="36460"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M111.25,663.105l5.125-0.625l5.125,2.5l0.75,4.75l4.375,5.25l0.875,4l-4.375-0.25
l-5.375-0.25l-3.75-4.625l-2.75-1.125V663.105z"
            />
            <path
              id="완도군"
              data-code="36460"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M134.75,655.855l-3.375,1l-4.875,3.25l-1.25,3.5l1.75,1.375l2.5,1.125l4.5-2.25
l4.625-2.125l-3.375-1.875l1.25-1L134.75,655.855z"
            />
            <path
              id="완도군"
              data-code="36460"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M135,668.98l-1.75,1.625l-3.75-0.625l-1.25,3.25l5.125,1.625l2.25,0.25l1.125,2.75
l3.125-2.25l2.5-0.625l0.375-2.25l-2.25-1.625l-1.125-1.375h-3.25L135,668.98z"
            />
            <path
              id="완도군"
              data-code="36460"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M141.5,691.48l-2.625,1.625l-1,4.875l2.875,1.25l7.25-0.125l-0.5-3.125l-2.125-1
l-0.625-3.25L141.5,691.48z"
            />
            <path
              id="완도군"
              data-code="36460"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M99.125,688.73l-0.75,4l1.875,3.5c0,0,6.5,0.125,6.5-0.375s-0.625-2.25-0.625-2.25
l1.25-1.5l-2-2.625l-3.125-0.375L99.125,688.73z"
            />
            <path
              id="완도군"
              data-code="36460"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M99,697.355l-3.875-1.25l-2.25,2.5L93,702.98l3.75,1.5l3.25-2.625l3.125-3L99,697.355z"
            />
            <path
              id="완도군"
              data-code="36460"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M111.875,693.98l-1.75,2.5l2.75,0.375l2.25-0.75l-1.125-1L111.875,693.98z"
            />
            <path
              id="완도군"
              data-code="36460"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M110.125,699.855l-0.75,3.625l2.125,2.75l1.375-2.5l0.375-3.5l-1.875-0.5
L110.125,699.855z"
            />
            <path
              id="완도군"
              data-code="36460"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M160.875,664.98c0,0-2.75-0.375-2.75,0.5s0.875,4.125,0.875,4.125s2.75,2.75,2.875,3.25
s0,4.25,0.5,3.5s4.125-4.125,4.125-4.125l3.75-1.625c0,0-3.375-4-3.75-4s-2.5,2.25-2.5,2.25L160.875,664.98z"
            />
          </g>
          <g id="신안군">
            <path
              id="신안군"
              data-code="36480"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M37.083,577.273l-1.167,2.832l-4.333-0.166l-3.667,2.5l-2.833,4.334l-2.333,0.5l9,2l4,2
l4.333-3.834l1.167-4l-2.5-2.666L37.083,577.273z"
            />
            <path
              id="신안군"
              data-code="36480"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M25.75,598.273l-5.5,4l-5.167-0.334l-1.333,8l3,2.166l3.667-1.666v-3.166l5-1.5l2-6
L25.75,598.273z"
            />
            <path
              id="신안군"
              data-code="36480"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M22.25,609.939l-1.167,2l-4.833,1.334l1.333,3.832l5.667,3.5l2.833-2.832l3-2.5
l-0.5-4.668l-2.833,1.834L22.25,609.939z"
            />
            <path
              id="신안군"
              data-code="36480"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M33.417,620.439l-3.333,4.666l-0.833,4.168l1,3.666l2.5,1.334l2.5-2.668l-1.667-3
l-0.833-3.666l1.5-2L33.417,620.439z"
            />
            <path
              id="신안군"
              data-code="36480"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M39.417,628.439l-2.5,1.834l2.167,4l-3.667,3l-1.833,0.832l2,1.168l-0.667,2.5l3-2.5
l2.667-3l1.5-3.668l-2-2.166L39.417,628.439z"
            />
            <path
              id="신안군"
              data-code="36480"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M48.25,597.105l-2.333,1.5l-5.667,0.668l3.667,2.5l2.667,1.5l2.667-2.334L48.25,597.105z
"
            />
            <path
              id="신안군"
              data-code="36480"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M47.417,606.273l-2.833-1.334l-3.333-1.666l-0.833,5.5l0.833,6.332l4.333-1.5
l4.667-3.166L47.417,606.273z"
            />
            <path
              id="신안군"
              data-code="36480"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M43.917,587.605l-0.833,1.834l-3.5,1.666l-1.167,2.5l3.333,2.5l1.167-2.832l2.667-0.834
l-0.167-2l1.5-2.334L43.917,587.605z"
            />
            <path
              id="신안군"
              data-code="36480"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M46.583,618.273l-3.5,3.332l2.5,4.168l3.333-2l0.833-3.168L46.583,618.273z"
            />
            <path
              id="신안군"
              data-code="36480"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M66.917,580.439l-2,0.834l1.667,2.666l-1.333,1.334l-2.5,2.166l-4,2l4.167,1.666l2.5-1
l5.833,1.668l-0.667,2.666l3.667-0.834l0.333-3.5l-3.667-1l-2.167-0.5l1.167-3.166l2.667-0.834l-2.667-2.5L66.917,580.439z"
            />
            <path
              id="신안군"
              data-code="36480"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M47.083,544.939l-6,2.666l-3.833,4.334c0,0-4.5,2.332-4.167,2.832s2.333,4,2.333,4
l5.667-0.332l2-4.5l0.167-3.668l3.667-3.666L47.083,544.939z"
            />
            <path
              id="신안군"
              data-code="36480"
              fill="#B9B9B9"
              stroke="#FFFFFF"
              d="M45.25,564.105l-3.5,2.5l3.5,0.666l0.5,6c0,0-3,2.334-2.167,2.5
c0.833,0.168,5.833,0.168,5.833,0.168l0.333-4.334l-0.667-3.666L45.25,564.105z"
            />
          </g>
        </g>
        <g id="레이어_19">
          <path
            fill="none"
            stroke="#000000"
            d="M499.25,715.605h-133v-85h133V715.605z"
          />
          <path
            fill="none"
            stroke="#000000"
            d="M31,194.355H0.5v-30.5H31V194.355z"
          />
          <path
            fill="none"
            stroke="#000000"
            d="M46.25,194.105H36v-10.25h10.25V194.105z"
          />
          <path
            fill="none"
            stroke="#000000"
            d="M497,370.855h-24v-24h24V370.855z"
          />
          <path
            fill="none"
            stroke="#000000"
            d="M508.5,370.605h-8.25v-8.25h8.25V370.605z"
          />
        </g>
        <path
          id="세종특별자치시"
          data-code="29010"
          fill="#B9B9B9"
          stroke="#FFFFFF"
          d="M191.876,343.855l-5.375-3.469l-2.313-3.406L183,332.052
l0.625-5.322l0.563-6.178l-4.063-2.072l-4.75-6l1.375-4l-0.625-5.375l-2.625-2.5l1.75-3.25l4.625-0.25l8.875,5.25l4.375-0.125
l0.5,2.125h1.625l-0.5,3.875l-2.75,1.875l1.25,3l2,2.375v4l3.375,1.375l4.306,1.809l1.736,2.055l-0.917,5.168l0.917,2l-2.091,1.171
l0.517,4.106l-1.718,4.191l-3.75,3.875l-3.875,0.125L191.876,343.855z"
        />
      </svg>
    </Container>
  );
};

export default KoreaDivisionSvg;
