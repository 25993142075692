// import "./App.css";
import Main from "./routes/Main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./routes/Layout";
import DeviceList from "./routes/DeviceList";
import Login from "./routes/Login";
import { useReactiveVar } from "@apollo/client";
import {
  jwt_decode,
  TOKEN,
  TOKEN_VAR,
  USERINFO_VAR,
} from "./utils/reactiveVars";
import Signup from "./routes/Signup";
import Account from "./routes/Account";
import Admin from "./routes/Admin";
import { useEffect, useState } from "react";
import VideoList from "./routes/VideoList";
import LayoutMobile from "./routes/LayoutMobile";
import VideoListMobile from "./routes/VideoListMobile";
import AuthoringTools from "./routes/AuthoringTools";

function App() {
  // const token = localStorage.getItem("token");
  const userInfoVar = useReactiveVar(USERINFO_VAR);
  const tokenVar = useReactiveVar(TOKEN_VAR);

  return (
    <BrowserRouter>
      {/* <GlobalStyle /> */}
      <Routes>
        <>
          <Route
            path="/"
            element={
              <Layout>
                <Main />
              </Layout>
            }
          ></Route>
          <Route
            path="/videoList"
            element={
              <Layout>
                <VideoList />
              </Layout>
            }
          />
          <Route
            path="/videoListMobile"
            element={
              <LayoutMobile>
                <VideoList />
              </LayoutMobile>
              }
          />

        </>
        {tokenVar ? (
          <>
            <Route
              path="/deviceList"
              element={
                <Layout>
                  <DeviceList />
                </Layout>
              }
            />
            <Route
              path="/videoListMobile"
              element={
                <LayoutMobile>
                  <VideoListMobile />
                </LayoutMobile>
              }
            />
            <Route
              path="/account"
              element={
                <Layout>
                  <Account />
                </Layout>
              }
            />
            {userInfoVar?.usertype === "admin" && (
              <Route
                path="/admin"
                element={
                  <Layout>
                    <Admin />
                  </Layout>
                }
              />
            )}
          </>
        ) : (
          <>
            <Route
              path="/login"
              element={
                <Layout>
                  <Login />
                </Layout>
              }
            />
            <Route
              path="/signup"
              element={
                <Layout>
                  <Signup />
                </Layout>
              }
            />
            <Route
              path="*"
              element={
                <Layout>
                  <Login />
                </Layout>
              }
            />
          </>
        )}
        <Route
          path="/authoringTools"
          element={
            <Layout>
              <AuthoringTools/>
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
