import styled from "styled-components";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const Container = styled.div``;

const HighLineChart = ({ data }) => {
  const categories = data?.map((v) => v.date);
  const dataForChart1 = data?.map((v) => Number(v.dayPrecip));
  const dataForChart2 = data?.map((v) => Number(v.sumPrecip2d));
  const dataForChart3 = data?.map((v) => Number(v.sumPrecip3d));

  const options = {
    chart: {
      type: "line", // bar차트. 아무 설정이 없으면 line chart가 된다.
    },
    title: {
      text: "누적 강수량",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      //type: "category",
      categories: categories,
    },
    colors: [
      "#3498db",
      "#009432",
      "#0652DD",
      // "#2ecc71",
      // "#1289A7",
      // "#C4E538",
      // "#1abc9c",
      // "#12CBC4",
    ],
    yAxis: {
      min: 0,
      title: {
        text: "mm",
      },
    },
    legend: {
      reversed: true,
    },
    plotOptions: {
      series: {
        //stacking: "normal",
        dataLabels: {
          enabled: true,
          format: "<b>{point.y}</b>",
        },
      },
    },
    series: [
      {
        name: "3일 누적 강수량",
        //data: [40000, 50000, 60000, 70000, 80000, 100000, 120000, 150000],
        data: dataForChart3,
      },
      {
        name: "2일 누적 강수량",
        //data: [40000, 50000, 60000, 70000, 80000, 100000, 120000, 150000],
        data: dataForChart2,
      },
      {
        name: "일 강수량",
        //data: [40000, 50000, 60000, 70000, 80000, 100000, 120000, 150000],
        data: dataForChart1,
      },
    ],
  };
  return (
    <Container>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Container>
  );
};

export default HighLineChart;
