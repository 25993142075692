import { Container } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const HighBarChartModule = ({ type, title, categories, series }) => {
  console.log("now")
  const options = {
    data: "hello",
    chart: {
      type: type ? type : "column", // bar차트. 아무 설정이 없으면 line chart가 된다.
    },
    title: {
      text: title ? title : "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories,
      labels: {
        formatter: (e) => {
          console.log("event : ", e);
          e.value = `<div class="instant">${e?.value ? e.value : 0}</div>`;
          return e.value;
          // return (e) => {
          //   console.log("e : ", e);
          //   e.value = `<div class="instant">${e?.value ? e.value : 0}</div>`;
          //   return e.value;
          // };
        },
        useHTML: true,
        style: {
          cursor: "pointer",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    legend: {
      reversed: true,
    },
    plotOptions: {
      series: {
        cursor: "pointer",
      },
      column: {
        pointPadding: 0.1,
        borderWidth: 0,
      },
    },
    series,
  };
  return (
    <Container>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Container>
  );
};

export default HighBarChartModule;
