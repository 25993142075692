import { useReactiveVar } from "@apollo/client";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import {
  locCodeVar,
  searchedDataForBarVar,
  fixLocVar,
} from "../../utils/reactiveVars";

const Container = styled.div``;
const SearchCon = styled.div`
  display: flex;
  justify-content: center;
`;
const SSelect = styled.select`
  padding: 5px;
`;
const FixLoc = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
`;

const SearchBox = () => {
  const [selDate, setSelDate] = useState();
  const { register, getValues, setValue } = useForm();
  const locCode = useReactiveVar(locCodeVar);
  const fixLoc = useReactiveVar(fixLocVar);
  const [data, setData] = useState([]);
  const getRainDamages = async (locCode) => {
    const data = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/getRainDamages`,
      {
        doCode: locCode?.length === 2 ? locCode : null,
        siCode: locCode?.length === 5 ? locCode : null,
      },
      {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      }
    );
    console.log("data :", data);
    if (data?.data) {
      setData(data?.data);
    }
  };
  useEffect(() => {
    console.log("locCode : ", locCode);
    if (locCode) {
      getRainDamages(locCode);
    }
  }, [locCode]);
  // const { loading, data } = SelectRainDamagesByCode(
  //   locCode?.length === 2 ? locCode : null,
  //   locCode?.length === 5 ? locCode : null
  // );

  const [dataForSearch, setDataForSearch] = useState();
  //console.log("charts data : ", data);
  useEffect(() => {
    setSelDate(null);
    if (data) {
      //console.log(data??.);
      const clone = JSON.parse(JSON.stringify(data));
      //현재값과 다음값의 차이가 1일 = 1000 * 60 * 60 * 24 을 넘지 않고
      //침수면적과 피해액의 값이 같다면 rowspan
      if (clone?.length) {
        let standardId = 0; //clone?.[0].id;
        let standardDate = null; //기준 날짜
        let standardDamagePrice = null; //기준 피해액
        let standardTotalPrecip = null; //기준 총 강수량
        let standardTerm = null; //기준 총 기간
        let standardTyphoon = null; //기준 태풍
        let standardRowSpan = null; //span 의 수
        for (let i = 0; i < clone?.length; i++) {
          //console.log("standardId : ", standardId);
          //console.log("standrad date : ", standardDate);
          if (
            new Date(standardDate).getTime() + 1000 * 60 * 60 * 24 ===
              new Date(clone?.[i].date).getTime() &&
            standardDamagePrice == clone?.[i].damagePrice &&
            standardTotalPrecip == clone?.[i].totalPrecip &&
            standardTerm == clone?.[i].term &&
            standardTyphoon == clone?.[i].typhoon
          ) {
            //span 이 돼야하는 값이라면
            //standardId == clone?.[i].id
            standardDate = clone?.[i].date;
            standardRowSpan = Number(standardRowSpan) + 1;
          } else {
            //여기에 들어온다면 데이터가 더이상 합치는 부분이 아니므로 해당 id 의 데이터에 rowspan 을 주고
            //standardId 를 바꾸고 standardRowSpan 로 null 바꾼다.
            for (let j = 0; j < clone?.length; j++) {
              if (
                clone[j].damagePrice === standardDamagePrice &&
                clone[j].totalPrecip === standardTotalPrecip &&
                clone[j].term === standardTerm &&
                clone[j].typhoon == standardTyphoon
              ) {
                clone[j].groupId = standardId;
              }
            }
            standardId = clone?.[i].id;
            standardDate = clone?.[i].date;
            standardDamagePrice = clone?.[i].damagePrice;
            standardTotalPrecip = clone?.[i].totalPrecip;
            standardTerm = clone?.[i].term;
            standardTyphoon = clone?.[i].typhoon;
            standardRowSpan = null;
          }
        }
        setDataForSearch(clone);
      }
    }
  }, [data]);
  // useEffect(() => {
  //   if (dataForSearch??.?.length) {
  //     console.log("data for search : ", dataForSearch);
  //   }
  // }, [dataForSearch]);
  const optionGen = () => {
    if (dataForSearch?.length) {
      let filtered = [];
      let standardGroupId = null;
      for (let i = 0; i < dataForSearch?.length; i++) {
        if (standardGroupId === dataForSearch?.[i].groupId) {
        } else {
          filtered.push(dataForSearch?.[i]);
          standardGroupId = dataForSearch?.[i + 1].groupId;
        }
      }
      //console.log("filtered : ", filtered);
      setValue("searchDateGroupId", selDate ? selDate : filtered[0]?.groupId);
      //onSearchDateClick();
      return filtered?.map((v, idx) => (
        <option key={idx} value={v.groupId}>
          {v.date}
        </option>
      ));
    }
  };
  const onSearchDateClick = () => {
    const { searchDateGroupId } = getValues();
    //console.log("searchDateGroupId : ", searchDateGroupId);
    //console.log("dataForSearch : ", dataForSearch);
    const group = dataForSearch?.filter(
      (v) => v.groupId === Number(searchDateGroupId)
    );
    //console.log("group : ", group);
    searchedDataForBarVar(group);
  };
  //날짜 변경 시 해당 날짜를 state 에 저장 : 검색 클릭시 초기화되는 문제 해결방안
  const onSelectChange = (e) => {
    setSelDate(e.target.value);
  };
  return (
    <Container>
      <SearchCon className="search-bar">
        <SSelect {...register("searchDateGroupId")} onChange={onSelectChange}>
          {optionGen()}
        </SSelect>
        <Button
          variant="primary"
          type="button"
          title="검색"
          onClick={onSearchDateClick}
        >
          검색
        </Button>
        <FixLoc>{fixLoc}</FixLoc>
      </SearchCon>
    </Container>
  );
};

export default SearchBox;
