//해당 지역(예를 들어 성남)에 설치된 위치의 수위 유속 유량을 막대 그래프로 한 번에 볼 수 있는 UI Component

import { useReactiveVar } from "@apollo/client";
import axios from "axios";
import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { fixLocVar } from "../../utils/reactiveVars";
import HighBarChartModule from "../charts/highBarChartModule";

const Devices = () => {
  const [devices, setDevices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);
  const fixLoc = useReactiveVar(fixLocVar);
  const getDevices = async () => {
    const data = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/getDevices`,
      {
        locName: fixLoc,
      },
      {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      }
    );
    if (data?.data) {
      setDevices(data.data);
    } else {
      setDevices([]);
    }
  };
  useEffect(() => {
    getDevices();
  }, [fixLoc]);
  useEffect(() => {
    if (devices.length) {
      console.log("devices : ", devices);
      const categories = devices?.map((device) => device.placeName);
      console.log("categories  : ", categories);
      setCategories(categories);
      let series = [
        { name: "강수량(mm)" },
        { name: "수위(mm)" },
        { name: "유속(m/s)" },
      ];
      let rains = [];
      let flows = [];
      let flowrates = [];
      devices.forEach((w) => {
        rains.push(w.RAIN ? w.RAIN : 0);
        flows.push(w.FLOW_AVR ? w.FLOW_AVR : 0);
        flowrates.push(w.FLOWRATE_AVR ? w.FLOWRATE_AVR : 0);
      });
      series[0].data = rains;
      series[1].data = flows;
      series[2].data = flowrates;
      setSeries(series);
    }
  }, [devices]);
  return (
    <>
      {categories.length && series.length ? (
        <HighBarChartModule
          type="column"
          title="순간 계측량"
          categories={categories}
          series={series}
        />
      ) : null}
    </>
  );
};

export default Devices;
