import DownpourPredictionTable from "./DownpourPredictionTable";
import KoreaDivisionSvg from "./koreaDivisionSvg";
import SearchDownpour from "./SearchDownpour";

const MapandDownpour = () => {
  return (
    <>
      <div className="col-md-12">
        <SearchDownpour />
      </div>
      <div className="col-md-8">
        <KoreaDivisionSvg />
      </div>
      <div className="col-md-4">
        <DownpourPredictionTable />
      </div>
      {/* <div className="col-md-12">영상정보</div> */}
    </>
  );
};

export default MapandDownpour;
