import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import Grid from "@toast-ui/react-grid";
import { useQuery, useReactiveVar } from "@apollo/client";
import { locCodeVar } from "../../utils/reactiveVars";
import axios from "axios";

const Container = styled.div``;

const LocationTable = () => {
  const gridRef = useRef();
  const locCode = useReactiveVar(locCodeVar);
  // const { loading, data } = SelectRainDamagesByCode(
  //   locCode?.length === 2 ? locCode : null,
  //   locCode?.length === 5 ? locCode : null
  // );
  const [data, setData] = useState([]);
  const getRainDamages = async (locCode) => {
    const data = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/getRainDamages`,
      {
        doCode: locCode?.length === 2 ? locCode : null,
        siCode: locCode?.length === 5 ? locCode : null,
      },
      {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      }
    );
    console.log("data :", data);
    if (data?.data) {
      setData(data?.data);
    }
  };
  useEffect(() => {
    console.log("locCode : ", locCode);
    if (locCode) {
      getRainDamages(locCode);
    }
  }, [locCode]);

  const myChallengesColumns = [
    { name: "id", header: "id", align: "left", hidden: true },
    { name: "date", header: "일자", width: "120" },
    {
      name: "floodedArea",
      header: "침수면적\n(1,000m²)",
      align: "center",
      width: "100",
    },
    {
      name: "damagePrice",
      header: "피해액\n(천원)",
      align: "center",
      width: "150",
    },
    {
      name: "totalPrecip",
      header: "총\n강수량\n(mm)",
      align: "center",
      width: "100",
    },
    { name: "term", header: "기간\n(일)", align: "center", width: "100" },
    { name: "typhoon", header: "태풍", align: "center", width: "100" },
    {
      name: "precipDur",
      header: "강수\n계속시간\n(시간)",
      align: "center",
      width: "100",
    },
    {
      name: "maxPrecip",
      header: "최다\n강수\n1H",
      align: "center",
      width: "100",
    },
    {
      name: "dayPrecip",
      header: "일\n강수량\n(mm)",
      align: "center",
      width: "100",
    },
    { name: "sumPrecip2d", header: "2일", align: "center", width: "100" },
    { name: "sumPrecip3d", header: "3일", align: "center", width: "100" },
    {
      name: "maxWindSpeed",
      header: "최대\n풍속\n(m/s)",
      align: "center",
      width: "100",
    },
    {
      name: "avgTemp",
      header: "평균\n기온\n(℃)",
      align: "center",
      width: "100",
    },
  ];

  const onGridClick = (e) => {
    const rowData = gridRef.current.getInstance().getRow(e?.rowKey);
  };
  useEffect(() => {
    if (data) {
      //window.grid = gridRef?.current;
      //console.log(data?);
      const clone = JSON.parse(JSON.stringify(data));
      //현재값과 다음값의 차이가 1일 = 1000 * 60 * 60 * 24 을 넘지 않고
      //침수면적과 피해액의 값이 같다면 rowspan
      if (clone?.length) {
        let standardId = 0; //clone[0].id;
        let standardDate = null; //기준 날짜
        let standardDamagePrice = null; //기준 피해액
        let standardTotalPrecip = null; //기준 총 강수량
        let standardTerm = null; //기준 총 기간
        let standardTyphoon = null; //기준 태풍
        let standardRowSpan = null; //span 의 후
        for (let i = 0; i < clone.length; i++) {
          //console.log("standardId : ", standardId);
          //console.log("standrad date : ", standardDate);
          if (
            new Date(standardDate).getTime() + 1000 * 60 * 60 * 24 ===
              new Date(clone[i].date).getTime() &&
            standardDamagePrice == clone[i].damagePrice &&
            standardTotalPrecip == clone[i].totalPrecip &&
            standardTerm == clone[i].term &&
            standardTyphoon == clone[i].typhoon
          ) {
            //span 이 돼야하는 값이라면
            //standardId == clone[i].id
            standardDate = clone[i].date;
            standardRowSpan = Number(standardRowSpan) + 1;
          } else {
            //여기에 들어온다면 데이터가 더이상 합치는 부분이 아니므로 해당 id 의 데이터에 rowspan 을 주고
            //standardId 를 바꾸고 standardRowSpan 로 null 바꾼다.
            for (let j = 0; j < clone.length; j++) {
              if (clone[j].id === standardId) {
                clone[j]._attributes = {
                  rowSpan: {
                    floodedArea:
                      standardRowSpan === null ? null : standardRowSpan + 1, // 데이터가 하나일 경우 null 이 나온다. 그리고 사용중인 그리드는 rowspan 이 1 일 경우 문제를 발생시킨다.
                    //그래서 null 일 경우는 span 이 1 인 상태로 표가 뜨기에 null 로 값을주어 값이 하나일 경우를 표시했다. 그 외의 값은 1을 더해서 마지막 값이 누락되지 않도록 한다.
                    //마지막 값이 누락되는 이유는 상기 if 식에서 현재데이터와 비교해서 다를경우 여기 else 로 넘어오므로 span 이 하나 추가되지 않기 때문이다. 그래서 1 을 더해서 마지막 값이 누락되지 않도록 한다.
                    damagePrice:
                      standardRowSpan === null ? null : standardRowSpan + 1,
                    totalPrecip:
                      standardRowSpan === null ? null : standardRowSpan + 1,
                    term: standardRowSpan === null ? null : standardRowSpan + 1,
                    typhoon:
                      standardRowSpan === null ? null : standardRowSpan + 1,
                  },
                };
              }
            }
            standardId = clone[i].id;
            standardDate = clone[i].date;
            standardDamagePrice = clone[i].damagePrice;
            standardTotalPrecip = clone[i].totalPrecip;
            standardTerm = clone[i].term;
            standardTyphoon = clone[i].typhoon;
            standardRowSpan = null;
          }
          // clone[0]._attributes = {
          //   rowSpan: {
          //     floodedArea: 3,
          //   },
          // };
        }
      }
      gridRef?.current?.getInstance().resetData(clone?.map((v) => ({ ...v })));
    }
  }, [data]);
  return (
    <Container>
      <Grid
        ref={gridRef}
        // data={data??.map((v) => {
        //   return { ...v };
        // })}
        width="auto"
        bodyHeight={300}
        onClick={onGridClick}
        columns={myChallengesColumns}
        rowHeaders={["rowNum"]}
        header={{
          complexColumns: [
            {
              header: "누적 강수량\n(mm)",
              name: "parent1",
              childNames: ["sumPrecip2d", "sumPrecip3d"],
            },
          ],
          height: 70,
        }}
        pageOptions={{
          perPage: 0,
          useClient: true,
          type: "scroll",
        }}
      />
    </Container>
  );
};

export default LocationTable;
